const HomeGradientIcon2 = (p) => {
  return (
    <svg
      width="1008"
      height="895"
      viewBox="0 0 1008 895"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g filter="url(#filter0_f_1_2859)">
        <path
          d="M830.93 244.148C909.302 363.767 803.855 821.661 676.599 619.912C549.343 418.163 650.806 668.233 344.746 737.249C38.686 806.266 134.757 394.192 350.673 252.729C566.589 111.266 752.559 124.528 830.93 244.148Z"
          fill="#1DBF73"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1_2859"
          x="0.131462"
          y="0.524414"
          width="1007.7"
          height="894.454"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="75"
            result="effect1_foregroundBlur_1_2859"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomeGradientIcon2;
