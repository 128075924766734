import cx from 'classnames';
import styles from './HowItWorks.module.scss';

const HowItWorks = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.how_it_works)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.label)}>
          <p>How does it work?</p>
        </div>
        <div className={styles.steps}>
          <ul>
            <li>
              <div className={cx(styles.flex_center_all, styles.order)}>
                <p>1</p>
              </div>
              <p>Create your store profile.</p>
            </li>
            <li>
              <div className={cx(styles.flex_center_all, styles.order)}>
                <p>2</p>
              </div>
              <p>
                Have customer <span>scan</span> your shop's QR code.
              </p>
            </li>
            <li>
              <div className={cx(styles.flex_center_all, styles.order)}>
                <p>3</p>
              </div>
              <p>Let customers fill out their email address.</p>
            </li>
            <li>
              <div className={cx(styles.flex_center_all, styles.order)}>
                <p>4</p>
              </div>
              <p>
                Get customer leads and <span>increase profit.</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
