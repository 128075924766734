import cx from 'classnames';
import styles from './NotificationItem.module.scss';
import { useMemo } from 'react';
import { Avatar } from '@chakra-ui/react';
import { head, isEmpty } from 'lodash';
import { withUserProfileSettings } from 'managers/profile';
import {
  storeNotificationStoreCreated,
  storeNotificationSetName,
  storeNotificationUserFirstScanned,
  storeNotificationUserScannedForPoints,
} from 'constants';
import moment from 'moment';
import { withStoreManagerSettings } from 'managers/stores';

const NotificationItem = (props) => {
  const { notification, storeSelected, user } = props;

  const notificationType = useMemo(() => notification?.type, [notification]);

  const userAvatar = useMemo(
    () =>
      !isEmpty(notification?.preview?.userInfo)
        ? notification.preview.userInfo
        : user,
    [user, notification]
  );

  const dateFormatted = useMemo(() => {
    const createdMins = notification?.createdMins;
    const dateObj = new Date(createdMins * 60 * 1_000);
    return moment(dateObj).fromNow();
  }, [notification]);

  const previewEmail = useMemo(
    () => notification?.preview?.email,
    [notification]
  );

  return (
    <div className={cx(styles.flex_center_all, styles.notification_item)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.left)}>
          <Avatar
            name={head(userAvatar?.fullName || userAvatar?.email || '')}
            src={userAvatar?.image || ''}
            height={'44px'}
            width={'44px'}
            display={'flex'}
            justifyContent={'center'}
            color={'#fff'}
            background={'#000'}
            backgroundColor={'#000'}
          />
        </div>
        <div className={cx(styles.flex_center_all, styles.right)}>
          {notificationType === storeNotificationStoreCreated && (
            <p>{`Created store profile: ${dateFormatted}`}</p>
          )}

          {notificationType === storeNotificationUserFirstScanned &&
            !isEmpty(previewEmail) && (
              <p>
                {`User: `}
                <span>{`${previewEmail}`}</span>
                {` first scan, ${dateFormatted}.`}
              </p>
            )}

          {notificationType === storeNotificationUserScannedForPoints && (
            <p>
              {`User: `}
              <span>{`${previewEmail}`}</span>
              {' scanned for points.'}
            </p>
          )}

          {notificationType === storeNotificationSetName && (
            <p>
              {`Business profile name was set to `}
              <span>{`${
                notification?.preview?.businessName ||
                storeSelected?.businessName ||
                ''
              }`}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(NotificationItem)
);
