import cx from 'classnames';
import styles from './Illustration.module.scss';

const Illustration = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.illustration)}>
      <div className={styles.content}>
        <img
          alt="Illustration drawn customers"
          src="/images/melonkarma-illustration.png"
        />
        <div className={cx(styles.cover_dom, styles.img_cover)}></div>
      </div>
    </div>
  );
};

export default Illustration;
