export function getUsersApiPathname() {
  return `/v1/users`;
}

export function getStoresApiPathname() {
  return `/v1/stores`;
}

export function getFilesApiPathname() {
  return `/v1/files`;
}

export function getAdminApiPathname() {
  return `/v1/admin`;
}

export function getStoreAnalyticsPathname() {
  return `/v1/analytics`;
}
