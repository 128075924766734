import { isNetworkError, isSuccessStatus } from 'api';
import { getUsersApiPathname } from './urls';
import { axiosGetRequest, axiosPostRequest } from './axios';
import { isEmpty, isNumber, isString, pick, trim } from 'lodash';
import { toNumber } from 'lodash';

export async function sendLogoutRequest(headers = {}) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  try {
    const url = getUsersApiPathname() + '/logout';
    const res = await axiosPostRequest(url, { ...headers }, {});
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    success,
  };
}

export async function sendCustomerLoginWithCodeRequest(email = '') {}

export async function getUserInfoRequest(userId = '', headers = {}) {
  let errorMessage = '';
  let networkError = false;
  let user = null;
  let token = '';

  try {
    const url = getUsersApiPathname() + `/info/${trim(userId)}`;
    const res = await axiosGetRequest(url, { ...headers });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
      token = resData?.token;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { token, networkError, errorMessage, user };
}

export async function sendOwnerLoginRequest(emailOrHandle = '', password = '') {
  let errorMessage = '';
  let networkError = false;
  let token = '';
  let user = null;
  let stores = [];

  try {
    const origin = `${window.location.origin || ''}`;
    const url = getUsersApiPathname() + '/login/owner';
    const res = await axiosPostRequest(
      url,
      {},
      { email: emailOrHandle, origin, password }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && !isEmpty(resData?.user)) {
      user = resData?.user;
      token = resData?.token || '';
      stores = resData?.stores || [];
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    token,
    user: user ? { ...user } : null,
    stores: stores ? [...stores] : [],
  };
}

export async function sendOwnerSignupRequest(
  email = '',
  fullName = '',
  password = '',
  businessName = '',
  country = '',
  cityOrRegion = '',
  inviteCode = ''
) {
  let errorMessage = '';
  let networkError = false;
  let token = '';
  let user = null;
  let stores = [];

  //   email: { type: 'string', maxLength: 250 },
  //   fullName: { type: 'string', maxLength: 80 },
  //   businessName: { type: 'string', maxLength: 250 },
  //   password: { type: 'string', maxLength: 240 },
  //   country: { type: 'string', maxLength: 10 },
  //   inviteCode: { type: 'string', maxLength: 80 }

  try {
    const origin = `${window.location.origin || ''}`;
    const url = getUsersApiPathname() + '/sign-up/owner';
    const res = await axiosPostRequest(
      url,
      {},
      {
        email,
        origin,
        fullName,
        password,
        businessName,
        country,
        inviteCode,
        cityOrRegion,
      }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && !isEmpty(resData?.user)) {
      user = resData?.user;
      token = resData?.token;
      stores = resData?.stores || [];
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    token,
    user: user ? { ...user } : null,
    stores: stores ? [...stores] : [],
  };
}

export async function updateUserAccountInfoRequest(headers = {}, params = {}) {
  let errorMessage = '';
  let networkError = false;
  let user = null;
  let success = false;

  // email: { type: 'string', maxLength: 250 },
  // handle: { type: 'string', maxLength: 80 },
  // fullName: { type: 'string', maxLength: 80 },
  // password: { type: 'string', maxLength: 240 }

  try {
    const url = getUsersApiPathname() + '/info';
    const res = await axiosPostRequest(
      url,
      { ...headers },
      { ...pick(params, ['email', 'handle', 'fullName', 'password']) }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
    user: user ? { ...user } : null,
  };
}

export async function sendForgotPasswordEmailRequest(email = '') {
  let errorMessage = '';
  let networkError = false;
  let tokenId = '';
  let success = true;

  try {
    const origin = `${window.location.origin || ''}`;
    const url = getUsersApiPathname() + '/forgot-password';
    const res = await axiosPostRequest(url, {}, { email, origin });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.tokenId) {
      tokenId = resData?.tokenId;
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { success, networkError, errorMessage, tokenId };
}

export async function verifyUserEmailTokenRequest(token = '') {
  let networkError = false;
  let errorMessage = '';
  let success = false;
  let user = null;

  try {
    const url = getUsersApiPathname() + `/verify-email?token=${token}`;
    const res = await axiosGetRequest(url, {});
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      success = true;
      user = resData?.user;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    errorMessage = err?.message;
    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    success,
    user: user ? { ...user } : null,
  };
}

export async function updatePasswordFromForgotPassword(
  password = '',
  tokenId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getUsersApiPathname() + '/reset-password';
    const res = await axiosPostRequest(
      url,
      { ...headers },
      { password, tokenId }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && !isEmpty(resData?.user)) {
      success = true;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    success,
    networkError,
    errorMessage,
  };
}

export async function sendRecordUserScannedRequest(
  storeId = '',
  email = '',
  purchase = 0,
  fullName = '',
  country = '',
  cityOrRegion = '',
  requireEmailExists = false
) {
  let networkError = false;
  let errorMessage = '';
  let totalPoints = 0;
  let user = null;
  let notFound = false;

  try {
    const url = getUsersApiPathname() + `/scan/${trim(storeId)}`;
    const res = await axiosPostRequest(
      url,
      {},
      {
        email,
        cityOrRegion,
        requireEmailExists: Boolean(requireEmailExists),
        country: isString(country) && !isEmpty(country) ? country : '',
        fullName: trim(fullName),
        purchase: isNumber(purchase) ? purchase : toNumber(purchase),
      }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
      totalPoints = resData?.totalPoints;
    } else if (resData?.message) {
      notFound = resData?.notFound;

      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    totalPoints,
    notFound,
    user: user ? { ...user } : null,
  };
}

export async function loginUserEmailFromStoreRequest(email = '', storeId = '') {
  let networkError = false;
  let errorMessage = '';
  let user = null;
  let customer = null;

  try {
    const url =
      getUsersApiPathname() +
      `/login/store/${trim(storeId)}?email=${trim(email)}`;
    const res = await axiosPostRequest(url, {});
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
      customer = resData?.customer;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    customer,
    user: user ? { ...user } : null,
  };
}

export async function getUserInfoByEmailRequest(email = '', headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let user = null;

  try {
    const url = getUsersApiPathname() + `/email/${trim(email)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    user,
    networkError,
    errorMessage,
  };
}

export async function loginUserCardHolderRequest(qrId = '', storeId = '') {
  let networkError = false;
  let errorMessage = '';
  let user = null;
  let customer = null;
  let store = null;

  try {
    const url = getUsersApiPathname() + `/login/card-holder`;
    const res = await axiosPostRequest(url, {}, { qrId, storeId });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
      customer = resData?.customer;
      store = resData?.store;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    customer,
    user,
    store,
  };
}

export async function updateUserCardHolderInfoRequest(userId = '', props = {}) {
  let networkError = false;
  let errorMessage = '';
  let user = null;

  try {
    const sanitizedProps = pick(props, ['email', 'fullName', 'storeIdOrHandle']);
    const sanitizedUserId = trim(userId);
    const url = getUsersApiPathname() + `/info/card-holder`;
    const res = await axiosPostRequest(
      url,
      {},
      { ...sanitizedProps, userId: sanitizedUserId }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.user) {
      user = resData?.user;
    } else if (resData?.message) {
      throw new Error(resData?.message);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    user,
  };
}
