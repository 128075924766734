import cx from 'classnames';
import Header from './Header';
import styles from './Home.module.scss';
import HowItWorks from './HowItWorks';
import RewardsCustomers from './RewardCustomers';
import Footer from 'components/footer';
import SignUp from 'components/sign-up';
import Illustration from './Illustration';
import { AppRootContainerId } from 'App';
import { withUserProfileSettings } from 'managers/profile';
import { useEffect, useRef, useState } from 'react';
import { useWindowResize } from 'hooks';
import { isFunction, isNumber, toString } from 'lodash';
import { HomeGradientIcon1, HomeGradientIcon2 } from 'icons';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { useNavigate } from 'react-router-dom';
import { isMobileScreen } from 'utils/helpers';

const HomePage = (props) => {
  const { setActiveModalDom, user, isLoggedIn } = props;
  const introDom = useRef(null);
  const { availableHeight } = useWindowResize();
  const [targetBusinessName, setTargetBusinessName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    if (isFunction(dom?.scrollTo)) {
      dom.scrollTo(0, 0);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const dom = introDom?.current;

    if (dom) {
      const h = `${availableHeight}px`;

      if (isNumber(availableHeight) && availableHeight > 470) {
        dom.style.height = h;
      } else {
        dom.style.height = '560px';
      }
    }
  }, [availableHeight]);

  /**
   * Auto redirect user to dashboard from mobile screen
   */
  useEffect(() => {
    if (user?.storeOwner && isMobileScreen() && isLoggedIn) {
      navigate('/dashboard');
    }
  }, [user?.storeOwner, navigate, isLoggedIn]);

  const initCreateShop = () => {
    if (isLoggedIn && user?.storeOwner) {
      navigate('/dashboard');
      return;
    }

    if (isFunction(setActiveModalDom)) {
      setTargetBusinessName('');
      setActiveModalDom(<SignUp targetBusinessName={targetBusinessName} />);
    }
  };

  return (
    <div className={styles.homepage}>
      <Header />
      <div className={styles.content}>
        <div
          className={cx(styles.flex_center_all, styles.intro)}
          ref={introDom}
        >
          <div className={cx(styles.flex_center_all, styles.gradient1)}>
            <HomeGradientIcon1 />
          </div>
          <div className={cx(styles.flex_center_all, styles.gradient2)}>
            <HomeGradientIcon2 />
          </div>

          <div className={cx(styles.flex_center_all, styles.one_liner)}>
            <p>
              Easily <span>integrate a rewards system</span> to your business.
            </p>
          </div>
          <div className={cx(styles.shop_handle, styles.flex_center_all)}>
            <Button
              height={'50px'}
              variant={'ghost'}
              borderRadius={'100px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              color="#fff"
              className={cx(styles.flex_center_all, styles.create_shop)}
              onClick={initCreateShop}
            >
              <p>Create for my shop</p>
            </Button>
            <input
              placeholder="@yourshop"
              type="text"
              aria-label="Fill out your shop handle"
              value={targetBusinessName}
              onChange={(evt) =>
                setTargetBusinessName(toString(evt?.target?.value || ''))
              }
            ></input>
          </div>
          <div className={cx(styles.flex_center_all, styles.for_free)}>
            <p>No credit card required.</p>
          </div>
        </div>
        <HowItWorks />
        <RewardsCustomers />
        <Illustration />
        <Footer />
      </div>
    </div>
  );
};

export default withUserProfileSettings(withModalManagerSettings(HomePage));
