import { includes, toLower } from 'lodash';
import { isDev } from './helpers';

export const getImageBase64 = (file) =>
  new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = async function () {
      resolve(this.result);
    };
    fr.onerror = () => {
      resolve('');
    };
    fr.readAsDataURL(file);
  });

export function scaleImageToJpeg(
  src,
  type = 'jpeg',
  quality = 0.5,
  maxW = 0,
  maxH = 0
) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      try {
        let h =
          typeof this.naturalHeight === 'number' && this.naturalHeight > 0
            ? this.naturalHeight
            : this.height;
        let w =
          typeof this.naturalWidth === 'number' && this.naturalWidth > 0
            ? this.naturalWidth
            : this.width;

        if (w > maxW && maxW > 0) {
          const ratio = maxW / w;

          w = Math.ceil(ratio * w);

          if (maxH <= 0) {
            h = Math.ceil(ratio * h);
          }
        }

        if (h > maxH && maxH > 0) {
          const ratio = maxH / h;

          h = Math.ceil(ratio * h);
        }

        const fileType = type.indexOf('jpeg') > -1 ? 'image/jpeg' : 'image/png';
        const canvas = document.createElement('canvas');
        canvas.width = Math.ceil(w);
        canvas.height = Math.ceil(h);
        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height);
        const value = canvas.toDataURL(fileType, quality);
        resolve({ value, err: false });
      } catch (err) {
        console.log(err?.message);
        resolve({ err: true });
      }

      img.remove();
    };

    if (isDev()) {
      img.crossOrigin = 'Anonymous';
    }

    img.onerror = function (err) {
      console.log(err?.message);
      resolve({ err: true });
      img.remove();
    };

    img.src = src;
  });
}

export function base64ToBlob(b64Data = '', type = '') {
  try {
    if (b64Data.indexOf('data:image') >= 0) {
      b64Data = b64Data.substring(
        b64Data.indexOf('base64,') + 'base64,'.length,
        b64Data.length
      );
    }

    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const createdBlob = new Blob(byteArrays, {
      type: type ? type : 'image/jpeg',
    });
    return createdBlob;
  } catch (err) {
    console.error(err);

    return null;
  }
}

export const isTypeJpeg = (type = '') => {
  return includes(type, 'image/jpeg') || includes(type, 'image/jpg');
};

export const isTypePng = (type = '') => {
  return includes(toLower(type), 'image/png');
};
