import GenerateUserQr from './GenerateUserQr';
import styles from './QRCodes.module.scss';
import NonPremiumCTA from './NonPremiumCTA';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';

const QRCodes = (props) => {
  const { userIsPremium } = props;

  return (
    <div className={styles.qr_codes}>
      {!userIsPremium && <NonPremiumCTA />}
      <GenerateUserQr />
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(QRCodes));
