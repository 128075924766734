import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './RewardDescription.module.scss';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import YoutubeExtension from '@tiptap/extension-youtube';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import { withUserProfileSettings } from 'managers/profile';
import { Editor as TipTapEditor } from '@tiptap/core';
import { isFunction } from 'lodash';
import { useDebounce } from 'hooks';

export const rewardDescriptionEditorId = 'rewardDescriptionEditorId';

const characterCountLimit = 480;

const RewardDescription = (props) => {
  const { storeEditorInstance } = props;
  const editorDomRef = useRef(null);
  const editorInstanceCreated = useRef(false);
  const [editor, setEditor] = useState(null);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const dom = editorDomRef?.current;

    if (
      !editor &&
      isFunction(storeEditorInstance) &&
      !editorInstanceCreated?.current
    ) {
      editorInstanceCreated.current = true;
      const editorInstance = new TipTapEditor({
        element: dom,
        extensions: [
          StarterKit.configure({
            code: false,
            codeBlock: false,
            italic: true,
            bold: true,
            heading: { levels: [2, 3] },
            blockquote: {
              content: 'paragraph*',
            },
          }),
          Placeholder.configure({
            placeholder: 'Describe your reward',
          }),
          Link.configure({
            openOnClick: false,
          }),
          CharacterCount.configure({
            limit: characterCountLimit,
          }),
          YoutubeExtension.extend({
            addOptions() {
              return {
                ...this.parent?.(),
                height: '320px',
                width: '100%',
              };
            },
          }),
        ],
        content: `<p></p>`,
        editable: true,
        autofocus: false,
        injectCSS: false,
      });

      storeEditorInstance(editorInstance);
      setEditor(editorInstance);
    }
  }, [editor, storeEditorInstance]);

  const updateCharCount = useDebounce(() => {
    if (editor?.storage) {
      setCharCount(editor.storage.characterCount.characters());
    }
  }, 200);

  const onEditorChange = useCallback(
    ({ editor }) => {
      // check if content has harmful words

      if (editor) {
        updateCharCount();
      }
    },
    // eslint-disable-next-line
    [editor, charCount, updateCharCount]
  );

  useEffect(() => {
    if (editor) {
      editor.on('update', onEditorChange);
    }

    return () => {
      if (editor) {
        editor.off('update', onEditorChange);
      }
    };
  }, [editor, onEditorChange]);

  return (
    <>
      <div className={styles.reward_description}>
        <div className={styles.description_label}>
          <p>Description ( optional ) </p>
        </div>
        <div className={styles.editor}>
          <div
            id={rewardDescriptionEditorId}
            ref={editorDomRef}
            className={styles.editor_raw}
          ></div>
        </div>
        <div className={cx(styles.flex_center_all, styles.char_count)}>
          <p>{`${charCount}/${characterCountLimit}`}</p>
        </div>
      </div>
    </>
  );
};

export default withUserProfileSettings(RewardDescription);
