export const cloudFunctionUrlUsCentral =
  'https://us-central1-melonkarma.cloudfunctions.net/UserPublic';

export const lightTheme = 'light';

export const darkTheme = 'dark';

export const lightThemeBackground = '#f8faed';

export const primaryGreenColor = '#50A65C';

export const minPassword = 4;

export const maxPassword = 50;

export const minHandle = 2;
export const maxHandle = 80;

export const minName = 2;
export const maxName = 120;

export const minBusinessName = 4;
export const maxBusinessName = 100;

export const maxEmail = 250;
export const minEmail = 7;

// check db for enums
export const userActivityScanAddPoints = 'scanAddPoints';
export const userActivityAddPoints = 'addPoints'; // manual add points
export const userActivityConsumePoints = 'consumePoints';
export const userActivityConsumeCoupon = 'consumeCoupon';
export const userActivityDeactivated = 'userDeactivated';
export const userActivityActivated = 'userActivated';
export const userActivityBanned = 'userBanned';

export const storeNotificationUserDeleted = 'userDeleted';
export const storeNotificationUserBanned = 'userBanned';
export const storeNotificationUserScannedForPoints = 'userScannedPoints';
export const storeNotificationUserPointsEdited = 'userPointsEdited';
export const storeNotificationUserFirstScanned = 'userFirstScanned';
export const storeNotificationStoreCreated = 'storeCreated';
export const storeNotificationUpdateName = 'storeNameUpdated';
export const storeNotificationUpdatedHandle = 'storeHandleUpdated';
export const storeNotificationSetName = 'storeNameSet';
export const storeNotificationSetHandle = 'storeHandleSet';

export const storeMemberRole = 'roleStoreMember';
export const storeMemberOwnerRole = 'roleStoreOwner';
export const storeMemberAdminRole = 'roleStoreAdmin';

export const planTypeFree = 'planTypeFree';
export const planTypePremiumStore = 'planTypePremiumStore';

export const calendarMonthsAbvOrdered = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const calendarMonths = [
  {
    name: 'January',
    key: '0',
  },
  {
    name: 'February',
    key: '1',
  },
  {
    name: 'March',
    key: '2',
  },
  {
    name: 'April',
    key: '3',
  },
  {
    name: 'May',
    key: '4',
  },
  {
    name: 'June',
    key: '5',
  },
  {
    name: 'July',
    key: '6',
  },
  {
    name: 'August',
    key: '7',
  },
  {
    name: 'September',
    key: '8',
  },
  {
    name: 'October',
    key: '9',
  },
  {
    name: 'November',
    key: '10',
  },
  {
    name: 'December',
    key: '11',
  },
];
