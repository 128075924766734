import axios from 'axios';
import { isEmpty, isObject } from 'lodash';
import { isDev } from 'utils/helpers';

export const getApiDashboardBaseUrl = () => {
  return isDev() ? 'http://localhost:8081' : 'https://api.melonkarma.com';
};

export const axiosGetRequest = (url = '', headers = {}) =>
  axios({
    url,
    baseURL: getApiDashboardBaseUrl(),
    method: 'GET',
    headers: { ...headers },
    validateStatus: function (status) {
      return status >= 200 && status <= 500;
    },
  });

export const axiosPostRequest = (url = '', headers = {}, data = {}, params) =>
  axios({
    url,
    data,
    baseURL: getApiDashboardBaseUrl(),
    method: 'POST',
    headers: { ...headers },
    validateStatus: function (status) {
      return status >= 200 && status <= 500;
    },
    ...(isObject(params) && !isEmpty(params) ? params : {}),
  });

export const axiosPutRequest = (url = '', headers = {}, data = {}, params) =>
  axios({
    url,
    data,
    baseURL: getApiDashboardBaseUrl(),
    method: 'PUT',
    headers: { ...headers },
    validateStatus: function (status) {
      return status >= 200 && status <= 500;
    },
    ...(isObject(params) && !isEmpty(params) ? params : {}),
  });

export const axiosDeleteRequest = (url = '', headers = {}, data = {}, params) =>
  axios({
    url,
    data,
    baseURL: getApiDashboardBaseUrl(),
    method: 'DELETE',
    headers: { ...headers },
    validateStatus: function (status) {
      return status >= 200 && status <= 500;
    },
    ...(isObject(params) && !isEmpty(params) ? params : {}),
  });
