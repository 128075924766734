import cx from 'classnames';
import styles from './Settings.module.scss';
import DeleteStore from 'components/delete-store';
import { Button, Checkbox, Tooltip, useToast } from '@chakra-ui/react';
import { DeleteIcon } from 'icons';
import { withModalManagerSettings } from 'managers/modal';
import { isEmpty, isFunction, isUndefined } from 'lodash';
import { withStoreManagerSettings } from 'managers/stores';
import { useMemo, useRef, lazy } from 'react';
import { updateStoreInfoPropertyRequest } from 'api/stores';
import { getAuthenticatedHeaders } from 'utils/helpers';
import { InfoCircledIcon } from '@radix-ui/react-icons';

const PurchaseToPoints = lazy(() => import('./settings/PurchaseToPoints'));

const Settings = (props) => {
  const sendStoreSettingsUpdateTimeoutId = useRef(null);
  const { user, storeSelected, updateStoreProps, setActiveModalDom } = props;

  const onDelete = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<DeleteStore />);
    }
  };

  const storeSettings = useMemo(
    () => storeSelected?.settings || {},
    [storeSelected]
  );

  const storeId = useMemo(() => storeSelected?.id, [storeSelected]);

  const toast = useToast({ position: 'top' });

  const updateStoreSettings = (prop = '', value) => {
    if (isFunction(updateStoreProps) && !isEmpty(prop) && !isUndefined(value)) {
      const newStoreSettings = { ...storeSettings, [prop]: value };
      const headers = getAuthenticatedHeaders(user);

      updateStoreProps(storeId, {
        settings: newStoreSettings,
      });

      // debounce
      clearTimeout(sendStoreSettingsUpdateTimeoutId.current);
      sendStoreSettingsUpdateTimeoutId.current = setTimeout(() => {
        updateStoreInfoPropertyRequest(
          storeId,
          { settings: newStoreSettings },
          headers
        );

        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 1_000,
          isClosable: true,
        });

        clearTimeout(sendStoreSettingsUpdateTimeoutId.current);
      }, 500);
    }
  };

  return (
    <div className={styles.panel_settings}>
      <div className={cx(styles.flex_center_all, styles.general)}>
        <div className={cx(styles.flex_center_all, styles.label)}>
          <p>General Settings </p>
        </div>

        <div className={cx(styles.flex_center_all, styles.auto_approve)}>
          <Checkbox
            size="lg"
            colorScheme="green"
            defaultChecked={storeSettings?.autoApproveUser || false}
            checked={storeSettings?.autoApproveUser}
            value={storeSettings?.autoApproveUser}
            onChange={() => {
              // toggle here
              updateStoreSettings(
                'autoApproveUser',
                !storeSettings?.autoApproveUser
              );
            }}
          >
            <div
              className={cx(
                styles.auto_approve_content,
                styles.flex_center_all
              )}
            >
              <p className={styles.auto_approve_text}>
                Auto activate user when scanning.
              </p>

              <Tooltip
                className={styles.custom_tooltip}
                label="You can use this property to launch paid memberships."
              >
                <div className={cx(styles.flex_center_all, styles.tip_icon)}>
                  <InfoCircledIcon height={'18px'} width={'18px'} />
                </div>
              </Tooltip>
            </div>
          </Checkbox>
        </div>
      </div>

      <PurchaseToPoints
        storeSelected={storeSelected}
        updateStoreSettings={updateStoreSettings}
      />

      <div className={cx(styles.flex_center_all, styles.delete_store)}>
        <p>Delete your store profile</p>
        <Button
          className={cx(styles.delete, styles.flex_center_all)}
          variant={'ghost'}
          onClick={onDelete}
        >
          <DeleteIcon height={'20px'} width={'20px'} />
          <p>Delete</p>
        </Button>
      </div>
    </div>
  );
};

export default withStoreManagerSettings(withModalManagerSettings(Settings));
