import cx from 'classnames';
import styles from './Stores.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import { useEffect, useMemo, useState } from 'react';
import {
  filter,
  isEmpty,
  isFunction,
  isObject,
  last,
  map,
  size,
  toString,
} from 'lodash';
import { Button, useToast } from '@chakra-ui/react';
import { ChevronDownIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { withModalManagerSettings } from 'managers/modal';
import CreateNewStore from 'components/create-store';
import { planTypePremiumStore } from 'constants';

export const StoresUserOptionsClassname = 'StoresUserOptionsClassname';
export const storesListComponentId = 'storesListComponentId';

const Stores = (props) => {
  const {
    stores,
    setActiveModalDom,
    visitedStores,
    storeSelected: ownedStoreSelected,
    visitedStoreSelected,
    forStoreOwner,
    selectStore,
    user,
    profileLoaded,
  } = props;

  const isUserPremium = useMemo(
    () => user?.plan?.type === planTypePremiumStore,
    [user]
  );

  const storeSelected = useMemo(
    () => (forStoreOwner ? ownedStoreSelected : visitedStoreSelected),
    [forStoreOwner, ownedStoreSelected, visitedStoreSelected]
  );

  const noStoreSelected = useMemo(
    () => !storeSelected || isEmpty(storeSelected),
    [storeSelected]
  );

  const toast = useToast({
    position: 'top',
  });

  const [showStoreOptions, setShowStoreOptions] = useState(false);

  const storesSanizited = useMemo(() => {
    if (forStoreOwner) {
      return filter(
        stores,
        (storeInfo) => isObject(storeInfo) && !isEmpty(storeInfo?.id)
      );
    } else {
      return filter(
        visitedStores,
        (storeInfo) => isObject(storeInfo) && !isEmpty(storeInfo?.id)
      );
    }
  }, [stores, visitedStores, forStoreOwner]);

  useEffect(() => {
    const onClick = (evt) => {
      const target = evt?.target;

      if (target && target?.classList?.contains(StoresUserOptionsClassname)) {
        const targetStoreId = toString(last(target?.classList || []) || '');

        console.log('targetStoreId', targetStoreId);

        if (
          target?.classList?.contains('createStore') &&
          isFunction(setActiveModalDom)
        ) {
          if (!isUserPremium && size(stores) >= 2) {
            toast({
              title: 'Account limited. Upgrade to premium.',
              status: 'error',
              duration: 1_500,
              isClosable: false,
            });

            return;
          }

          setActiveModalDom(<CreateNewStore />);
          setShowStoreOptions(false);
        } else if (
          !isEmpty(targetStoreId) &&
          targetStoreId !== StoresUserOptionsClassname
        ) {
          if (forStoreOwner && isFunction(selectStore)) {
            selectStore(targetStoreId);
          }

          setShowStoreOptions(false);
        }

        return;
      }

      setShowStoreOptions(false);
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [
    toast,
    stores,
    profileLoaded,
    isUserPremium,
    setActiveModalDom,
    selectStore,
    forStoreOwner,
  ]);

  const emptyStoresList = useMemo(
    () => isEmpty(storesSanizited) || !storesSanizited?.length,
    [storesSanizited]
  );

  const blockStoreOptions = useMemo(
    () =>
      !user?.auth?.token ||
      (!forStoreOwner && (emptyStoresList || size(visitedStores) < 2)) ||
      !forStoreOwner,
    [visitedStores, forStoreOwner, emptyStoresList, user?.auth]
  );

  return (
    <div
      id={storesListComponentId}
      className={cx(styles.flex_center_all, styles.stores)}
    >
      <div className={styles.content}>
        <Button
          variant={'ghost'}
          display={'flex'}
          justifyContent={'center'}
          background={'transparent'}
          onClick={() => {
            if (blockStoreOptions) {
              return;
            }

            setShowStoreOptions(!showStoreOptions);
          }}
        >
          <p>
            {noStoreSelected
              ? 'No store selected'
              : storeSelected?.businessName}
          </p>
          <div
            className={cx(styles.flex_center_all, styles.arrow, {
              [styles.arrow_expand]: showStoreOptions,
            })}
          >
            {(forStoreOwner || !blockStoreOptions) && (
              <ChevronDownIcon height={'20px'} width={'20px'} />
            )}
          </div>

          <div
            className={cx(styles.cover_dom, StoresUserOptionsClassname)}
          ></div>
        </Button>

        {showStoreOptions && (
          <div
            className={cx(styles.stores_options, StoresUserOptionsClassname)}
          >
            <ul
              className={cx(styles.flex_center_all, StoresUserOptionsClassname)}
            >
              {forStoreOwner && (
                <li
                  key={'HeaderOwnerStoresItemCreateNew'}
                  className={cx(
                    styles.create_store,
                    StoresUserOptionsClassname,
                    'createStore'
                  )}
                >
                  <div className={cx(styles.flex_center_all, styles.icon)}>
                    <PlusCircledIcon height={'20px'} width={'20px'} />{' '}
                  </div>
                  <p>{'Create new store'}</p>
                  <div
                    className={cx(
                      styles.cover_dom,
                      StoresUserOptionsClassname,
                      'createStore'
                    )}
                  ></div>
                </li>
              )}

              {map(storesSanizited, (storeInfo) => {
                const storeId = storeInfo?.id || '';
                const storeKey = `HeaderOwnerStoresItem${storeInfo?.id}`;

                return (
                  <li
                    key={storeKey}
                    className={cx(StoresUserOptionsClassname, storeId)}
                  >
                    <p>{storeInfo?.businessName}</p>
                    <div
                      className={cx(
                        styles.cover_dom,
                        StoresUserOptionsClassname,
                        storeId
                      )}
                    ></div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(Stores))
);
