import { isFunction, isNaN, isNumber, toNumber, toString, trim } from 'lodash';
import styles from './UpdateCustomerPoints.module.scss';
import cx from 'classnames';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '@chakra-ui/button';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { updateStoreCustomerPropertyRequest } from 'api/stores';
import { getAuthenticatedHeaders } from 'utils/helpers';
import { useToast } from '@chakra-ui/toast';

const UpdateCustomerPoints = (props) => {
  const {
    targetCustomerInfo,
    blockClosingOfModal,
    unBlockClosingOfModal,
    closeModal,
    storeSelected,
    user,
    updateStoreCustomerProperty,
  } = props;

  const [points, setPoints] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const storeId = useMemo(() => storeSelected?.id || '', [storeSelected]);
  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };
  const toast = useToast({ position: 'top' });

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      const totalPoints = toNumber(parseFloat(trim(points)).toFixed(2));

      if (!isNumber(totalPoints) || isNaN(totalPoints)) {
        setErrorMessage(
          'Invalid points, make sure you provide a valid integer.'
        );
        return;
      }

      setSubmitted(true);

      const headers = getAuthenticatedHeaders(user);
      const userId = targetCustomerInfo?.id;
      const { storeUser, errorMessage, networkError } =
        await updateStoreCustomerPropertyRequest(
          storeId,
          userId,
          { totalPoints: points },
          headers
        );

      if (!errorMessage && !networkError && storeUser) {
        toast({
          title: 'Successfully Updated!',
          duration: 2_500,
          isClosable: true,
          status: 'success',
        });

        if (isFunction(closeModal)) {
          closeModal(true);
        }

        if (isFunction(updateStoreCustomerProperty)) {
          updateStoreCustomerProperty(userId, { totalPoints });
        }
      } else {
        if (networkError) {
          setErrorMessage('Check your network connection.');
        } else {
          setErrorMessage('Something went wrong. Try again.');
        }
      }
    } catch {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  useEffect(() => {
    if (isNumber(targetCustomerInfo?.totalPoints)) {
      setPoints(toString(targetCustomerInfo.totalPoints));
    }
  }, [targetCustomerInfo?.totalPoints]);

  return (
    <div className={styles.update_customer_points}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Update customer points </p>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Existing points</p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_margin
        )}
      >
        <input
          placeholder="youremail@domain.com"
          value={points}
          onChange={(evt) => {
            setPoints(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          type="text"
          aria-label="Your email for login"
        />
      </div>

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted}
        >
          <p>Submit</p>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(UpdateCustomerPoints))
);
