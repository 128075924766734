import cx from 'classnames';
import styles from './HeaderUserOptions.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { useEffect } from 'react';
import { isEmpty, isFunction } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button, useToast } from '@chakra-ui/react';
import { SettingsCogIcon } from 'icons';
import { CardStackIcon, ExitIcon, ReaderIcon } from '@radix-ui/react-icons';
import { withStoreManagerSettings } from 'managers/stores';

export const HeaderUserOptionsClassname = 'HeaderUserOptionsClassname';

const HeaderUserOptions = (props) => {
  const { close, user, logout, storeSelected } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const onClick = (evt) => {
      if (
        evt?.target &&
        evt?.target?.classList?.contains(HeaderUserOptionsClassname)
      ) {
        return;
      }

      if (isFunction(close)) {
        close();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [close]);

  const toast = useToast({ position: 'top' });

  const goToAccountSettings = () => {
    navigate('/account');
  };

  const goToBillingPage = () => {
    navigate('/billing');
  };

  const goToStoreQRPage = () => {
    if (!isEmpty(storeSelected)) {
      navigate(`/portal/qr/${storeSelected?.id}`);
    } else {
      // toast
      toast({
        status: 'error',
        duration: 2_500,
        isClosable: true,
        title: 'No store selected',
      });
    }
  };

  const goLogout = () => {
    navigate('/');

    if (isFunction(logout)) {
      logout();
    }
  };

  return (
    <div className={styles.header_options}>
      <div
        className={cx(
          styles.flex_center_all,
          styles.content,
          HeaderUserOptionsClassname
        )}
      >
        <Button
          height={'40px'}
          type="button"
          variant={'ghost'}
          display={'flex'}
          background={'transparent'}
          justifyContent={'flex-start'}
          className={cx(
            styles.account_settings,
            styles.option,
            HeaderUserOptionsClassname
          )}
          onClick={goToAccountSettings}
        >
          <div className={cx(styles.flex_center_all, styles.icon)}>
            <SettingsCogIcon height={'20px'} width={'20px'} />
          </div>
          <p>Account Settings</p>
          <div
            className={cx(styles.cover_dom, HeaderUserOptionsClassname)}
          ></div>
        </Button>

        {user?.storeOwner && (
          <Button
            height={'40px'}
            type="button"
            variant={'ghost'}
            display={'flex'}
            background={'transparent'}
            justifyContent={'flex-start'}
            className={cx(
              styles.store_qr_page,
              styles.option,
              HeaderUserOptionsClassname
            )}
            onClick={goToStoreQRPage}
          >
            <div className={cx(styles.flex_center_all, styles.icon)}>
              <ReaderIcon height={'20px'} width={'20px'} />
            </div>
            <p>Store QR Page</p>

            <div
              className={cx(styles.cover_dom, HeaderUserOptionsClassname)}
            ></div>
          </Button>
        )}

        {user?.storeOwner && (
          <Button
            height={'40px'}
            type="button"
            variant={'ghost'}
            display={'flex'}
            background={'transparent'}
            justifyContent={'flex-start'}
            className={cx(
              styles.billing_details,
              styles.option,
              HeaderUserOptionsClassname
            )}
            onClick={goToBillingPage}
          >
            <div className={cx(styles.flex_center_all, styles.icon)}>
              <CardStackIcon height={'20px'} width={'20px'} />
            </div>
            <p>Billing Details</p>

            <div
              className={cx(styles.cover_dom, HeaderUserOptionsClassname)}
            ></div>
          </Button>
        )}

        <Button
          height={'40px'}
          type="button"
          variant={'ghost'}
          display={'flex'}
          background={'transparent'}
          justifyContent={'flex-start'}
          className={cx(
            styles.store_qr_apge,
            styles.option,
            HeaderUserOptionsClassname
          )}
          onClick={goLogout}
        >
          <div className={cx(styles.flex_center_all, styles.icon)}>
            <ExitIcon height={'20px'} width={'20px'} />
          </div>
          <p>Logout</p>

          <div
            className={cx(styles.cover_dom, HeaderUserOptionsClassname)}
          ></div>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(HeaderUserOptions)
);
