import styles from './Notifications.module.scss';
import cx from 'classnames';
import { withStoreManagerSettings } from 'managers/stores';
import { useMemo } from 'react';
import { isEmpty, isFunction, map, size } from 'lodash';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { withUserProfileSettings } from 'managers/profile';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import NotificationItem from './NotificationItem';

const pageSize = 20;

const Notifications = (props) => {
  const {
    storeNotifications,
    storeNotificationsPage,
    storeNotificationsFetching,
    storeNotificationsHasNext,
    storeNotificationsTotalCount,
    getStoreNotificationsNextPage,
    getStoreNotificationsPrevPage,
  } = props;

  const start = useMemo(
    () =>
      storeNotificationsPage < 2
        ? !isEmpty(storeNotifications)
          ? 1
          : 0
        : (storeNotificationsPage - 1) * pageSize,
    [storeNotificationsPage, storeNotifications]
  );

  const end = useMemo(
    () =>
      storeNotificationsPage < 2
        ? size(storeNotifications)
        : start +
          (size(storeNotifications) < pageSize
            ? size(storeNotifications)
            : pageSize - 1),
    [start, storeNotifications, storeNotificationsPage]
  );

  const emptyList = useMemo(
    () =>
      !storeNotificationsFetching &&
      (!storeNotifications?.length || isEmpty(storeNotifications)),
    [storeNotificationsFetching, storeNotifications]
  );

  const toast = useToast({ position: 'top' });

  const goPrevPage = () => {
    if (isFunction(getStoreNotificationsPrevPage)) {
      getStoreNotificationsPrevPage();
    }
  };

  const goNextPage = () => {
    if (isFunction(getStoreNotificationsNextPage)) {
      getStoreNotificationsNextPage();
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.notifications)}>
      <div className={cx(styles.flex_center_all, styles.page_info)}>
        <div className={cx(styles.flex_center_all, styles.page_info_content)}>
          <p>{`Total notifications ( ${storeNotificationsTotalCount} )`}</p>

          <div className={cx(styles.flex_center_all, styles.nav)}>
            <Button
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'40px'}
              width={'40px'}
              variant={'ghost'}
              borderRadius={'50%'}
              onClick={goPrevPage}
            >
              <ChevronLeftIcon height={'20px'} width={'20px'} />
            </Button>

            <p>{`${start} - ${end}`}</p>

            <Button
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'40px'}
              width={'40px'}
              variant={'ghost'}
              borderRadius={'50%'}
              onClick={() => {
                if (!storeNotificationsHasNext) {
                  toast({
                    title: 'End of list.',
                    duration: 1_500,
                    status: 'info',
                  });

                  return;
                }

                goNextPage();
              }}
            >
              <ChevronRightIcon height={'20px'} width={'20px'} />
            </Button>
          </div>
        </div>
      </div>

      {!emptyList && (
        <div className={styles.list}>
          <ul>
            {map(storeNotifications, (notification) => {
              const notifId = notification?.id;
              const cacheKey = `PanelNotificationKey${notifId}`;

              return (
                <li key={cacheKey}>
                  <NotificationItem notification={notification} />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {emptyList && (
        <div className={cx(styles.flex_center_all, styles.empty_list)}>
          <div className={styles.flex_center_all}>
            <p>No results.</p>
          </div>
        </div>
      )}

      {storeNotificationsFetching && isEmpty(storeNotifications) && (
        <div className={cx(styles.flex_center_all, styles.loading)}>
          <Spinner height={'24px'} width={'24px'} />
        </div>
      )}
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(Notifications));
