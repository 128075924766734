import cx from 'classnames';
import styles from './PrivacyPolicy.module.scss';
import Header from 'pages/home/Header';
import { useEffect } from 'react';
import { AppRootContainerId } from 'App';

const PrivacyPolicy = () => {
  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    window.scrollTo(0, 0);

    if (dom?.scrollTo) {
      dom.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={cx(styles.flex_center_all, styles.privacy_policy)}>
      <Header />
      <div className={styles.content}>
        <div className={cx(styles.flex_center_all, styles.banner)}>
          <p>Privacy Policy</p>
        </div>
        <div className={styles.texts}>
          <br></br>
          <br></br>
          <section>
            <h2>Introduction</h2>
            <br></br>
            <p>
              Welcome to <span>Melonkarma!</span> Your privacy is important to
              us. This Privacy Policy explains how we collect, use, and protect
              your information when you use our platform and related services.
              By using our Service, you agree to this policy. This Privacy
              Policy applies to all users, including "Merchants" and
              "Customers." "Users" refer to anyone using our Services.
            </p>
            <br></br>
            <p>
              Please read this Privacy Policy to understand how we handle your
              data. If you have any questions, feel free to contact us. Your
              continued use of our Service means you accept this Privacy Policy
              and any future updates.
            </p>
            <br></br>
            <p>
              If you do not agree with this Policy, you must not use the
              Service. If you change your mind in the future, you must stop
              using the Service and you may exercise your rights in relation to
              your Information as set out in this Policy.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Use of Information</h2>
            <br></br>
            <p>
              <span>Rewards System for Merchants and Customers</span>
            </p>
            <br></br>
            <p>
              The primary purpose of collecting your email address is to enable
              you, as a merchant or customer, to participate in our rewards
              system. By providing your email address, you can access and manage
              your merchant/customers account, view rewards, and receive updates
              regarding your account status.
            </p>
            <br></br>
            <p>
              <span>Communication</span>
            </p>
            <br></br>
            <p>
              We may use your email address to communicate with you about
              important updates, account notifications, changes to our services,
              and promotions related to <span>Melonkarma</span>. These
              communications are necessary to ensure the proper functioning of
              the rewards system and to provide you with relevant information.
            </p>
          </section>
          <section>
            <h2>Collection of information</h2>
            <br></br>
            <p>
              <span>Logging of data</span>
            </p>
            <br></br>
            <p>
              We store our data in US servers. When you visit our website, our
              servers may automatically record the information in server log
              files. These log files may include your computer’s Internet
              Protocol (IP) address, your browser type and version, and the time
              and date of your visit.
            </p>
            <p>
              Additionally, we may collect and store other personal information
              you provide to us, such as Name, Email address, and Location. We
              retain this information for as long as necessary to fulfill the
              purposes for which it was collected, to comply with our legal
              obligations, resolve disputes, and enforce our agreements with
              third parties.
            </p>{' '}
            <br></br>
            <p>
              <span>Personal information</span>
            </p>
            <br></br>
            <p>
              This includes any personal contact information that will allow us
              to communicate with you and account login information to give you
              access to your specific account profile, such as but not limited
              to the following:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Cellphone Number</li>
            </ul>
          </section>
          <br></br>
          <section>
            <h2>Security of information</h2>
            <br></br>
            <p>
              Melonkarma take the security and integrity of your personal
              information seriously. While we have implemented appropriate
              administrative, technical, and physical security measures, no
              method of data transmission over the Internet can be guaranteed
              100% secure. Therefore, we cannot ensure absolute security of your
              personal information.
            </p>
          </section>
          <section>
            <h2>Disclosure of personal information to third parties</h2>
            <br></br>
            <p>
              We do not sell or trade any of your information, otherwise we
              disclose them to trusted third party services helping us deliver
              the features we offer. We share your Information with selected and
              trusted third parties, including:
            </p>{' '}
            <br></br>
            <ul>
              <li>
                Other users: Your profile information and any information you
                choose to share with other users through our services.
              </li>
              <li>
                Cloud service provider: We rely on a trusted provider for data
                storage.
              </li>
              <li>
                Payment processors: They process your information and may use it
                for their own purposes in accordance with their privacy
                policies.
              </li>
              <li>
                Analytics providers: We use Fathom, a GDPR-compliant analytics
                service provided by usefathom.com, to collect analytics about
                our site visitors and improve our service and product.
              </li>
              <li>
                Law enforcement agencies and organizations: If required by law
                or to protect our rights and interests.
              </li>
            </ul>
            <br></br>
            <p>
              We may also share your personal information in the following
              circumstances:
            </p>{' '}
            <br></br>
            <ul>
              <li>
                With your consent: We may share your information with third
                parties if we have obtained your explicit consent to do so.
              </li>
              <li>
                Aggregated or anonymized data: We may disclose aggregated or
                anonymized information that does not personally identify you for
                marketing, advertising, research, or other purposes.
              </li>
              <li>
                Change of ownership: In the event of a merger, acquisition, or
                transfer of assets, your personal information may be transferred
                to the acquiring entity, subject to the same privacy commitments
                outlined in this Privacy Policy.
              </li>
            </ul>{' '}
          </section>
          <section>
            <h2>Your rights</h2>
            <br></br>
            <p>
              The personal information you have provided us shall be retained
              for as long as necessary to fulfill the purposes for which it was
              collected, to comply with our legal obligations, resolve disputes,
              and enforce our agreements with third parties. Where provided by
              law, you can exercise your particular right to:
            </p>
            <br></br>
            <ul>
              <li>
                If you believe that any information we hold about you is
                inaccurate, out of date, incomplete, irrelevant or misleading,
                please contact us using the details below. We will take
                reasonable steps to correct and modify your information;
              </li>
              <li>
                If you believe that we have breached your rights under
                applicable privacy laws and wish to make a complaint, please
                contact us and provide full details of the alleged breach. Rest
                assured that we will acknowledge, investigate, and take
                corresponding actions with regard to any complaint about the way
                we manage personal information. Nonetheless, if you have
                unresolved concerns, you also have the right to complain to
                competent data protection authorities.
              </li>
            </ul>
          </section>
          <br></br>
          <section>
            <h2>Cookies and Tracking</h2>
            <br></br>
            <p>
              We do not use cookies or tracking technologies to gather any
              information about our users. We do not engage in any form of
              behavioral tracking or targeted advertising.
            </p>
            <p>How we use cookies</p>
            <br></br>
            <ul>
              <li>
                We need to use some certain necessary cookies to make sure our
                website functions properly as a web application.
              </li>
              <li>
                We collect analytics about the types of people who visit our
                site to improve our service and product.
              </li>
              <li>
                We may also use third-party tracking tools or services to
                collect information about your browsing activities on our
                website for analytics and advertising purposes. These
                third-party tools may use cookies, web beacons, or similar
                technologies to track and analyze your usage patterns on our
                website.
              </li>
            </ul>
          </section>
          <br></br>
          <section>
            <h2>Security Measures</h2>
            <br></br>
            <p>
              We have implemented industry-standard security measures to protect
              your information from unauthorized access, alteration, or
              disclosure. Our platform employs encryption techniques and
              safeguards to maintain the confidentiality and integrity of your
              data.
            </p>
          </section>{' '}
          <br></br>
          <section>
            <h2>Children's Privacy</h2>
            <br></br>
            <p>
              <span> Melonkarma </span> is not intended for use by individuals
              under the age of 13. We do not knowingly collect any personal
              information from children. If we become aware that a user is under
              the age of 13, we will promptly delete any information associated
              with that user.
            </p>
          </section>{' '}
          <br></br>
          <section>
            <h2>Changes to the Privacy Policy</h2> <br></br>
            <p>
              We may occasionally update this Privacy Policy to reflect changes
              in our practices or legal requirements. We encourage you to review
              this policy periodically to stay informed about how we protect
              your privacy.
            </p>
            <br></br>
            <p>
              If you have any queries, comments, or requests about this Privacy
              policy, your data, or your rights with respect to your
              information, please connect with us by dropping an email at
              support@melonkarma.com
            </p>
          </section>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
