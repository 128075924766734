import cx from 'classnames';
import styles from './CustomerSelection.module.scss';
import { Avatar, Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { useMemo } from 'react';
import { head, isEmpty, isFunction, isObject, pick } from 'lodash';
import { withUserProfileSettings } from 'managers/profile';
import { scannedOption } from '.';
import { ChevronRightIcon } from '@radix-ui/react-icons';

const CustomerSelection = (props) => {
  const { pickScanOption, customer } = props;

  const hasCustomerProfileStored = useMemo(
    () =>
      isObject(customer) && !isEmpty(customer?.id) && !isEmpty(customer?.email),
    [customer]
  );

  return (
    <div className={styles.customer_selection}>
      <ul>
        {hasCustomerProfileStored && (
          <li>
            <Button
              aria-label="Pick existing user profile"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'space-between',
              }}
              className={styles.existing_user}
              onClick={() => {
                if (isFunction(pickScanOption)) {
                  pickScanOption(
                    scannedOption.storedAccount,
                    pick(customer, ['fullName', 'email'])
                  );
                }
              }}
            >
              <div
                className={cx(
                  styles.flex_center_all,
                  styles.existing_user_info
                )}
              >
                <Avatar
                  src={customer?.image || ''}
                  height={'30px'}
                  width={'30px'}
                  background={'black'}
                  name={head(customer?.fullName || customer?.email)}
                />

                <p>{`${customer?.fullName} `}</p>
              </div>

              <div className={styles.existing_user_email}>
                <p>{customer?.email}</p>
              </div>

              <p className={styles.flex_center_all}>
                <span>Use this instead</span>
                <ChevronRightIcon height={'16px'} width={'16px'} />
              </p>
            </Button>
          </li>
        )}
        <li>
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit)}
            color="#fff"
            onClick={() => {
              if (isFunction(pickScanOption)) {
                pickScanOption(scannedOption.existingAccount);
              }
            }}
          >
            <p>I have an existing account</p>
          </Button>
        </li>{' '}
        <li>
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit)}
            color="#fff"
            onClick={() => {
              if (isFunction(pickScanOption)) {
                pickScanOption(scannedOption.newAccount);
              }
            }}
          >
            <p>New account</p>
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default withUserProfileSettings(CustomerSelection);
