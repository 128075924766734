import cx from 'classnames';
import styles from './ForgotPassword.module.scss';
import { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { isFunction, toString, trim } from 'lodash';
import { primaryGreenColor } from 'constants';
import { sendForgotPasswordEmailRequest } from 'api';
import { isCorrectEmailFormat, isSafeEmailAddress } from 'utils/helpers';
import { withModalManagerSettings } from 'managers/modal';

const ForgotPassword = (props) => {
  const { blockClosingOfModal, unBlockClosingOfModal, closeModal } = props;
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toast = useToast({ position: 'top' });

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      if (!isSafeEmailAddress(email) || !isCorrectEmailFormat(email)) {
        setErrorMessage('Invalid email address.');
        return;
      }

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      setErrorMessage('');
      setSubmitted(true);
      const { success } = await sendForgotPasswordEmailRequest(trim(email));

      toast({
        status: 'success',
        title: 'Successfully sent!',
        duration: 3_000,
        isClosable: true,
      });

      if (success && isFunction(closeModal)) {
        closeModal(true);
      }
    } catch (err) {
    } finally {
      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }

      setSubmitted(false);
    }
  };

  return (
    <div className={styles.forgot_password}>
      <div className={styles.label}>
        <p>Forgot your password? We'll send you a reset link. </p>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Email </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_margin
        )}
      >
        <input
          placeholder="youremail@domain.com"
          value={email}
          onChange={(evt) => {
            setEmail(toString(evt?.target?.value || ''));

            if (errorMessage) {
              setErrorMessage('');
            }
          }}
          type="text"
          aria-label="Your email for login"
        />
      </div>

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>
      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          isLoading={submitted}
          onClick={submit}
        >
          <p>Submit</p>
        </Button>
      </div>
    </div>
  );
};

export default withModalManagerSettings(ForgotPassword);
