import { useCallback } from 'react';

const useAuth = (props) => {
  const { user } = props;

  const getAuthenticatedHeaders = useCallback(() => {
    const token = user?.auth?.token || '';

    return {
      authorization: `BEARER ${token}`,
    };
  }, [user?.auth?.token]);

  return {
    getAuthenticatedHeaders,
  };
};

export default useAuth;
