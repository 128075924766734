import { head, includes, isEmpty, split, toLower } from 'lodash';
import { axiosPutRequest } from './axios';
import { cloudFunctionUrlUsCentral } from 'constants';

export const isNetworkError = (err) => {
  try {
    const sanitizedErrorMessage = toLower(toString(err?.message || ''));

    if (
      includes(sanitizedErrorMessage, 'network error') ||
      includes(sanitizedErrorMessage, 'error network') ||
      (includes(sanitizedErrorMessage, 'network') &&
        includes(sanitizedErrorMessage, 'error'))
    ) {
      // mark user is offline
      return true;
    }
  } catch {}

  return false;
};

export const isSuccessStatus = (res) => {
  return res?.status === 200 || res?.status === 201;
};

/**
 * Get user country on init
 * @returns
 */
export async function probeUser() {
  let networkError = false;
  let country = '';
  let region = '';
  let errorMessage = '';

  try {
    const res = await axiosPutRequest(`${cloudFunctionUrlUsCentral}/_p/probe`);
    if (isSuccessStatus(res) && !isEmpty(res?.data?.l)) {
      const stringSplit = split(res.data.l, ',');
      country = head(stringSplit);
      region = stringSplit[1];
    } else if (res?.data?.message) {
      errorMessage = res?.data?.message;

      throw new Error(res.data.message);
    } else {
      throw new Error(`${res?.statusText} ${res?.status}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { region, country, networkError, err: errorMessage };
}

export * from './users';
