import cx from 'classnames';
import styles from './Rewards.module.scss';
import StoreRewardForm from 'components/store-reward-form';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { filter, isFunction, map, size } from 'lodash';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';

import StoreReward from 'components/store-reward';
import NonPremiumCTA from './NonPremiumCTA';

const Rewards = (props) => {
  const {
    userIsPremium,
    setActiveModalDom,
    storeRewards,
    storeRewardsFetching,
  } = props;
  const toast = useToast({ position: 'top' });

  const triggerCreateModal = () => {
    if (!userIsPremium) {
      return;
    }

    const limit = 15;
    if (size(storeRewards) >= limit) {
      toast({
        duration: 2_500,
        status: 'error',
        title: `Rewards is limited to ${limit} items for now.`,
      });

      // limit store rewards for now
      return;
    }

    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<StoreRewardForm />);
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.rewards)}>
      {userIsPremium && (
        <div className={cx(styles.flex_center_all, styles.cta)}>
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.trigger_create)}
            color="#fff"
            onClick={triggerCreateModal}
          >
            <p>Create a reward</p>
          </Button>
        </div>
      )}

      {!userIsPremium && <NonPremiumCTA />}
      <div className={styles.list}>
        <ul>
          {map(filter(storeRewards), (reward) => {
            const key = `DashboardRewardsList${reward?.id || ''}`;

            return (
              <li key={key}>
                <StoreReward allowModification reward={reward} />
              </li>
            );
          })}
        </ul>
      </div>

      {storeRewardsFetching && (
        <div className={cx(styles.flex_center_all, styles.fetching_list)}>
          <Spinner height={'26px'} width={'26px'} />
        </div>
      )}
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(Rewards))
);
