import cx from 'classnames';
import styles from './Security.module.scss';
import { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { EyeCloseIcon, EyeIcon } from 'icons';
import { size, toString } from 'lodash';
import { primaryGreenColor } from 'constants';
import { minPassword } from 'constants';
import { updateUserAccountInfoRequest } from 'api';
import { withUserProfileSettings } from 'managers/profile';
import { useAuth } from 'hooks';
import './Security.scss';

const Security = (props) => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({
    position: 'top',
    duration: 3_000,
    isClosable: true,
    id: 'chakraToastSecurity',
  });

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      if (!password || size(password) < minPassword) {
        setErrorMessage(
          `Password must at least contain ${minPassword} characters`
        );
        return;
      }

      const headers = getAuthenticatedHeaders();
      setSubmitted(true);

      const { success } = await updateUserAccountInfoRequest(headers, {
        password,
      });

      if (success) {
        toast({
          status: 'success',
          title: 'Successfully Updated Password!',
        });
        setPassword('');
        setShowPassword(false);
      }
    } catch {
    } finally {
      setSubmitted(false);
    }
  };

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  return (
    <div className={styles.security}>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label,
          styles.input_label_account
        )}
      >
        <p>Password </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="******"
          value={password}
          onChange={(evt) => {
            setPassword(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          type={showPassword ? 'text' : 'password'}
          aria-label="Fill out your password"
        />
        <div className={cx(styles.flex_center_all, styles.input_right_icon)}>
          <Button
            variant={'unstyled'}
            aria-label="Show password"
            className={cx(styles.flex_center_all, styles.toggle_show)}
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <EyeIcon />
            ) : (
              <EyeCloseIcon height={'18px'} width={'18px'} />
            )}
          </Button>
        </div>
      </div>

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted}
        >
          <p>Update password</p>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(Security);
