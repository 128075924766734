import cx from 'classnames';
import styles from './TermsService.module.scss';
import Header from 'pages/home/Header';
import { useEffect } from 'react';
import { AppRootContainerId } from 'App';

const TermsService = () => {
  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    window.scrollTo(0, 0);

    if (dom?.scrollTo) {
      dom.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={cx(styles.flex_center_all, styles.terms_service)}>
      <Header />
      <div className={styles.content}>
        <div className={cx(styles.flex_center_all, styles.banner)}>
          <p>Terms of Service</p>
        </div>
        <div className={styles.texts}>
          <p>
            Welcome to <span>melonkarma!</span> These Terms of Service ("Terms")
            govern your use of the <span>Melonkarma</span> platform and related
            services ("Service"). By accessing or using our Service, you agree
            to be bound by these Terms and any additional terms incorporated by
            reference. If you do not agree with any part of these Terms, please
            do not use our Service.
          </p>
          <br></br>
          <p>
            This Agreement is applicable to all individuals or entities using
            our Services, including "Merchants" who utilize our Services to
            manage loyalty reward programs for their customers under a Selected
            Subscription Plan, and "Customers" who use our Services to track and
            manage their loyalty points with one or more Merchants. The term
            "Users" refers to all end users of our Services, encompassing
            employees, representatives, consultants, contractors, agents of
            Merchants, Customers, and any other end users.
          </p>
          <br></br>
          <p>
            The pronouns "You" and "your" refer to you as an individual user or
            the legal entity you represent when entering into this Agreement on
            behalf of another legal entity. If you are an individual user,
            including a Customer or a Merchant operating as a sole proprietor,
            you are also considered a User. If you are a User representing
            another legal entity, such as a corporation, you confirm that you
            have the authority to bind that entity to this Agreement. You are
            responsible for ensuring that every employee, representative,
            consultant, contractor, or agent using the Services on your behalf
            complies with this Agreement. If you are a Merchant, this includes
            any User who creates an account associated with you ("Account").
          </p>
          <br></br>
          <p>
            Even if you access the Services through an integrated platform or
            another third-party platform, this Agreement governs your use of our
            Services.
          </p>
          <br></br>
          <section>
            <h2>Account Registration and Usage</h2>
            <br></br>
            <p>
              <span>Account Creation:</span> To access and use melonkarma, you
              must create a user account. During the registration process, you
              will need to provide personal information, including your name,
              email address, username, and password. All personal information
              you provide is subject to our Privacy Policy. By creating an
              account, you confirm that you are at least 13 years old. You are
              solely responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. If you suspect any unauthorized access or use of your
              account, please notify us immediately. You agree to provide
              accurate and truthful information during registration and to
              update your information as necessary.
            </p>
            <br></br>
            <p>
              <span> Logo Usage: </span> Upon registering and using our app,
              each User agrees to grant Melonkarma the right to automatically
              use their logo or brand identity within the app's interface. This
              allows us to personalize the user experience and enhance the
              branding for each individual Merchant or Customer. By using our
              Services, Users confirm that they have the necessary rights and
              permissions to grant Melonkarma this logo usage.
            </p>
            <br></br>
            <p>
              <span> Content:</span> The Services contain information and data
              (including text, images, photos, videos, audio, and documents)
              owned or licensed by Melonkarma, its suppliers, or licensors
              ("Melonkarma Content"). The Services and Melonkarma Content may be
              protected by copyright, trademark, patent, trade secret, and other
              laws. As between you and Melonkarma, Melonkarma owns and retains
              all title, interest, and rights, including all registered and
              unregistered rights granted, applied for, or otherwise existing
              now or in the future under any patent, copyright, trademark, trade
              secret, database protection, or other intellectual property rights
              laws. This includes all similar or equivalent rights or forms of
              protection in any part of the world, relating to the Services and
              Melonkarma Content.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Merchant Data</h2>
            <br></br>
            <p>
              <span> User Content:</span> You can create, record, submit,
              publish, and use ("submit") text, images, photos, videos, audio,
              and documents ("User Content") through the Services. You are
              exclusively responsible for all User Content and the consequences
              of submitting User Content through the Services. We do not verify
              the accuracy, quality, content, or legality of User Content. We
              may, but are under no obligation to, review, analyze, filter,
              edit, block, or remove any User Content. We are not responsible
              for preventing or identifying infringement of intellectual
              property rights or non-compliance with Applicable Laws.{' '}
              <span>Melonkarma</span>
              will not be liable, directly or indirectly, in any way for any
              damage or loss caused or alleged to be caused by or in connection
              with User Content.
            </p>
            <br></br>
            <p>
              <span> Responsibility to Obtain Consents:</span> You are
              responsible for obtaining all necessary consents, licenses, and
              waivers to use the Services, including submitting User Content. If
              you are a Merchant, you must obtain the necessary rights,
              releases, and consents from your customers and other third parties
              for their data. By using the Services, you confirm that you have
              obtained all required rights and consents for data usage as per
              this Agreement.
            </p>
            <br></br>
            <p>
              <span>Anonymized Merchant Data:</span> If you're a Merchant, you
              give Melonkarma the right to use the data you provide, along with
              User Content and other Service usage, to create anonymous and
              combined information ("Anonymized Aggregated Data"). This data
              will not identify you, your Users, customers, or anyone else.
            </p>

            <br></br>

            <p>
              You understand that Melonkarma: (i) owns the Anonymized Aggregated
              Data; (ii) can create new information and insights from it; (iii)
              may use it to enhance the Services or for other purposes; and (iv)
              can share, sell, or publish it to others without revealing your
              identity.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Use of the Service </h2>
            <br></br>
            <p>
              <span>License: </span> melonkarma grants you a limited,
              non-exclusive, non-transferable, revocable license to access and
              use the Service for its intended purposes, subject to compliance
              with these Terms.
            </p>
            <br></br>
            <p>
              <span> Subscription Services:</span> With the exception of free
              Services, you acquire access to the Services by subscribing to a
              chosen plan ("Selected Subscription Plan"). As long as you adhere
              to this Agreement and pay all relevant fees, Melonkarma grants you
              the right to use the Services throughout the duration of your
              subscription ("Subscription Term"), as specified in your Selected
              Subscription Plan, and any other Services explicitly provided for
              free.
            </p>
            <br></br>
            <p>
              <span>Account Access: </span> To access the Platform, each User
              must register and create an Account with Melonkarma, providing
              certain information, which may include a name, email address,
              login username, and password (collectively, "ID"). You are
              responsible for maintaining the confidentiality of the IDs and
              will not use the IDs of any third party, disclose your IDs to any
              third party, or permit Users to share IDs. You are responsible for
              all activities, charges, and liabilities made on or through your
              Accounts, whether authorized or unauthorized by you. If you
              suspect any unauthorized use with any Account or any use of an ID
              by anyone other than the applicable User, you must notify us
              immediately. You will ensure each of your Users provides correct
              and complete Account information at all times and inform us of any
              changes to the information your Users have provided.
            </p>
            <br></br>
            <p>
              <span> Usage Limits:</span> The Services may have specific usage
              limits and restrictions, which could be mentioned in a Selected
              Subscription Plan or available on the Services. These limits might
              include the locations from which you can access or use the
              Services, the number of Users, recipients, or customers allowed,
              and usage volumes ("Usage Limits"). It's your responsibility to
              make sure you stay within these Usage Limits. We may update or
              modify these limits by giving you notice.
            </p>
            <br></br>
            <p>
              <span>Equipments: </span> It is your responsibility to acquire and
              maintain all the necessary telecommunications, broadband, computer
              hardware, software, equipment, and services required to connect
              to, access, and use the Services.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Rewards System</h2>
            <br></br>
            <p>
              As part of our Services, we offer a reward system called "Seed,"
              which allows Users to earn points based on their activities and
              interactions within the app. These Seeds can be accumulated and
              used to redeem various coupons or vouchers, provided by the
              merchants registered in our app. Please note that the Seeds are
              not transferable.
            </p>
            <br></br>

            <p>
              The Merchant reserves the right to change, suspend, or cancel all
              or a portion of the Rewards Program, including any points you may
              have accrued, at any time. They will generally make reasonable
              efforts to notify you of any material changes, suspensions, or
              cancellations to the Rewards Program.
            </p>

            <br></br>
            <p>
              For avoidance of doubt, Melonkarma shall not be responsible for,
              nor obligated to award points or Rewards to Rewards Program
              participants for any Activity that is not properly recorded,
              tracked, and/or deemed approved under Melonkarma's or its
              third-party Rewards Program affiliates' policies, procedures, and
              systems. Any points or Rewards previously granted may be revoked
              in appropriate circumstances.
            </p>
            <br></br>

            <p>
              Please review all applicable Additional Terms before
              participating, as there may be limitations on Activities and
              Rewards. For example, we reserve the right to request and verify
              receipts of completed purchases prior to or in connection with the
              awarding of points for shopping Activities, to verify the validity
              of such purchases with the applicable merchant.
            </p>
            <br></br>
            <p>
              By using our Services, you agree that we may contact you by phone,
              using email or SMS, or text to verify transactions and/or prevent
              potential fraud or misuse of our Melonkarma app and features.
            </p>
            <br></br>
            <p>
              Certain limitations on Activities and rewards may include (without
              limitation) executed by the Merchants: the right to change or
              limit your ability to participate in certain Activities or the
              Rewards Program itself; the right to change or limit the allowable
              frequency of Activities; the right to change or limit the number
              of points you can earn for a given Activity or during a given time
              period; and the right to change the Activities or Rewards
              available, or the number of points required for a particular
              reward.
            </p>

            <br></br>
            <p>Please note that points awarded have no cash value.</p>
          </section>
          <br></br>
          <section>
            <h2>Fees</h2>
            <br></br>
            <p>
              <span> Service Fees:</span> You are required to pay all the fees
              specified in your Selected Subscription Plan ("Service Fees").
              These fees are non-cancellable and non-refundable, and they are
              based on the purchased Subscription Plan, not actual usage unless
              stated otherwise. In the event of unused Services, including if we
              terminate or modify the Services for any reason, you are not
              entitled to any refunds.
            </p>
            <br></br>
            <p>
              <span> Taxes: </span> All Service Fees, expenses, and other
              payments to Melonkarma under this Agreement do not include any
              sales, use, value-added, excise, or other relevant taxes, tariffs,
              or duties ("Taxes"). It is your sole responsibility to pay these
              Taxes, even if they are not explicitly listed on the Selected
              Subscription Plan. If Melonkarma pays any Taxes on your behalf,
              you will promptly reimburse Melonkarma, unless you provide
              satisfactory documentation proving that such Taxes are not
              applicable to you..
            </p>
            <br></br>
            <p>
              <span> Payment Terms:</span> Unless specified otherwise in a
              Selected Subscription Plan or another agreement, Service Fees are
              due before the start of the Subscription Term. You will pay all
              Service Fees, expenses, and other amounts to Melonkarma as
              outlined in the Selected Subscription Plan or another agreement,
              in the currency specified, without any set-off or deduction. While
              we may offer third-party payment service options, we are not
              liable or responsible for these services or their providers in any
              manner.
            </p>
            <br></br>
            <p>
              <span> Prohibited Conduct:</span> While using the Service, you
              agree not to engage in any of the following activities:
            </p>
            <br></br>
            <ul>
              <li>
                Violating any applicable laws, regulations, or third-party
                rights.
              </li>
              <br></br>
              <li>
                Impersonating any person or entity or providing false
                information.
              </li>
              <br></br>
              <li>
                Uploading, transmitting, or distributing any harmful, offensive,
                or infringing content.
              </li>
              <br></br>
              <li>
                Engaging in any activity that may harm, disrupt, or interfere
                with the operation of melonkarma or its users.
              </li>
              <br></br>
              <li>
                Collecting or harvesting user information without their consent.
              </li>
            </ul>
          </section>
          <br></br>
          <section>
            <h2>Moderation</h2>
            <br></br>
            <p>
              <span> Reporting Abuse: </span> If you come across any content or
              user activity that violates these Terms or is abusive, harassing,
              or infringing, please send us an email report immediately at
              admin@melonkarma.com
            </p>
            <br></br>
            <p>
              <span>User Intellectual Property:</span> You retain ownership of
              the intellectual property rights in the content you post or share
              on <span>melonkarma</span>. However, by posting or sharing
              content, you grant other users a non-exclusive, worldwide,
              royalty-free license to access, use, reproduce, publicly display,
              and distribute that content on melonkarma.
            </p>
            <br></br>
            <p>
              <span>Trademarks:</span> As part of your Selected Subscription
              Plan, you may be required or allowed to display trademarks owned
              or licensed by Melonkarma ("Melonkarma Marks"). You are authorized
              to display these marks solely to indicate that you use
              Melonkarma's services, following any guidelines provided by us.
              Any goods or services associated with the Melonkarma Marks must
              comply with this Agreement and include a loyalty program operated
              by Melonkarma. The goodwill resulting from such use will
              automatically accrue to Melonkarma. Melonkarma reserves the right
              to inspect the goods and services you offer for compliance with
              this Agreement, with reasonable notice to you.
            </p>
            <br></br>
            <p>
              <span> Feedback:</span> If you, your Users, or your customers
              provide us with information like feedback, comments, or
              suggestions, you grant us a worldwide, perpetual, irrevocable,
              royalty-free, fully paid-up, non-exclusive, sublicensable, and
              transferable license to use, host, run, reproduce, process, adapt,
              translate, modify, publish, transmit, distribute, and display this
              information. We may incorporate this information into our products
              and Services. By providing this information, you also waive all
              moral rights in and to it and obtain waivers of moral rights from
              your Users and customers.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Termination</h2>
            <br></br>
            <p>
              These Terms will remain in effect until terminated by either
              party. You may terminate your account at any time by contacting
              our support team. We may also terminate or suspend your account
              and access to the Service at any time for any reason, including
              but not limited to violations of these Terms.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Disclaimer</h2>
            <br></br>

            <ul>
              <li>
                The materials and products on this Platform are provided on an
                'as is' basis. <span>Melonkarma</span> does not warrant or make
                any representations with respect to the accuracy of the results,
                or reliability of the use of the materials on this website.
              </li>
              <li>
                To the fullest extent permitted by law, <span>melonkarma </span>{' '}
                disclaims all warranties, express or implied, including but not
                limited to, implied warranties of title, or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </li>
            </ul>
          </section>
          <br></br>

          <section>
            <h2>Modifications</h2>
            <br></br>
            <p>
              <span>Melonkarma</span> may need to revise the website’s terms of
              service from time to time. Please check these Terms periodically
              for changes. Your continued use of this website will be regarded
              as acceptance to be bound by the current version of these terms of
              service.
            </p>
          </section>
          <br></br>
          <section>
            <h2>Governing Law and Jurisdiction</h2>
            <br></br>
            <p>
              These terms and conditions are construed in accordance with the
              laws of the Philippines and you irrevocably submit to the
              exclusive jurisdiction of the courts in that country which governs
              this Agreement’s interpretation.
            </p>
          </section>
        </div>
        <div className={cx(styles.flex_center_all, styles.last_updated)}>
          <p>
            Last updated: <span>July 23, 2023</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsService;
