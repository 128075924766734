import cx from 'classnames';
import styles from './GeneratedUserQrItem.module.scss';
import moment from 'moment/moment';
import CopyButton from 'components/copy-button';
import { useMemo } from 'react';
import { Button } from '@chakra-ui/react';
import { withModalManagerSettings } from 'managers/modal';
import { isFunction } from 'lodash';
import CustomerCardQR from 'components/customer-card-qr';
import { ExternalLinkIcon } from '@radix-ui/react-icons';

const GeneratedUserQrItem = (props) => {
  const { qrData, setActiveModalDom } = props;

  const dateCreatedFormatted = useMemo(() => {
    const { createdMins } = qrData;
    const createdMs = createdMins * 60 * 1000;
    const createdDateObj = new Date(createdMs);

    return moment.utc(createdDateObj).fromNow();
  }, [qrData]);

  const showQRCodeImage = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<CustomerCardQR qrData={qrData} />);
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.generated_user_qr_item)}>
      <div className={cx(styles.flex_center_all, styles.left)}>
        <div className={cx(styles.flex_center_all, styles.user_id)}>
          <p>{qrData?.id}</p>
          <CopyButton />
        </div>
        <div className={styles.created_date}>
          <p>{dateCreatedFormatted}</p>
        </div>
      </div>
      <div className={cx(styles.flex_center_all, styles.right)}>
        <Button
          variant={'ghost'}
          className={cx(styles.flex_center_all, styles.show_qr_code_button)}
          color="#fff"
          onClick={showQRCodeImage}
        >
          <ExternalLinkIcon />
        </Button>
      </div>
    </div>
  );
};

export default withModalManagerSettings(GeneratedUserQrItem);
