import cx from 'classnames';
import styles from './Analytics.module.scss';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';
import { isFunction, isNumber } from 'lodash';
import { Button } from '@chakra-ui/react';
import { lazy } from 'react';
import { LockClosedIcon } from '@radix-ui/react-icons';
import { unlockPremiumFeature } from 'utils/helpers';

const MonthlyScans = lazy(() => import('./analytics/MonthlyScans'));

const Analytics = (props) => {
  const { storeSelected, userIsPremium } = props;

  return (
    <div className={styles.analytics}>
      {userIsPremium && (
        <div className={cx(styles.flex_center_all, styles.views)}>
          <p>
            {`Total Views `}
            <span>
              {isNumber(storeSelected?.views) ? storeSelected?.views : 0}
            </span>
          </p>
        </div>
      )}

      {!userIsPremium && (
        <div className={cx(styles.flex_center_all, styles.cta_premium)}>
          <Button
            height={'unset'}
            boxSizing={'border-box'}
            padding={'16px 20px'}
            background={'#7d5dc1'}
            borderRadius={'50px'}
            display={'flex'}
            justifyContent={'center'}
            backgroundColor={'#7d5dc1'}
            onClick={() => {
              if (isFunction(unlockPremiumFeature)) {
                unlockPremiumFeature();
              }
            }}
          >
            <LockClosedIcon height={'20px'} width={'20px'} />
            <p>Unlock this feature with premium</p>
          </Button>
        </div>
      )}

      {userIsPremium && <MonthlyScans />}
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(Analytics));
