import NetworkOffline from 'components/network-offline';
import VerifyEmailPrompt from 'components/verify-email-prompt';
import { withNetworkSettings } from 'managers/network';
import { withUserProfileSettings } from 'managers/profile';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobileScreen } from 'utils/helpers';

const RouteElementContainer = (props) => {
  const {
    children,
    profileLoaded,
    isDemo,
    user,
    isLoggedIn,
    isUserNetworkOffline,
  } = props;

  const location = useLocation();

  const isOffline = useMemo(
    () => profileLoaded && isUserNetworkOffline && isMobileScreen(),
    [profileLoaded, isUserNetworkOffline]
  );

  const showVerifyEmailPrompt = useMemo(
    () =>
      !location?.pathname?.startsWith('/verify-email') &&
      !isDemo &&
      !user?.emailVerified &&
      profileLoaded &&
      isLoggedIn,
    [location, isLoggedIn, profileLoaded, user, isDemo]
  );

  return (
    <>
      {children}
      {isOffline ? <NetworkOffline /> : <></>}
      {showVerifyEmailPrompt && !isOffline && <VerifyEmailPrompt />}
    </>
  );
};

export default withNetworkSettings(
  withUserProfileSettings(RouteElementContainer)
);
