import { getUserInfoRequest, probeUser, sendLogoutRequest } from 'api';
import { planTypePremiumStore, darkTheme } from 'constants';
import {
  isEmpty,
  isObject,
  isString,
  size,
  split,
  toNumber,
  toString,
} from 'lodash';
import { Component, createContext, forwardRef, useMemo } from 'react';
import { getAuthenticatedHeaders, isMobileScreen } from 'utils/helpers';

export function withUserProfileSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

const { Consumer, Provider } = createContext();

const userDemo = {
  id: 'f80e7054-a90b-40b4-a7c1-9ee04a33e78c',
  createdYear: 2023,
  fullName: 'Robert Espina',
  email: 'robert.espina9627@gmail.com',
  handle: 'robertespina',
  image:
    'https://images.unsplash.com/photo-1445499348736-29b6cdfc03b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
  storeOwner: true,
  auth: { token: '' },
  plan: {
    type: '',
  },
};

function useDemo() {
  return {
    user: userDemo,
    isLoggedIn: true,
    isDemo: true,
    profileLoaded: true,
  };
}

const melonKarmaProfileKey = 'melonKarmaProfile';
const melonKarmaUserKey = 'melonKarmaUser';
const melonKarmaTTotalKey = 'melonKarmaTTotal';
const melonKarmaCustomerKey = 'melonKarmaCustomer';

class ProfileManager extends Component {
  state = {
    mounted: false,
    customer: null,
    profile: {
      country: '',
      region: '',
      rememberLogin: true,
      doneWithAfterSignupOnboarding: false,
      cookiesAccepted: false,
      lastSignupRecordedInSeconds: 0,
      theme: darkTheme,
    },
    user: null,
    userNotifications: [],
    userNotificationsPage: 1,
    userNotificationsFetching: false,
    userNotificationsHasNext: false,
    isLoggedIn: false,
    isDemo: false,
    profileLoaded: false,
    toForceAppUpdate: false,
    ...(false && useDemo()),
  };

  constructor(props) {
    super(props);
    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    const { isDemo } = this.state;

    this.checkUserProbe();
    this.setState(
      {
        mounted: true,
        ...(isDemo && { profileLoaded: true }),
      },
      this.check
    );

    document.addEventListener('visibilitychange', this.onAppUnload, false);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onAppUnload, false);
  }

  onAppUnload = () => {
    if (document?.hidden || !isMobileScreen()) {
      this.storeConfigInLocalStorage();
    }
  };

  check = () => {
    const { user, token, profile, customer } =
      this.getUserConfigInLocalStorage();
    const {
      profile: currentProfile,
      user: currentUser,
      customer: currentCustomer,
    } = this.state;
    const { isDemo } = this.state;
    const isLoggedIn =
      !isEmpty(token) &&
      isString(token) &&
      !isEmpty(user) &&
      !isEmpty(user?.id);
    this.removeLogoLoading();

    if (isDemo) {
      return;
    }

    this.setState(
      {
        isLoggedIn,

        customer: {
          ...currentCustomer,
          ...customer,
        },

        user: {
          ...currentUser,
          ...(!isEmpty(user) ? user : {}),
          auth: { token },
        },
        profile: {
          ...currentProfile,
          ...(isObject(profile) && !isEmpty(profile) ? { ...profile } : {}),
        },
        profileLoaded: true,
      },
      () => {
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
          // get user basic info and settings
          this.getCurrentUser();
        }
      }
    );
  };

  removeLogoLoading = () => {
    const dom = document.getElementById('loadingParent');

    if (dom) {
      dom.remove();
    }
  };

  getUserConfigInLocalStorage = () => {
    let user = null;
    let profile = {};
    let token = '';
    let customer = null;
    let totalTokenSplit = toNumber(localStorage.getItem(melonKarmaTTotalKey));

    try {
      if (!isNaN(totalTokenSplit) && totalTokenSplit) {
        for (let i = 0; i < totalTokenSplit; i++) {
          const tokenPartition =
            localStorage.getItem(`melonKarmaT${i + 1}Set`) || '';

          if (tokenPartition) {
            if (i <= 0) {
              token = tokenPartition;
            } else {
              token = `${token}.${tokenPartition}`;
            }
          }
        }
      }
    } catch {
      token = '';
    }

    try {
      user = JSON.parse(localStorage.getItem(melonKarmaUserKey));
    } catch {
      user = {};
    }

    try {
      profile = JSON.parse(localStorage.getItem(melonKarmaProfileKey));
    } catch {
      profile = {};
    }

    try {
      customer = JSON.parse(localStorage.getItem(melonKarmaCustomerKey));
    } catch {
      customer = null;
    }

    return { profile, customer, token, user };
  };

  logout = () => {
    const { user } = this.state;
    const headers = getAuthenticatedHeaders(user);

    sendLogoutRequest({ ...headers });
    this.setState(
      {
        user: null,
        isLoggedIn: false,
      },
      this.clearUserConfigInLocalStorage
    );
  };

  storeConfigInLocalStorage = () => {
    try {
      const { user, profile } = this.state;
      const token = toString(user?.auth?.token || '');
      const userStringify = JSON.stringify({ ...user, auth: '' });
      const profileStringify = JSON.stringify(profile);
      const splitToken = split(token, '.');

      for (let i = 0; i < size(splitToken); i++) {
        const str = splitToken[i];

        if (str) {
          localStorage.setItem(`melonKarmaT${i + 1}Set`, str);
        }
      }

      if (profile?.rememberLogin) {
        localStorage.setItem(melonKarmaTTotalKey, size(splitToken));
        localStorage.setItem(melonKarmaUserKey, userStringify);
      }

      localStorage.setItem(melonKarmaProfileKey, profileStringify);
    } catch {}
  };

  storeCustomerConfigLocalStorage = () => {
    try {
      const { customer } = this.state;

      if (!isEmpty(customer) && isObject(customer)) {
        const customerInfoStringify = JSON.stringify(customer);
        localStorage.setItem(melonKarmaCustomerKey, customerInfoStringify);
      } else {
        localStorage.removeItem(melonKarmaCustomerKey);
      }
    } catch {}
  };

  clearUserConfigInLocalStorage = () => {
    localStorage.removeItem(`melonKarmaT1Set`);
    localStorage.removeItem(`melonKarmaT2Set`);
    localStorage.removeItem(`melonKarmaT3Set`);
    localStorage.removeItem(`melonKarmaT4Set`);
    localStorage.removeItem(`melonKarmaT5Set`);
    localStorage.removeItem(melonKarmaTTotalKey);
    localStorage.removeItem(melonKarmaUserKey);
    localStorage.removeItem(melonKarmaCustomerKey);

    this.setState({
      user: null,
      isLoggedIn: false,
      isDemo: false,
      userNotifications: [],
      userNotificationsPage: 1,
      userNotificationsFetching: false,
      userNotificationsHasNext: false,
    });
  };

  checkUserProbe = async () => {
    const { country, region } = await probeUser();
    const { profile } = this.state;

    if (country || (!isEmpty(country) && profile?.country !== country)) {
      this.setState({
        profile: { ...profile, country, region },
      });
    }
  };

  consumeUserForLogin = (token = '', user = {}) => {
    const { user: currentUser } = this.state;

    if (token && user) {
      this.setState(
        {
          user: {
            ...currentUser,
            ...user,
            auth: { ...currentUser?.auth, token },
          },
          isLoggedIn: true,
        },
        () => {
          const { profile, isLoggedIn } = this.state;

          if (profile?.rememberLogin) {
            this.storeConfigInLocalStorage();
          }

          if (isLoggedIn) {
            this.getCurrentUser();
            this.getUserNotifications();
          }
        }
      );
    }
  };

  getCurrentUser = async () => {
    const { user: currentUser } = this.state;
    const headers = getAuthenticatedHeaders(currentUser);
    const userId = currentUser?.id;
    const {
      user: updatedUser,
      token,
      errorMessage,
      networkError,
    } = await getUserInfoRequest(userId, headers);

    if (errorMessage || networkError) {
      return;
    }

    if (isObject(updatedUser) && !isEmpty(updatedUser)) {
      if (!isEmpty(token)) {
        // token refresh
        this.setState(
          {
            user: { ...updatedUser, auth: { ...currentUser?.auth, token } },
          },
          this.storeConfigInLocalStorage
        );
      } else {
        this.setState({
          user: { ...updatedUser, auth: currentUser?.auth },
        });
      }
    }
  };

  updateUserProps = (params = {}) => {
    const { user } = this.state;

    if (!isEmpty(params) && isObject(params)) {
      this.setState({ user: { ...user, ...params } }, () =>
        this.storeConfigInLocalStorage()
      );
    }
  };

  consumeUserProfileFromScannedAction = (userProfile = {}) => {
    if (!isEmpty(userProfile)) {
      this.setState(
        { customer: { ...userProfile } },
        this.storeCustomerConfigLocalStorage
      );
    } else {
      this.setState({ customer: null }, this.storeCustomerConfigLocalStorage);
    }
  };

  getUserNotifications = () => {
    const {
      userNotificationsPage,
      user,
      userNotifications,
      userNotificationsFetching,
    } = this.state;
    // todo
  };

  render() {
    const { children, router } = this.props;
    const {
      profileLoaded,
      isLoggedIn,
      user,
      profile,
      isDemo,
      toForceAppUpdate,
      customer,
    } = this.state;
    const userIsPremium = user?.plan?.type === planTypePremiumStore;
    const props = {
      profileLoaded,
      isLoggedIn,
      user,
      userIsPremium,
      profile,
      isDemo,
      toForceAppUpdate,
      customer,
      // from react router dom
      browserRouter: router,
      isThemeDarkMode: profile?.theme === darkTheme,
      consumeUserProfileFromScannedAction:
        this.consumeUserProfileFromScannedAction,
      logout: this.logout,
      updateUserProps: this.updateUserProps,
      consumeUserForLogin: this.consumeUserForLogin,
      getUserNotifications: this.getUserNotifications,
    };

    return (
      <ProfileManagerContextMemoized {...props}>
        {children}
      </ProfileManagerContextMemoized>
    );
  }
}

const ProfileManagerContextMemoized = ({ children, ...props }) => {
  const {
    profileLoaded,
    isLoggedIn,
    user,
    userIsPremium,
    profile,
    isDemo,
    toForceAppUpdate,
    browserRouter,
    customer,
  } = props;

  const propsValue = useMemo(
    () => ({ ...props }),
    // eslint-disable-next-line
    [
      customer,
      browserRouter,
      profileLoaded,
      isLoggedIn,
      user,
      userIsPremium,
      user?.achievements,
      user?.plan,
      profile,
      isDemo,
      toForceAppUpdate,
    ]
  );

  return <Provider value={propsValue}>{children || null}</Provider>;
};

export default ProfileManager;
