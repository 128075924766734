import cx from 'classnames';
import styles from './CustomerItem.module.scss';
import moment from 'moment/moment';
import { Avatar } from '@chakra-ui/react';
import { useMemo } from 'react';
import { head, isEmpty, isFunction, size, toNumber } from 'lodash';
import { withStoreManagerSettings } from 'managers/stores';
import { useNavigate } from 'react-router-dom';
import CopyButton from 'components/copy-button';

const PointsInfo = (props) => {
  const { customer } = props;

  const points = useMemo(
    () => toNumber(customer?.totalPoints || customer?.points || 0),
    [customer]
  );

  const pointsFormatted = useMemo(() => {
    if (points > 99_999 && points < 999_999) {
      return `${head(`${points}`)}00K+`;
    } else if (points > 999_999) {
      return `${head(`${points}`)}M+`;
    }

    return points;
  }, [points]);

  return (
    <div className={cx(styles.flex_center_all, styles.points)}>
      <p>{`${pointsFormatted} ${points < 2 ? 'point' : 'points'}`}</p>
    </div>
  );
};

const LastScanned = (props) => {
  const { customer } = props;

  const mostRecentActivity = useMemo(
    () => head(customer?.recentActivities),
    [customer]
  );

  const dateFormatted = useMemo(() => {
    const createdMins = mostRecentActivity?.createdMins;
    const dateObj = new Date(createdMins * 60 * 1_000);
    return moment(dateObj).fromNow();
  }, [mostRecentActivity]);

  return (
    <div className={cx(styles.flex_center_all, styles.last_scanned)}>
      {!isEmpty(mostRecentActivity) && (
        <p>
          {'Last Scanned: '}
          <span>{dateFormatted} </span>
        </p>
      )}
    </div>
  );
};

const CustomerState = (props) => {
  const { customer } = props;

  return (
    <div
      className={cx(styles.flex_center_all, styles.customer_state, {
        [styles.customer_state_active]: customer?.approved,
        [styles.customer_state_inactive]: !customer?.approved,
      })}
    >
      <p>{customer?.approved ? 'Active' : 'Inactive'}</p>
    </div>
  );
};

export const CustomerItem = (props) => {
  const { customer, setStoreCustomer, storeSelected } = props;

  const hasFullName = useMemo(() => !isEmpty(customer?.fullName), [customer]);

  const customerEmail = useMemo(() => customer?.email || '', [customer]);

  const customerName = useMemo(() => customer?.fullName, [customer]);

  const navigate = useNavigate();

  const viewCustomer = async () => {
    const customerUserId = customer?.id;

    if (isFunction(setStoreCustomer)) {
      await setStoreCustomer({ ...customer });
      navigate(
        `/store/customer/${customerUserId}?${
          storeSelected?.id ? `storeId=${storeSelected.id}` : ''
        }`
      );
    }
  };

  const isRepeatCustomer = useMemo(
    () =>
      !isEmpty(customer) &&
      (size(customer?.recentActivities) > 1 || customer?.repeatCustomer),
    [customer]
  );
  const isCardHolder = useMemo(() => customer?.cardHolder, [customer]);

  return (
    <div className={cx(styles.flex_center_all, styles.customer_item)}>
      <div className={cx(styles.first, styles.flex_center_all)}>
        <div className={cx(styles.flex_center_all, styles.left)}>
          <div
            className={cx(styles.flex_center_all, styles.avatar)}
            onClick={viewCustomer}
          >
            <Avatar
              name={head(customer?.fullName || customer?.email || 'n')}
              src={customer?.image}
              height={'40px'}
              width={'40px'}
              display={'flex'}
              background={'#000'}
              backgroundColor={'#000'}
            />
          </div>

          <div className={cx(styles.flex_center_all, styles.names)}>
            {hasFullName && (
              <div className={styles.customer_name} onClick={viewCustomer}>
                <p> {customerName}</p>
              </div>
            )}

            <div className={cx(styles.flex_center_all, styles.customer_email)}>
              <p onClick={viewCustomer}>{customerEmail}</p>
              <CopyButton text={customerEmail} className={styles.copy_button} />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.flex_center_all, styles.right)}
          onClick={viewCustomer}
        >
          <PointsInfo customer={customer} />
          <LastScanned customer={customer} />
          <CustomerState customer={customer} />
        </div>
      </div>

      {(isRepeatCustomer || isCardHolder) && (
        <div className={cx(styles.second, styles.flex_center_all)}>
          {isRepeatCustomer && (
            <div className={cx(styles.flex_center_all, styles.repeat_customer)}>
              <p>Repeat customer</p>
            </div>
          )}
          {isCardHolder && (
            <div className={cx(styles.flex_center_all, styles.card_holder)}>
              <p>Card Holder </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withStoreManagerSettings(CustomerItem);
