import React, { Component, createContext, forwardRef } from 'react';
import Toast from './Toast';
import { isMobileScreen } from 'utils/helpers';
import { isFunction } from 'lodash';
import { withUserProfileSettings } from './profile';
import { withModalManagerSettings } from './modal';

const { Consumer, Provider } = createContext();

export function withMobileToastSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class MobileToastManager extends Component {
  state = {
    dom: null,
    active: false,
    blockClosingMobileToast: false,
  };

  setActiveDomForMobileToast = (dom) => {
    if (dom) {
      const { closeModal } = this.props;

      if (!isMobileScreen()) {
        return;
      }

      this.setState(
        {
          dom,
          active: true,
        },
        () => {
          if (isFunction(closeModal)) {
            closeModal();
          }
        }
      );
    }
  };

  closeMobileToast = (forceClose = false) => {
    const { blockClosingMobileToast } = this.state;

    if (blockClosingMobileToast && !forceClose) {
      return;
    }

    this.setState({
      dom: null,
      blockClosingMobileToast: false,
      active: false,
    });
  };

  blockMobileToastClosing = () => {
    this.setState({
      blockClosingMobileToast: true,
    });
  };

  unblockMobileToastClosing = (close = false) => {
    this.setState(
      {
        blockClosingMobileToast: false,
      },
      () => {
        if (close) {
          this.closeMobileToast();
        }
      }
    );
  };

  render() {
    const { children } = this.props;
    const { dom, active, blockClosingMobileToast } = this.state;
    const isMobileToastActive =
      (blockClosingMobileToast || active) && isMobileScreen();

    return (
      <Provider
        value={{
          isMobileToastActive,
          mobileToastDom: dom,
          blockMobileToastClosing: this.blockMobileToastClosing,
          closeMobileToast: this.closeMobileToast,
          unblockMobileToastClosing: this.unblockMobileToastClosing,
          setActiveDomForMobileToast: this.setActiveDomForMobileToast,
        }}
      >
        {children}
        {isMobileToastActive && (
          <Toast
            close={this.closeMobileToast}
            blockClosingMobileToast={blockClosingMobileToast}
            dom={dom}
          />
        )}
      </Provider>
    );
  }
}

export default withUserProfileSettings(
  withModalManagerSettings(MobileToastManager)
);
