import cx from 'classnames';
import styles from './Header.module.scss';
import { Avatar, Button, Tooltip } from '@chakra-ui/react';
import { withUserProfileSettings } from 'managers/profile';
import { ChevronDownIcon, ChevronLeftIcon } from '@radix-ui/react-icons';
import { getUserDisplayNameFromObject, isMobileScreen } from 'utils/helpers';
import { head } from 'lodash';
import LogoIcon from 'components/logo-icon';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderUserOptions, {
  HeaderUserOptionsClassname,
} from './HeaderUserOptions';
import { useMemo, useState } from 'react';
import Stores from 'components/stores';

const Header = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [showHeaderOptions, setShowHeaderOptions] = useState(false);

  const onLogoClick = () => {
    if (
      location?.pathname?.startsWith('/account') ||
      location?.pathname?.startsWith('/billing')
    ) {
      navigate('/dashboard');
      return;
    }

    if (!isMobileScreen()) {
      navigate('/');
      return;
    }
  };

  const hideStores = useMemo(() => {
    const pathname = location?.pathname;

    if (
      pathname?.startsWith('/account') ||
      pathname?.startsWith('/store/customer') ||
      pathname?.startsWith('/billing')
    ) {
      return true;
    }

    return false;
  }, [location]);

  const hideLogo = useMemo(() => {
    const pathname = location?.pathname;

    if (pathname?.startsWith('/store/customer')) {
      return true;
    }

    return false;
  }, [location]);

  return (
    <div className={cx(styles.flex_center_all, styles.header_dashboard)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.left)}>
          {location?.pathname?.startsWith('/store/customer') && (
            <div className={cx(styles.flex_center_all, styles.return)}>
              <Tooltip
                label="Back to dashboard"
                className={styles.custom_tooltip}
              >
                <Button
                  className={cx(styles.flex_center_all, styles.return_button)}
                  variant={'ghost'}
                  height={'40px'}
                  padding={'8px 12px'}
                  boxSizing={'border-box'}
                  background={'#292A2B'}
                  backgroundColor={'#292A2B'}
                  borderRadius={'6px'}
                  color={'#fff'}
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon height={'20px'} width={'20px'} />
                </Button>
              </Tooltip>
            </div>
          )}

          {!hideLogo && <LogoIcon onClick={onLogoClick} />}
          {!hideStores && <Stores forStoreOwner />}
        </div>
        <div className={cx(styles.flex_center_all, styles.right)}>
          <div className={cx(styles.flex_center_all, styles.user)}>
            <div>
              <button
                type="button"
                className={cx(styles.flex_center_all)}
                onClick={() => {
                  setShowHeaderOptions(!showHeaderOptions);
                }}
              >
                <Avatar
                  height={'40px'}
                  width={'40px'}
                  background={'#000'}
                  color={'#fff'}
                  name={head(getUserDisplayNameFromObject(user))}
                />
                <div
                  className={cx(styles.flex_center_all, styles.arrow, {
                    [styles.arrow_expand]: showHeaderOptions,
                  })}
                >
                  <ChevronDownIcon height={'20px'} width={'20px'} />
                </div>
                <div
                  className={cx(styles.cover_dom, HeaderUserOptionsClassname)}
                ></div>
              </button>
              {showHeaderOptions && (
                <HeaderUserOptions close={() => setShowHeaderOptions(false)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(Header);
