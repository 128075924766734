import { Component, createContext, forwardRef, useMemo } from 'react';
import { withUserProfileSettings } from './profile';
import {
  filter,
  head,
  includes,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  isObject,
  isString,
  map,
  omit,
  toLower,
  trim,
} from 'lodash';
import {
  getOwnedStoresByPageRequest,
  getStoreInfoByHandleOrIdRequest,
  getStoreNotificationsByPageRequest,
  getStoreRecordedCustomersByPageRequest,
  getStoreRewardsByPageRequest,
} from 'api/stores';
import { getAuthenticatedHeaders, isDev } from 'utils/helpers';
import PQueue from 'p-queue';

const { Consumer, Provider } = createContext();

export function withStoreManagerSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

export const storeFetchProcessingQueue = new PQueue({ concurrency: 1 });

class StoresManager extends Component {
  mostRecentNotificaitonsIntervalId = null;
  mostRecentStoreCustomersIntervalId = null;
  state = {
    mounted: false,
    storeCustomer: null,
    // owner stores
    storeSelected: null,
    stores: [
      // {
      //   id: '55bbf5b4-35fa-4aff-93c8-4ae15622a115',
      //   handle: '@magician',
      //   businessName: 'Magician',
      //   image: '',
      //   coverPhoto:
      //     'https://images.unsplash.com/photo-1557076329-cda8f51f2b95?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1776&q=80',
      // },
      // {
      //   id: '55bbf5b4-35fa-4aff-93c8-4ae15622c223',
      //   handle: '@figma',
      //   businessName: 'Figma',
      //   image: '',
      //   coverPhoto: '',
      // },
    ],
    storesPage: 1,
    storesListHasNext: false,
    storesListFetching: false,
    // visited stores
    visitedStoreSelected: null,
    visitedStores: [],
    visitedStoresPage: 1,
    visitedStoresHasNext: false,
    visitedStoresFetching: false,

    storeNotifications: [],
    storeNotificationsPage: 1,
    storeNotificationsFetching: false,
    storeNotificationsHasNext: false,
    storeNotificationsTotalCount: 0,

    storeCustomers: [],
    storeCustomersPage: 1,
    storeCustomersFetching: false,
    storeCustomersHasNext: false,
    storeCustomersTotalCount: 0,

    storeRewards: [],
    storeRewardsPage: 1,
    storeRewardsFetching: false,
    storeRewardsHasNext: false,
  };

  constructor(props) {
    super(props);
    this.setStateAsync = (obj) =>
      new Promise((resolve) => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true,
      },
      this.checkStores
    );
  }

  componentDidUpdate(prevProps) {
    const { mounted } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;

    if (!isLoggedIn && profileLoaded && prevProps?.isLoggedIn) {
      // user log out
      this.reset();
    }

    if (isLoggedIn && mounted && !prevProps?.isLoggedIn) {
      // user log in
      this.checkStores();
      return;
    }

    if (mounted && profileLoaded && isLoggedIn && !prevProps?.profileLoaded) {
      this.checkStores();
    }
  }

  componentWillUnmount() {
    if (!isNil(this.mostRecentNotificaitonsIntervalId)) {
      clearInterval(this.mostRecentNotificaitonsIntervalId);
    }

    if (!isNil(this.mostRecentStoreCustomersIntervalId)) {
      clearInterval(this.mostRecentStoreCustomersIntervalId);
    }
  }

  reset = () => {
    this.setState({
      storeCustomer: null,
      storeSelected: null,
      stores: [],
      storesPage: 1,
      storesListHasNext: false,
      storesListFetching: false,

      visitedStoreSelected: null,
      visitedStores: [],
      visitedStoresPage: 1,
      visitedStoresHasNext: false,
      visitedStoresFetching: false,

      storeCustomers: [],
      storeCustomersPage: 1,
      storeCustomersFetching: false,
      storeCustomersHasNext: false,
      storeCustomersTotalCount: 0,

      storeRewards: [],
      storeRewardsPage: 1,
      storeRewardsFetching: false,
      storeRewardsHasNext: false,
    });
  };

  checkStores = () => {
    const { mounted } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;

    if (!mounted || !profileLoaded || !isLoggedIn) {
      return;
    }

    console.log('checkStores');
    storeFetchProcessingQueue.add(async () => {
      await this.getOwnedStoresByPage();
    });
  };

  getOwnedStoresByPage = async () => {
    const { storesPage, storesListFetching } = this.state;
    const { user, logout } = this.props;

    if (storesListFetching) {
      return;
    }

    try {
      await this.setStateAsync({ storesListFetching: true });

      const headers = getAuthenticatedHeaders(user);
      const {
        stores: newStoresList,
        hasNext,
        errorMessage,
        networkError,
      } = await getOwnedStoresByPageRequest(storesPage, headers);
      const { storeSelected } = this.state;

      if (errorMessage || networkError) {
        if (toLower(trim(errorMessage))?.includes('unauthorized')) {
          if (isFunction(logout)) {
            logout();
          }
        }

        return;
      }

      if (isArray(newStoresList) && !isEmpty(newStoresList)) {
        this.consumeStoresInfo(newStoresList);

        if (isEmpty(storeSelected) || isEmpty(storeSelected?.id)) {
          await this.setStateAsync({ storeSelected: head(newStoresList) });
          this.getStoreRecordedCustomersByPage();
          this.getStoreNotificationsByPage();
          this.getMostRecentStoreNotifications();
          this.getMostRecentStoreCustomers();
          this.getStoreRewardsByPage();
        }
      }

      await this.setStateAsync({ storesListHasNext: !!hasNext });
    } catch {
    } finally {
      await this.setStateAsync({ storesListFetching: false });
    }
  };

  selectStore = (storeId = '') => {
    const { stores, storeSelected } = this.state;
    const find = head(
      filter(stores, (storeInfo) => storeId && storeInfo?.id === storeId)
    );

    if (
      isString(storeId) &&
      !isEmpty(storeId) &&
      !isEmpty(find) &&
      (!storeSelected || storeSelected?.id !== find?.id)
    ) {
      this.setState({ storeSelected: { ...find } }, () => {
        this.resetSelectedStoreVariablesAndFetch();
        this.getSelectedStoreInfo();
      });
    }
  };

  getSelectedStoreInfo = async () => {
    const { storeSelected } = this.state;
    const { user } = this.props;
    const storeId = trim(storeSelected?.id || '');

    if (!storeId) {
      return;
    }

    const headers = getAuthenticatedHeaders(user);
    const { store, errorMessage, networkError } =
      await getStoreInfoByHandleOrIdRequest(storeId, headers);
    const { stores } = this.state;

    if (errorMessage || networkError || store?.id !== storeId) {
      return;
    }

    const updatedStores = map(stores, (storeInfo) => {
      if (storeInfo?.id === storeId) {
        return { ...storeInfo, ...store };
      }

      return storeInfo;
    });

    if (!isEmpty(store)) {
      await this.setStateAsync({
        stores: updatedStores,
        storeSelected: { ...storeSelected, ...store },
      });
    }
  };

  resetSelectedStoreVariablesAndFetch = async () => {
    await this.setStateAsync({
      storeCustomers: [],
      storeCustomersPage: 1,
      storeCustomersFetching: false,
      storeCustomersHasNext: false,
      storeCustomersTotalCount: 0,

      storeNotifications: [],
      storeNotificationsPage: 1,
      storeNotificationsFetching: false,
      storeNotificationsHasNext: false,
      storeNotificationsTotalCount: 0,

      storeRewards: [],
      storeRewardsPage: 1,
      storeRewardsFetching: false,
      storeRewardsHasNext: false,
    });

    storeFetchProcessingQueue.add(async () => {
      await this.getStoreRecordedCustomersByPage();
      await this.getStoreNotificationsByPage();
      await this.getStoreRewardsByPage();
    });
  };

  consumeStoresInfo = (ownedStores = []) => {
    const { stores, storeSelected } = this.state;
    const storedIds = filter(map(stores, (storeInfo) => storeInfo?.id));
    const ownedStoreIds = filter(
      map(ownedStores, (storeInfo) => storeInfo?.id)
    );
    const sanitizedStores = filter(
      ownedStores,
      (storeInfo) =>
        !isEmpty(storeInfo?.id) && !includes(storedIds, storeInfo?.id)
    );
    const updatedStores = [...stores, ...sanitizedStores];

    if (!isEmpty(storeSelected) && includes(ownedStoreIds, storeSelected?.id)) {
      this.setState({
        storeSelected: {
          ...storeSelected,
          ...(head(
            filter(
              sanitizedStores,
              (storeInfo) => storeInfo?.id === storeSelected?.id
            )
          ) || {}),
        },
        stores: updatedStores,
      });
    } else {
      this.setState({
        stores: updatedStores,
      });
    }
  };

  consumeVisitedStore = (storeInfo = {}) => {
    if (!isEmpty(storeInfo?.id)) {
      const { visitedStores } = this.state;
      const storedIds = map(visitedStores, (store) => store?.id || '');
      let updatedStores = visitedStores;

      if (includes(storedIds, storeInfo?.id)) {
        updatedStores = map(updatedStores, (store) => {
          if (store?.id === storeInfo?.id) {
            return {
              ...store,
              ...storeInfo,
            };
          }

          return store;
        });
      } else {
        updatedStores = [storeInfo, ...visitedStores];
      }

      this.setState({
        visitedStoreSelected: storeInfo,
        visitedStores: updatedStores,
      });
    }
  };

  createNewStore = (storeInfo = {}) => {
    const { stores } = this.state;

    if (!isEmpty(storeInfo?.id)) {
      this.setState(
        {
          stores: [storeInfo, ...stores],
          storeSelected: storeInfo,
        },
        this.resetSelectedStoreVariablesAndFetch
      );
    }
  };

  removeStore = (storeId = '') => {
    const { storeSelected, stores } = this.state;
    const updatedStoreSelected =
      storeSelected?.id === storeId ? null : storeSelected;
    const updatedStores = filter(
      stores,
      (storeInfo) => trim(storeInfo?.id) !== trim(storeId)
    );

    this.setState({
      storeSelected: updatedStoreSelected,
      stores: updatedStores,
    });
  };

  updateStoreProps = (storeId = '', params = {}) => {
    if (!storeId || isEmpty(params)) {
      return;
    }

    params = omit(params, [
      'id',
      'createdMins',
      'createdSeconds',
      'created',
      'refId',
    ]);

    const { storeSelected, stores } = this.state;

    const updatedStores = map(stores, (storeInfo) => {
      if (storeInfo?.id === storeId) {
        return { ...storeInfo, ...params };
      }

      return storeInfo;
    });
    const updatedStoreSelected =
      storeId === storeSelected?.id
        ? { ...storeSelected, ...params }
        : storeSelected;

    this.setState({
      storeSelected: updatedStoreSelected,
      stores: updatedStores,
    });
  };

  updateVisitedStoreProperty = (storeId = '', params = {}) => {
    if (!storeId) {
      return;
    }

    const { visitedStoreSelected, visitedStores } = this.state;
    const updatedVisitedStores = map(visitedStores, (store) => {
      if (store?.id === storeId) {
        return { ...store, ...omit(params, ['id', 'refId']) };
      }

      return store;
    });
    const updatedVisitedStoreSelected =
      visitedStoreSelected?.id === storeId
        ? {
            ...visitedStoreSelected,
            ...omit(params, ['id', 'refId']),
          }
        : visitedStoreSelected;

    this.setState({
      visitedStores: updatedVisitedStores,
      visitedStoreSelected: updatedVisitedStoreSelected,
    });
  };

  clearSelectedVisistedStore = () => {
    this.setState({
      visitedStoreSelected: null,
    });
  };

  getStoreRecordedCustomersNextPage = () => {
    const {
      storeCustomersHasNext,
      storeCustomersPage,
      storeCustomersFetching,
      storeSelected,
    } = this.state;
    const storeId = trim(storeSelected?.id);

    if (!storeId || storeCustomersFetching || !storeCustomersHasNext) {
      return;
    }

    this.setState(
      {
        storeCustomersPage: storeCustomersPage + 1,
      },
      () => {
        storeFetchProcessingQueue.add(async () => {
          await this.getStoreRecordedCustomersByPage();
        });
      }
    );
  };

  getStoreRecordedCustomersPrevPage = () => {
    const { storeCustomersPage, storeCustomersFetching, storeSelected } =
      this.state;
    const storeId = trim(storeSelected?.id);

    if (!storeId || storeCustomersPage <= 1 || storeCustomersFetching) {
      return;
    }

    this.setState(
      {
        storeCustomersPage: storeCustomersPage - 1,
      },
      () => {
        storeFetchProcessingQueue.add(async () => {
          await this.getStoreRecordedCustomersByPage();
        });
      }
    );
  };

  getStoreRecordedCustomersByPage = async () => {
    const {
      stores,
      storeCustomersFetching,
      storeSelected,
      storeCustomersPage,
    } = this.state;
    const { user } = this.props;
    const storeId = trim(storeSelected?.id);

    if (storeCustomersFetching) {
      return;
    }

    if (!storeId) {
      if (storeCustomersFetching) {
        await this.setStateAsync({ storeCustomersFetching: false });
      }

      return;
    }

    try {
      await this.setStateAsync({ storeCustomersFetching: true });
      const headers = getAuthenticatedHeaders(user);
      const {
        errorMessage,
        networkError,
        storeInfo,
        users,
        totalCount,
        hasNext,
      } = await getStoreRecordedCustomersByPageRequest(
        storeCustomersPage,
        storeId,
        headers
      );

      if (!errorMessage && !networkError) {
        const updatedStores = map(stores, (store) => {
          if (store?.id === storeId) {
            return { ...store, ...storeInfo };
          }

          return store;
        });

        await this.setStateAsync({
          storeCustomers: users,
          storeCustomersTotalCount: totalCount,
          storeCustomersHasNext: hasNext,
          storeSelected: { ...storeSelected, ...storeInfo },
          stores: updatedStores,
        });
      }
    } catch {
    } finally {
      await this.setStateAsync({ storeCustomersFetching: false });
    }
  };

  getStoreNotificationsNextPage = () => {
    const {
      storeNotificationsFetching,
      storeSelected,
      storeNotificationsHasNext,
      storeNotificationsPage,
    } = this.state;
    const storeId = trim(storeSelected?.id);

    if (storeNotificationsFetching || !storeId || !storeNotificationsHasNext) {
      return;
    }

    this.setState(
      {
        storeNotificationsPage: storeNotificationsPage + 1,
      },
      this.getStoreNotificationsByPage
    );
  };

  getStoreNotificationsPrevPage = () => {
    const {
      storeNotificationsFetching,
      storeSelected,
      storeNotificationsPage,
    } = this.state;
    const storeId = trim(storeSelected?.id);

    if (storeNotificationsFetching || !storeId || storeNotificationsPage <= 1) {
      return;
    }

    this.setState(
      {
        storeNotificationsPage: storeNotificationsPage - 1,
      },
      this.getStoreNotificationsByPage
    );
  };

  getStoreNotificationsByPage = async () => {
    const {
      storeNotificationsFetching,
      storeNotificationsPage,
      storeSelected,
    } = this.state;
    const { user } = this.props;
    const storeId = trim(storeSelected?.id);

    if (storeNotificationsFetching || !storeId) {
      return;
    }

    try {
      await this.setStateAsync({ storeNotificationsFetching: true });
      const headers = getAuthenticatedHeaders(user);

      const { notifications, hasNext, totalCount, errorMessage, networkError } =
        await getStoreNotificationsByPageRequest(
          storeId,
          storeNotificationsPage,
          headers
        );

      if (!errorMessage && !networkError && isArray(notifications)) {
        await this.setStateAsync({
          storeNotificationsTotalCount: totalCount,
          storeNotificationsHasNext: hasNext,
          storeNotifications: notifications,
        });
      }
    } catch (err) {
    } finally {
      await this.setStateAsync({ storeNotificationsFetching: false });
    }

    // getStoreNotificationsByPageRequest
  };

  getMostRecentStoreNotifications = () => {
    // todo fetch via interval only the first page

    clearInterval(this.mostRecentNotificaitonsIntervalId);

    this.mostRecentNotificaitonsIntervalId = setInterval(
      () => {
        const {
          storeNotificationsPage,
          storeSelected,
          storeNotificationsFetching,
        } = this.state;
        const { isLoggedIn, profileLoaded } = this.props;

        if (
          !profileLoaded ||
          !isLoggedIn ||
          storeNotificationsFetching ||
          isEmpty(storeSelected?.id) ||
          storeNotificationsPage !== 1 ||
          document?.hidden
        ) {
          return;
        }

        this.getStoreNotificationsByPage();
      },
      isDev() ? 10_500 : 6_500
    );
  };

  getMostRecentStoreCustomers = () => {
    clearInterval(this.mostRecentStoreCustomersIntervalId);

    this.mostRecentStoreCustomersIntervalId = setInterval(
      () => {
        const { storeCustomersFetching, storeCustomersPage, storeSelected } =
          this.state;
        const { isLoggedIn } = this.props;

        if (
          !isLoggedIn ||
          isEmpty(storeSelected?.id || '') ||
          storeCustomersFetching ||
          storeCustomersPage !== 1 ||
          document?.hidden
        ) {
          return;
        }

        storeFetchProcessingQueue.add(async () => {
          await this.getStoreRecordedCustomersByPage();
        });
      },
      isDev() ? 10_500 : 5_500
    );
  };

  getStoreRewardsByPage = async () => {
    const { storeRewardsPage, storeSelected, storeRewardsFetching } =
      this.state;
    const { user } = this.props;

    try {
      if (storeRewardsFetching) {
        return;
      }

      await this.setStateAsync({
        storeRewardsFetching: true,
      });
      const storeId = trim(storeSelected?.id);
      const headers = getAuthenticatedHeaders(user);
      const { rewards, hasNext, errorMessage, networkError } =
        await getStoreRewardsByPageRequest(storeId, storeRewardsPage, headers);

      if (!errorMessage && !networkError && isArray(rewards)) {
        this.setState({
          storeRewards: filter(rewards),
          storeRewardsHasNext: hasNext,
        });
      }
    } catch (err) {
    } finally {
      await this.setStateAsync({
        storeRewardsFetching: false,
      });
    }
  };

  createNewStoreReward = (rewardProps = {}) => {
    try {
      const { storeRewards } = this.state;

      if (!isEmpty(rewardProps?.id)) {
        this.setState({
          storeRewards: [
            rewardProps,
            ...filter(storeRewards, (reward) => reward?.id !== rewardProps?.id),
          ],
        });
      }
    } catch (err) {}
  };

  removeStoreRewardById = (rewardId = '') => {
    try {
      if (!rewardId || !isString(rewardId) || isEmpty(rewardId)) {
        return;
      }

      const { storeRewards } = this.state;
      this.setState({
        storeRewards: filter(storeRewards, (reward) => reward?.id !== rewardId),
      });
    } catch (err) {}
  };

  updateStoreRewardById = (rewardId = '', params = {}) => {
    try {
      if (!rewardId || isEmpty(params)) {
        return;
      }

      const { storeRewards } = this.state;
      const updatedStoreRewards = map(storeRewards, (storeReward) => {
        if (storeReward?.id === rewardId) {
          return { ...storeReward, ...params };
        }

        return storeReward;
      });

      this.setState({
        storeRewards: updatedStoreRewards,
      });
    } catch (err) {}
  };

  updateStoreCustomerProperty = (userId = '', params = {}) => {
    if (!isEmpty(params) && !isEmpty(userId)) {
      const { storeCustomers, storeCustomer } = this.state;

      const updatedStoreCustomers = map(storeCustomers, (customerInfo) => {
        if (customerInfo?.id === userId) {
          return {
            ...customerInfo,
            ...omit(params, ['refId', 'id']),
          };
        }

        return customerInfo;
      });

      const updatedStoreCustomer =
        storeCustomer?.id === userId
          ? { ...storeCustomer, ...params }
          : storeCustomer;

      this.setState({
        storeCustomers: updatedStoreCustomers,
        storeCustomer: updatedStoreCustomer,
      });
    }
  };

  setStoreCustomer = async (storeCustomer) => {
    if (!isEmpty(storeCustomer) && isObject(storeCustomer)) {
      await this.setStateAsync({
        storeCustomer,
      });
    }
  };

  render() {
    const { children, isLoggedIn } = this.props;
    const {
      mounted,
      storeCustomer,
      stores,
      storeSelected,
      storesPage,
      storesListFetching,
      storesListHasNext,
      visitedStoreSelected,
      visitedStores,
      visitedStoresFetching,
      visitedStoresPage,
      visitedStoresHasNext,

      storeNotifications,
      storeNotificationsPage,
      storeNotificationsFetching,
      storeNotificationsHasNext,
      storeNotificationsTotalCount,

      storeCustomers,
      storeCustomersPage,
      storeCustomersFetching,
      storeCustomersHasNext,
      storeCustomersTotalCount,

      storeRewards,
      storeRewardsPage,
      storeRewardsFetching,
      storeRewardsHasNext,
    } = this.state;
    const props = {
      isLoggedIn,
      mounted,
      stores,
      storeCustomer,
      storeSelected,
      storesPage,
      storesListFetching,
      storesListHasNext,
      visitedStoreSelected,
      visitedStores,
      visitedStoresFetching,
      visitedStoresPage,
      visitedStoresHasNext,
      storeNotifications,
      storeNotificationsPage,
      storeNotificationsFetching,
      storeNotificationsHasNext,
      storeNotificationsTotalCount,

      storeCustomers,
      storeCustomersPage,
      storeCustomersFetching,
      storeCustomersHasNext,
      storeCustomersTotalCount,

      storeRewards,
      storeRewardsPage,
      storeRewardsFetching,
      storeRewardsHasNext,

      updateStoreCustomerProperty: this.updateStoreCustomerProperty,
      setStoreCustomer: this.setStoreCustomer,
      resetStoresVariable: this.reset,
      updateStoreRewardById: this.updateStoreRewardById,
      createNewStoreReward: this.createNewStoreReward,
      removeStoreRewardById: this.removeStoreRewardById,
      getStoreRewardsByPage: this.getStoreRewardsByPage,
      getStoreNotificationsNextPage: this.getStoreNotificationsNextPage,
      getStoreNotificationsPrevPage: this.getStoreNotificationsPrevPage,
      getStoreNotificationsByPage: this.getStoreNotificationsByPage,
      getStoreRecordedCustomersNextPage: this.getStoreRecordedCustomersNextPage,
      getStoreRecordedCustomersPrevPage: this.getStoreRecordedCustomersPrevPage,
      clearSelectedVisistedStore: this.clearSelectedVisistedStore,
      consumeVisitedStore: this.consumeVisitedStore,
      consumeStoresInfo: this.consumeStoresInfo,
      selectStore: this.selectStore,
      createNewStore: this.createNewStore,
      removeStore: this.removeStore,
      updateStoreProps: this.updateStoreProps,
      updateVisitedStoreProperty: this.updateVisitedStoreProperty,
    };

    return (
      <StoreManagerContextMemoized {...props}>
        {children}
      </StoreManagerContextMemoized>
    );
  }
}

const StoreManagerContextMemoized = ({ children, isLoggedIn, ...props }) => {
  const {
    stores,
    mounted,
    storeSelected,
    storeCustomer,
    storesPage,
    storesListFetching,
    storesListHasNext,
    visitedStores,
    visitedStoreSelected,
    visitedStoresFetching,
    visitedStoresPage,
    visitedStoresHasNext,

    storeNotifications,
    storeNotificationsPage,
    storeNotificationsFetching,
    storeNotificationsHasNext,
    storeNotificationsTotalCount,

    storeCustomers,
    storeCustomersPage,
    storeCustomersFetching,
    storeCustomersHasNext,
    storeCustomersTotalCount,

    storeRewards,
    storeRewardsPage,
    storeRewardsFetching,
    storeRewardsHasNext,
  } = props;

  const propsValue = useMemo(
    () => ({ ...props }),
    // eslint-disable-next-line
    [
      mounted,
      storeCustomer,
      storeSelected,
      stores,
      storesPage,
      storesListFetching,
      storesListHasNext,
      visitedStoreSelected,
      visitedStores,
      visitedStoresFetching,
      visitedStoresPage,
      visitedStoresHasNext,

      storeNotifications,
      storeNotificationsPage,
      storeNotificationsFetching,
      storeNotificationsHasNext,
      storeNotificationsTotalCount,

      storeCustomers,
      storeCustomersPage,
      storeCustomersFetching,
      storeCustomersHasNext,
      storeCustomersTotalCount,

      storeRewards,
      storeRewardsPage,
      storeRewardsFetching,
      storeRewardsHasNext,
    ]
  );

  return <Provider value={propsValue}>{children || null}</Provider>;
};

export default withUserProfileSettings(StoresManager);
