import cx from 'classnames';
import styles from './Login.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isFunction, size, toString } from 'lodash';
import { Button, useToast } from '@chakra-ui/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { EyeCloseIcon, EyeIcon } from 'icons';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { sendOwnerLoginRequest } from 'api';
import { withStoreManagerSettings } from 'managers/stores';
import { isCorrectEmailFormat, isSafeEmailAddress } from 'utils/helpers';
import SignUp from 'components/sign-up';
import ForgotPassword from 'components/forgot-password';
import { minPassword } from 'constants';

const Login = (props) => {
  const {
    setActiveModalDom,
    consumeUserForLogin,
    consumeStoresInfo,
    blockClosingOfModal,
    unBlockClosingOfModal,
    closeModal,
    browserRouter,
  } = props;
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [submitted, setSubmitted] = useState(false);

  const showSignup = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<SignUp />);
    }
  };

  const navigate = useMemo(() => browserRouter?.navigate, [browserRouter]);

  const toast = useToast({ position: 'top' });

  const showForgotPassword = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<ForgotPassword />);
    }
  };

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  const submit = useCallback(async () => {
    try {
      if (submitted) {
        return;
      }

      setSubmitted(true);
      setErrorMessage('');

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      if (!isSafeEmailAddress(email) || size(password) < minPassword) {
        setErrorMessage('Invalid email or password');
        return;
      }

      const { user, token, stores, errorMessage, networkError } =
        await sendOwnerLoginRequest(email, password);

      if (networkError) {
        toast({
          title: 'Check your network connection.',
          status: 'error',
          duration: 3_500,
          isClosable: true,
        });
      } else if (errorMessage) {
        if (isCorrectEmailFormat(email)) {
          setErrorMessage('Invalid email or password');
        } else {
          setErrorMessage('Invalid handle or password');
        }
      } else {
        if (isFunction(consumeUserForLogin)) {
          consumeUserForLogin(token, user);
        }

        if (isFunction(consumeStoresInfo)) {
          consumeStoresInfo(stores);
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }

        navigate('/dashboard');
      }
    } catch {
    } finally {
      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }

      setSubmitted(false);
    }
  }, [
    navigate,
    toast,
    submitted,
    email,
    password,
    consumeStoresInfo,
    consumeUserForLogin,
    closeModal,
    blockClosingOfModal,
    unBlockClosingOfModal,
  ]);

  useEffect(() => {
    const onKeydown = (evt) => {
      if (
        !isEmpty(email) &&
        !isEmpty(password) &&
        !submitted &&
        (evt.keyCode === 27 || evt.keyCode === 13)
      ) {
        submit();
      }
    };

    window.addEventListener('keydown', onKeydown, false);

    return () => {
      window.removeEventListener('keydown', onKeydown, false);
    };
  }, [submit, submitted, email, password]);

  return (
    <div className={cx(styles.flex_center_all, styles.login)}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Log in</p>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Email </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="youremail@domain.com"
          value={email}
          onChange={(evt) => {
            setEmail(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          type="text"
          aria-label="Your email for login"
        />
        <div
          className={cx(styles.flex_center_all, styles.input_right_icon, {
            [styles.hide_dom]: isEmpty(email),
          })}
        >
          <Button
            variant={'unstyled'}
            aria-label="Clear email input"
            className={cx(styles.flex_center_all, styles.clear)}
            onClick={() => {
              setEmail('');
              clearErrorMessage();
            }}
          >
            <Cross2Icon height={'18px'} width={'18px'} />
          </Button>
        </div>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Password </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="******"
          value={password}
          onChange={(evt) => {
            setPassword(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          type={showPassword ? 'text' : 'password'}
          aria-label="Fill out your password"
        />
        <div className={cx(styles.flex_center_all, styles.input_right_icon)}>
          <Button
            variant={'unstyled'}
            aria-label="Show password"
            className={cx(styles.flex_center_all, styles.toggle_show)}
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <EyeIcon />
            ) : (
              <EyeCloseIcon height={'18px'} width={'18px'} />
            )}
          </Button>
        </div>
      </div>

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted}
        >
          <p>Submit</p>
        </Button>

        <div className={cx(styles.flex_center_all, styles.forgot_password)}>
          <Button
            background={'transparent'}
            aria-label="Forgot password button"
            display={'flex'}
            onClick={showForgotPassword}
          >
            <p>Forgot Password?</p>
          </Button>
        </div>

        <div className={cx(styles.flex_center_all, styles.sign_up)}>
          <p>Don't have an account?</p>
          <Button
            background={'transparent'}
            aria-label="Create your account button"
            onClick={showSignup}
          >
            <p>Sign up</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(Login))
);
