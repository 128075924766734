import cx from 'classnames';
import styles from './CustomerPortalForm.module.scss';
import { useMemo, useState } from 'react';
import {
  includes,
  isEmpty,
  isFunction,
  isNaN,
  isNumber,
  omit,
  toLower,
  trim,
} from 'lodash';
import { Button, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon, Cross2Icon } from '@radix-ui/react-icons';
import { toString } from 'lodash';
import { primaryGreenColor } from 'constants';
import { withUserProfileSettings } from 'managers/profile';
import {
  loginUserEmailFromStoreRequest,
  sendRecordUserScannedRequest,
} from 'api';
import { withStoreManagerSettings } from 'managers/stores';
import { isCorrectEmailFormat, isMobileScreen } from 'utils/helpers';
import { withModalManagerSettings } from 'managers/modal';
import { withMobileToastSettings } from 'managers/mobileToast';
import { useForm } from 'react-hook-form';
import CustomerSelection from './CustomerSelection';
import { maxEmail } from 'constants';
import { maxName } from 'constants';

export const scannedOption = {
  storedAccount: 'storedAccount',
  existingAccount: 'existingAccount',
  newAccount: 'newAccount',
  login: 'login',
};

const CustomerPortalForm = (props) => {
  const {
    visitedStoreSelected,
    unBlockClosingOfModal,
    consumeUserProfileFromScannedAction,
    closeModal,
    browserRouter,
    profile,
    unblockMobileToastClosing,
    profileLoaded,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const storeId = useMemo(
    () => visitedStoreSelected?.id,
    [visitedStoreSelected]
  );

  const scanned = useMemo(
    () =>
      browserRouter
        ? toLower(toString(window.location.search))?.includes('scanned=true')
        : false,
    [browserRouter]
  );

  const [scanSelectedOption, setScanSelectedOption] = useState(
    scanned ? '' : scannedOption.login
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useMemo(() => browserRouter?.navigate, [browserRouter]);

  const toast = useToast({ position: 'top' });

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  const submitForm = async (data) => {
    try {
      const { email, fullName, purchase } = data;

      if (submitted) {
        return;
      }

      if (!email || !isCorrectEmailFormat(email)) {
        setErrorMessage('Invalid email address.');
        return;
      }

      setSubmitted(true);
      setErrorMessage('');
      let purchaseSanitized = Number(trim(purchase));

      if (isNaN(purchaseSanitized)) {
        purchaseSanitized = 0;
      }

      if (!scanned) {
        const { user, customer, networkError, errorMessage } =
          await loginUserEmailFromStoreRequest(email, storeId);

        if (networkError) {
          setErrorMessage('Check your network connection.');
          return;
        } else if (includes(toLower(errorMessage), 'store not found')) {
          setErrorMessage('Store not found.');
          return;
        } else if (includes(toLower(errorMessage), 'not found')) {
          setErrorMessage('User account not found.');
          return;
        } else if (!isEmpty(user)) {
          const totalPoints = isNumber(customer?.points)
            ? customer?.points
            : customer?.totalPoints;

          if (isFunction(consumeUserProfileFromScannedAction)) {
            consumeUserProfileFromScannedAction({
              ...user,
              ...omit(customer, ['id', 'refId', 'userId']),
              totalPoints,
              points: totalPoints,
            });
          }

          if (isFunction(navigate)) {
            navigate(`/portal/${visitedStoreSelected?.id}`);
          }

          toast({ title: 'Success', status: 'success' });
          closeModal(true);
          unBlockClosingOfModal(true);

          if (isMobileScreen() && isFunction(unblockMobileToastClosing)) {
            unblockMobileToastClosing(true);
          }
        } else {
          setErrorMessage('Something went wrong. Try again.');
          return;
        }
      } else {
        const { user, notFound, totalPoints, networkError } =
          await sendRecordUserScannedRequest(
            storeId,
            email,
            purchaseSanitized,
            fullName,
            profile?.country || '',
            profile?.region,
            scanSelectedOption === scannedOption.existingAccount ||
              scanSelectedOption === scannedOption.storedAccount
          );

        if (networkError) {
          setSubmitted(false);
          setErrorMessage('Check your network connection.');
          return;
        } else if (
          notFound &&
          (scanSelectedOption === scannedOption.existingAccount ||
            scanSelectedOption === scannedOption.storedAccount)
        ) {
          setSubmitted(false);
          setErrorMessage(
            'User account not found. Please create a new account.'
          );

          return;
        } else if (!user || isEmpty(user)) {
          setSubmitted(false);
          setErrorMessage('Something went wrong. Try again.');
          return;
        } else {
          // consume user for log in
          if (isFunction(consumeUserProfileFromScannedAction)) {
            consumeUserProfileFromScannedAction({
              ...user,
              totalPoints,
              points: totalPoints,
            });
          }
        }

        if (isFunction(navigate)) {
          navigate(`/portal/${visitedStoreSelected?.id}`);
        }

        toast({ title: 'Success', status: 'success' });
        closeModal(true);
        unBlockClosingOfModal(true);

        if (isMobileScreen() && isFunction(unblockMobileToastClosing)) {
          unblockMobileToastClosing(true);
        }
      }
    } catch (err) {
      console.log('submitForm() err:', err || err?.message);
    } finally {
      setSubmitted(false);
    }
  };

  const pickScanOption = (type = '', params = {}) => {
    if (!type) {
      return;
    }

    setScanSelectedOption(type);

    if (
      type === scannedOption.existingAccount ||
      type === scannedOption.newAccount
    ) {
      setValue('email', '');
      setValue('fullName', '');
    } else if (!isEmpty(params)) {
      const { email, fullName } = params;

      if (email) {
        setValue('email', trim(email));
      }

      if (fullName) {
        setValue('fullName', trim(fullName));
      }
    }
  };

  return (
    <div className={styles.customer_portal_form}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>
          {isEmpty(scanSelectedOption)
            ? 'Customer options'
            : 'Please fill out the information below.'}{' '}
        </p>
      </div>

      {isEmpty(scanSelectedOption) && (
        <CustomerSelection pickScanOption={pickScanOption} />
      )}

      <form
        onSubmit={handleSubmit(submitForm)}
        className={cx({ [styles.hide_dom]: isEmpty(scanSelectedOption) })}
      >
        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Email </p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_with_icon,
            styles.input_margin
          )}
        >
          <input
            placeholder="youremail@domain.com"
            type="text"
            aria-label="Your email for login"
            autoComplete="off"
            style={{ background: 'transparent' }}
            {...register('email', {
              required: 'Email is required',
              onChange: () => {
                clearErrorMessage();
              },
            })}
            maxLength={maxEmail}
          />

          <div
            className={cx(styles.flex_center_all, styles.input_right_icon, {
              [styles.hide_dom]:
                isEmpty(watch('email')) ||
                scanSelectedOption === scannedOption.storedAccount,
            })}
          >
            <Button
              variant={'unstyled'}
              aria-label="Clear email input"
              className={cx(styles.flex_center_all, styles.clear)}
              onClick={() => {
                setValue('email', '');

                clearErrorMessage();
              }}
            >
              <Cross2Icon height={'18px'} width={'18px'} />
            </Button>
          </div>
        </div>

        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label,
            {
              [styles.hide_dom]:
                !scanned || scanSelectedOption !== scannedOption.newAccount,
            }
          )}
        >
          <p>Full name</p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_margin,
            {
              [styles.hide_dom]:
                !scanned || scanSelectedOption !== scannedOption.newAccount,
            }
          )}
        >
          <input
            placeholder="Your name"
            {...register('fullName', {
              required: false,
              onChange: () => {
                clearErrorMessage();
              },
            })}
            autoComplete="off"
            type="text"
            aria-label="Input for your full name"
            maxLength={maxName}
          />
        </div>

        {scanned && (
          <>
            <div
              className={cx(
                styles.flex_center_all,
                styles.input_label_margin,
                styles.input_label
              )}
            >
              <p>Purchase Amount</p>
            </div>
            <div
              className={cx(
                styles.flex_center_all,
                styles.input,
                styles.input_margin
              )}
            >
              <input
                placeholder="e.g. 1000"
                {...register('purchase', {
                  required: false,
                  onChange: () => {
                    clearErrorMessage();
                  },
                })}
                autoComplete="off"
                type="text"
                aria-label="Purchase points"
              />
            </div>
          </>
        )}

        <div className={cx(styles.error, styles.flex_center_all)}>
          <p> {errorMessage || errors?.email?.message}</p>
        </div>

        <div className={cx(styles.flex_center_all, styles.cta)}>
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit)}
            color="#fff"
            type="submit"
            isLoading={submitted}
          >
            <p>Submit</p>
          </Button>

          {scanSelectedOption && profileLoaded && scanned && !submitted && (
            <Button
              type="button"
              className={styles.return_options}
              background={'transparent'}
              leftIcon={<ChevronLeftIcon height={'16px'} width={'16px'} />}
              onClick={() => setScanSelectedOption('')}
            >
              <p>Return </p>
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(
    withModalManagerSettings(withMobileToastSettings(CustomerPortalForm))
  )
);
