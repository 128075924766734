import StoreLayout from 'components/store-layout';
import styles from './CustomerPortal.module.scss';
import HeaderCustomerPortal from './Header';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withModalManagerSettings } from 'managers/modal';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';
import { isArray, isEmpty, isFunction, isNumber, isObject } from 'lodash';
import CustomerPortalForm from 'components/customer-portal-form';
import {
  getStoreInfoByHandleOrIdRequest,
  getStoreRewardsByPageRequest,
} from 'api/stores';
import { useAuth } from 'hooks';
import StoreContent from './StoreContent';
import Footer from './Footer';
import { AppRootContainerId } from 'App';
import { withMobileToastSettings } from 'managers/mobileToast';
import { isMobileScreen } from 'utils/helpers';
import CustomerPortalForCardHolderForm from 'components/customer-portal-card-holder-form';

export const HeaderCustomerPortalUserOptionsClassname =
  'HeaderCustomerPortalUserOptionsClassname';

const CustomerPortal = (props) => {
  const {
    profileLoaded,
    setActiveModalDom,
    user,
    customer,
    blockClosingOfModal,
    consumeVisitedStore,
    clearSelectedVisistedStore,
    updateUserProps,
    updateVisitedStoreProperty,
    setActiveDomForMobileToast,
    blockMobileToastClosing,
  } = props;

  const targetCustomerInfo = useMemo(
    () => (!isEmpty(customer) && isObject(customer) ? customer : user),
    [user, customer]
  );

  const fetchingStoreInfo = useRef(false);

  const location = useLocation();

  const [forceLogin, setForceLogin] = useState(false);

  const [fetchingTargetStoreInfo, setFetchingTargetStoreInfo] = useState(true);

  const [targetStoreInfo, setTargetStoreInfo] = useState(null);

  const urlPathname = useMemo(() => location?.pathname, [location]);

  const params = useParams();

  const [query] = useSearchParams();

  const scanned = useMemo(() => query?.get('scanned') || false, [query]);

  const physicalCardHolder = useMemo(
    () => !isEmpty(query?.get('qrUserId')) || false,
    [query]
  );

  const { getAuthenticatedHeaders } = useAuth(props);

  const storeIdOrHandle = useMemo(
    () => params?.storeIdOrHandle || '',
    [params?.storeIdOrHandle]
  );

  const loginChecked = useRef(false);

  const forceLoginMemoized = useMemo(() => forceLogin, [forceLogin]);

  useEffect(() => {
    if (loginChecked?.current) {
      return;
    }

    if (scanned && window.location?.pathname?.startsWith('/portal/login')) {
      setForceLogin(true);
      loginChecked.current = true;
    }
  }, [scanned]);

  useEffect(() => {
    if (loginChecked?.current) {
      return;
    }

    if (urlPathname?.startsWith('/portal/login')) {
      // force login
      setForceLogin(true);
      loginChecked.current = true;
    }
  }, [urlPathname]);

  useEffect(() => {
    if ((!targetCustomerInfo || isEmpty(targetCustomerInfo)) && profileLoaded) {
      setForceLogin(true);
      loginChecked.current = true;
    }
  }, [targetCustomerInfo, profileLoaded]);

  /**
   * Show form for card holders
   */
  useEffect(() => {
    const qrUserId = query?.get('qrUserId');
    if (
      profileLoaded &&
      (physicalCardHolder ||
        window.location?.pathname?.startsWith('/portal/card-holders')) &&
      isFunction(setActiveDomForMobileToast) &&
      isFunction(setActiveModalDom)
    ) {
      if (isMobileScreen()) {
        console.log(
          'setActiveDomForMobileToast(<CustomerPortalForCardHolderForm />)'
        );
        blockMobileToastClosing();
        setActiveDomForMobileToast(
          <CustomerPortalForCardHolderForm
            qrUserId={qrUserId}
            storeIdOrHandle={storeIdOrHandle}
          />
        );
      } else {
        blockClosingOfModal();
        console.log('setActiveModalDom(<CustomerPortalForCardHolderForm />)');
        setActiveModalDom(
          <CustomerPortalForCardHolderForm
            qrUserId={qrUserId}
            storeIdOrHandle={storeIdOrHandle}
          />,
          null,
          '',
          '',
          true
        );
      }

      setForceLogin(false);
    }
  }, [
    storeIdOrHandle,
    query,
    setActiveDomForMobileToast,
    setActiveModalDom,
    physicalCardHolder,
    profileLoaded,
    blockMobileToastClosing,
    blockClosingOfModal,
  ]);

  useEffect(() => {
    if (forceLoginMemoized && isFunction(setActiveModalDom)) {
      if (isMobileScreen()) {
        // use toast for mobile screen
        if (isFunction(setActiveDomForMobileToast)) {
          console.log('setActiveDomForMobileToast(<CustomerPortalForm />)');
          blockMobileToastClosing();
          setActiveDomForMobileToast(<CustomerPortalForm />);
        }
      } else {
        console.log('setActiveModalDom(<CustomerPortalForm />)');
        blockClosingOfModal();
        setActiveModalDom(<CustomerPortalForm />, null, '', '', true);
      }
    }
  }, [
    forceLoginMemoized,
    blockClosingOfModal,
    blockMobileToastClosing,
    setActiveDomForMobileToast,
    setActiveModalDom,
  ]);

  useEffect(() => {
    const fetchStoreInfo = async () => {
      if (fetchingStoreInfo.current) {
        return;
      }

      if (!storeIdOrHandle) {
        setFetchingTargetStoreInfo(false);
        return;
      }

      if (
        !isEmpty(storeIdOrHandle) &&
        (targetStoreInfo?.id === storeIdOrHandle ||
          targetStoreInfo?.handle === storeIdOrHandle)
      ) {
        // no need to fetch again
        return;
      }

      fetchingStoreInfo.current = true;
      setFetchingTargetStoreInfo(true);

      console.log('fetchStoreInfo storeIdOrHandle', storeIdOrHandle);

      const userEmail = user?.email || '';
      const customerEmail = customer?.email || '';
      const targetEmail = customerEmail || userEmail;
      const { store, errorMessage, networkError, customerInfo } =
        await getStoreInfoByHandleOrIdRequest(storeIdOrHandle, {}, targetEmail);

      if (errorMessage || networkError) {
        return;
      }

      setTargetStoreInfo({ ...store });
      setFetchingTargetStoreInfo(false);
      fetchingStoreInfo.current = false;

      if (
        !isEmpty(targetEmail) &&
        isNumber(customerInfo?.totalPoints) &&
        !customerEmail
      ) {
        // update user props
        if (isFunction(updateUserProps)) {
          updateUserProps({ totalPoints: customerInfo?.totalPoints });
        }
      }

      if (isFunction(consumeVisitedStore)) {
        consumeVisitedStore(store);

        const storeId = store?.id || storeIdOrHandle;
        const { rewards, errorMessage, networkError } =
          await getStoreRewardsByPageRequest(storeId, 1);

        if (!errorMessage && !networkError && isArray(rewards)) {
          if (isFunction(updateVisitedStoreProperty)) {
            updateVisitedStoreProperty(storeId, { rewards });
          }
        }
      }
    };

    if (profileLoaded && (!isEmpty(storeIdOrHandle) || !targetStoreInfo)) {
      fetchStoreInfo();
    }
  }, [
    profileLoaded,
    user,
    customer,
    storeIdOrHandle,
    targetStoreInfo,
    consumeVisitedStore,
    updateUserProps,
    updateVisitedStoreProperty,
    getAuthenticatedHeaders,
  ]);

  useEffect(() => {
    return () => {
      if (isFunction(clearSelectedVisistedStore)) {
        clearSelectedVisistedStore();
      }
    };

    // eslint-disable-next-line
  }, []);

  /**
   * Init scroll top
   */
  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    if (dom && isFunction(dom?.scrollTo)) {
      dom.scrollTo(0, 0);
    }
  }, []);

  // paths
  // /store/@storeNameOrIdOrHandle/
  // /portal/@storeNameOrIdOrHandle/ -> show rewards, info
  // /portal/qr/@storeNameOrIdOrHandle -> qr page
  // /portal/login/@storeNameOrIdOrHandle/ -> require email input, but store is selected
  // /portal -> no store selected
  // /portal/login -> require email input, no store selected
  return (
    <div className={styles.customer_portal}>
      <HeaderCustomerPortal fetchingTargetStoreInfo={fetchingTargetStoreInfo} />
      <div className={styles.customer_portal_content}>
        <StoreLayout fetchingTargetStoreInfo={fetchingTargetStoreInfo} />
        <StoreContent fetchingTargetStoreInfo={fetchingTargetStoreInfo} />
      </div>
      <Footer />
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(
    withModalManagerSettings(withMobileToastSettings(CustomerPortal))
  )
);
