import cx from 'classnames';
import styles from './StoreInfo.module.scss';
import NoStoreSelected from './NoStoreSelected';
import { withStoreManagerSettings } from 'managers/stores';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  head,
  isEmpty,
  isFunction,
  isUndefined,
  size,
  toLower,
  toString,
  trim,
} from 'lodash';
import { Avatar, Button, Spinner, Tooltip, useToast } from '@chakra-ui/react';
import { getHandlePropertyFromObject } from 'utils/helpers';
import { ImageFileIcon, PencilEditIcon } from 'icons';
import { minBusinessName, maxBusinessName, maxHandle } from 'constants';
import { minHandle } from 'constants';
import {
  base64ToBlob,
  getImageBase64,
  isTypeJpeg,
  isTypePng,
  scaleImageToJpeg,
} from 'utils/images';
import { withUserProfileSettings } from 'managers/profile';
import {
  updateStoreCoverPhotoImageRequest,
  updateStoreDisplayImageRequest,
  updateStoreInfoPropertyRequest,
} from 'api/stores';
import { useAuth } from 'hooks';
import './StoreInfo.scss';
import { ChevronRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { primaryGreenColor } from 'constants';
import { useNavigate } from 'react-router-dom';

export const uploadStoreImageFileInputId = 'uploadStoreImageFileInputId';

const StoreInfo = (props) => {
  const { storeSelected, updateStoreProps, storesListFetching } = props;

  const storeImageUploadingType = useRef('');
  const [uploadingStoreCoverPhoto, setUploadingStoreCoverPhoto] =
    useState(false);
  const [uploadingStoreImage, setUploadingStoreImage] = useState(false);

  const navigate = useNavigate();

  const saveStoreInfoTimeout = useRef(null);

  const coverPhotoDom = useRef(null);

  const storeNameInputDom = useRef(null);

  const storeHandleInputDom = useRef(null);

  const hasCoverPhoto = useMemo(
    () => !isEmpty(toString(storeSelected?.coverPhoto || '')),
    [storeSelected]
  );

  const noStoreSelected = useMemo(
    () => !storeSelected || isEmpty(storeSelected?.id),
    [storeSelected]
  );

  const fetchingInitialStore = useMemo(
    () => storesListFetching && isEmpty(storeSelected),
    [storeSelected, storesListFetching]
  );

  const [updatingProperty, setUpdatingProperty] = useState(false);

  const [storeName, setStoreName] = useState('');
  const [storeHandle, setStoreHandle] = useState(
    getHandlePropertyFromObject(storeSelected) || ''
  );
  const { getAuthenticatedHeaders } = useAuth(props);

  // Handle store switch
  useEffect(() => {
    if (!isUndefined(storeSelected?.businessName)) {
      setStoreName(storeSelected?.businessName || '');
    }
  }, [storeSelected?.businessName]);

  // Handle store switch
  useEffect(() => {
    setStoreHandle(
      `@${getHandlePropertyFromObject({ handle: storeSelected?.handle }) || ''}`
    );
  }, [storeSelected?.handle]);

  // store switch, update cover photo
  useEffect(() => {
    const dom = coverPhotoDom?.current;

    if (dom && !isEmpty(storeSelected?.coverPhoto)) {
      dom.setAttribute('src', storeSelected.coverPhoto);
    } else if (dom) {
      dom.setAttribute('src', '');
    }
  }, [storeSelected]);

  const updateStoreProperties = () => {
    if (updatingProperty) {
      return;
    }

    clearTimeout(saveStoreInfoTimeout?.current);

    // debounced
    saveStoreInfoTimeout.current = setTimeout(() => {
      // store name
      let newStoreHandle = storeHandle?.startsWith('@')
        ? trim(storeHandle.substring(1))
        : trim(storeHandle);
      let updatingBusinessName = storeName !== storeSelected?.businessName;
      let updatingBusinessHandle = newStoreHandle !== storeSelected?.handle;
      let props = {};

      if (updatingBusinessName) {
        if (!storeName || size(trim(storeName)) < minBusinessName) {
          setStoreName(storeSelected?.businessName);
          updatingBusinessName = false;
        } else {
          props.businessName = storeName;
        }
      }

      if (updatingBusinessHandle) {
        if (
          !newStoreHandle ||
          storeHandle === '@' ||
          size(trim(newStoreHandle)) < minHandle
        ) {
          setStoreHandle(
            `@${
              getHandlePropertyFromObject({ handle: storeSelected?.handle }) ||
              ''
            }`
          );

          updatingBusinessHandle = false;
        } else {
          props.handle = newStoreHandle;
        }
      }

      if (!isEmpty(props)) {
        setUpdatingProperty(true);

        // updating something
        const headers = getAuthenticatedHeaders(props);
        const storeId = storeSelected?.id;
        const send = async () => {
          const { store, errorMessage, networkError } =
            await updateStoreInfoPropertyRequest(storeId, props, headers);
          const sanitizedErrorMessage = toLower(errorMessage);

          setUpdatingProperty(false);

          if (networkError) {
            toast({
              status: 'error',
              title: 'Check your network connection.',
              duration: 2_500,
            });
          } else if (
            sanitizedErrorMessage?.includes('taken') &&
            sanitizedErrorMessage?.includes('handle')
          ) {
            setStoreHandle(
              `@${
                getHandlePropertyFromObject({
                  handle: newStoreHandle || storeSelected?.handle,
                }) || ''
              }`
            );

            toast({
              status: 'error',
              title: 'Handle is already taken.',
              duration: 2_500,
            });
          } else if (!isEmpty(store) && isFunction(updateStoreProps)) {
            updateStoreProps(storeId, { ...store });
          }
        };

        send();
      }

      // store handle
      clearTimeout(saveStoreInfoTimeout?.current);
    }, 600);
  };

  const toast = useToast({ position: 'top', id: 'chakraToastStoreInfo' });

  const updateCoverPhoto = () => {
    if (uploadingStoreCoverPhoto) {
      return;
    }

    storeImageUploadingType.current = 'coverPhoto';
    triggerStoreImageFileUpload();
  };

  const updateDisplayImage = () => {
    if (uploadingStoreImage) {
      return;
    }

    storeImageUploadingType.current = 'image';
    triggerStoreImageFileUpload();
  };

  const sendUpload = async (file) => {
    const fileName = file?.name;
    const fileSize = file?.size;
    const fileType = file?.type;
    let blob = file || null;

    const isUploadingStoreCoverPhoto =
      storeImageUploadingType?.current === 'coverPhoto';
    const isUploadingStoreImage = storeImageUploadingType?.current === 'image';
    const storeId = storeSelected?.id || '';
    const headers = getAuthenticatedHeaders();

    if (
      (isUploadingStoreCoverPhoto && uploadingStoreCoverPhoto) ||
      (isUploadingStoreImage && uploadingStoreImage)
    ) {
      return;
    }

    if (
      fileSize > 10_240_000 ||
      // convert file to jpeg if not yet a jpeg
      (!isTypeJpeg(fileType) && !isTypePng(fileType))
    ) {
      // convert if > 10MB
      const b64 = await getImageBase64(file);
      const { value: newBase64Value, err: scaleError } = await scaleImageToJpeg(
        b64,
        'image/jpeg',
        0.9
      );

      if (scaleError) {
        toast({
          title: 'Invalid image. Try again.',
          status: 'warning',
          position: 'top',
          duration: 1_500,
        });

        if (isUploadingStoreImage) {
          setUploadingStoreImage(false);
        } else {
          setUploadingStoreCoverPhoto(false);
        }

        return;
      }

      if (newBase64Value && !scaleError) {
        blob = base64ToBlob(newBase64Value, 'image/jpeg');
      }
    }

    if (!isUploadingStoreCoverPhoto && !isUploadingStoreImage) {
      toast({
        title: 'Invalid type. Try again.',
        status: 'error',
        position: 'top',
        duration: 1_500,
      });

      return;
    }

    let updatedStoreProps = null;

    if (isUploadingStoreCoverPhoto) {
      setUploadingStoreCoverPhoto(true);
    } else {
      setUploadingStoreImage(true);
    }

    toast({
      status: 'loading',
      duration: 1_000,
      isClosable: true,
      title: 'Uploading..',
    });

    if (isUploadingStoreCoverPhoto) {
      const { store, errorMessage, networkError } =
        await updateStoreCoverPhotoImageRequest(
          blob || file,
          fileName,
          storeId,
          headers
        );

      if (!isEmpty(store) && !errorMessage && !networkError) {
        updatedStoreProps = { ...store };
      }

      setUploadingStoreCoverPhoto(false);
    } else if (isUploadingStoreImage) {
      const { store, errorMessage, networkError } =
        await updateStoreDisplayImageRequest(
          blob || file,
          fileName,
          storeId,
          headers
        );

      if (!isEmpty(store) && !errorMessage && !networkError) {
        updatedStoreProps = { ...store };
      }

      setUploadingStoreImage(false);
    }

    if (!isEmpty(updatedStoreProps)) {
      // update store props

      if (isFunction(updateStoreProps)) {
        updateStoreProps(storeId, updatedStoreProps);
      }

      toast({
        status: 'success',
        isClosable: true,
        duration: 2_500,
        title: 'Successfully Uploaded!',
      });
    }

    storeImageUploadingType.current = '';
  };

  const triggerStoreImageFileUpload = () => {
    let input = document.getElementById(uploadStoreImageFileInputId);

    const onImageSelectHandler = function () {
      const files = this.files;
      const file = head(files);
      const fileType = file?.type;
      const fileSize = file?.size;

      if (fileSize && fileSize > 20_000_000) {
        toast({
          title: 'Image size exceeded limit. Try again.',
          status: 'error',
          duration: 1_500,
        });
        return;
      } else if (!fileType) {
        toast({
          title: 'Invalid image file.',
          status: 'error',
          duration: 1_500,
        });

        return;
      }

      if (!file) {
        return;
      }

      sendUpload(file);
    };

    if (input) {
      if (input?.remove) {
        input.remove();
      }
    }

    input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/avif'
    );

    input.onchange = onImageSelectHandler;
    input.setAttribute('id', uploadStoreImageFileInputId);
    input.setAttribute('multiple', false);
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'file');
    document.body.appendChild(input);
    input.click();
  };

  const navigateToQrPage = () => {
    navigate(`/portal/qr/${storeSelected?.id}`);
  };

  return (
    <div className={cx(styles.flex_center_all, styles.store_info)}>
      <div className={styles.content}>
        {!noStoreSelected && (
          <>
            <div className={cx(styles.flex_center_all, styles.images)}>
              <div className={cx(styles.flex_center_all, styles.cover_photo)}>
                <img
                  ref={coverPhotoDom}
                  src={storeSelected?.coverPhoto}
                  alt="store banner avatar"
                  className={cx({ [styles.hide_dom]: !hasCoverPhoto })}
                />

                {!hasCoverPhoto && (
                  <div
                    className={cx(
                      styles.flex_center_all,
                      styles.cover_photo_tip
                    )}
                    onClick={updateCoverPhoto}
                  >
                    <InfoCircledIcon height={'20px'} width={'20px'} />{' '}
                    <p>Upload a landscape image</p>
                  </div>
                )}

                <Tooltip
                  label="Update store cover photo"
                  className={styles.custom_tooltip}
                >
                  <Button
                    className={cx(
                      styles.flex_center_all,
                      styles.edit_button,
                      styles.edit_cover_photo
                    )}
                    variant={'ghost'}
                    height={'40px'}
                    width={'40px'}
                    padding={'0'}
                    boxSizing={'border-box'}
                    display={'flex'}
                    borderRadius={'50%'}
                    onClick={updateCoverPhoto}
                  >
                    <ImageFileIcon height={'16px'} width={'16px'} />
                  </Button>
                </Tooltip>
              </div>
              <div className={cx(styles.flex_center_all, styles.display_image)}>
                <div
                  className={cx(styles.flex_center_all, styles.store_avatar)}
                >
                  <div
                    className={cx(
                      styles.flex_center_all,
                      styles.avatar_wrapper
                    )}
                  >
                    <Avatar
                      src={storeSelected?.image}
                      name={head(storeSelected?.businessName)}
                      height={'120px'}
                      width={'120px'}
                      background={'#000'}
                      backgroundColor={'#000'}
                      color={'#fff'}
                    />
                  </div>

                  <Tooltip
                    label="Upload store image"
                    className={styles.custom_tooltip}
                  >
                    <Button
                      className={cx(
                        styles.flex_center_all,
                        styles.edit_button,
                        styles.update_avatar
                      )}
                      variant={'ghost'}
                      height={'40px'}
                      width={'40px'}
                      padding={'0'}
                      boxSizing={'border-box'}
                      display={'flex'}
                      borderRadius={'50%'}
                      onClick={updateDisplayImage}
                    >
                      <ImageFileIcon height={'16px'} width={'16px'} />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={cx(styles.flex_center_all, styles.store_texts)}>
              <div className={styles.store_texts_name}>
                <Tooltip
                  label={`${storeName}`}
                  className={styles.custom_tooltip}
                >
                  <input
                    type="text"
                    aria-label="Store name"
                    value={storeName}
                    onChange={(evt) => {
                      const val = toString(evt?.target?.value || '');

                      setStoreName(val);
                    }}
                    maxLength={maxBusinessName}
                    ref={storeNameInputDom}
                    onBlur={updateStoreProperties}
                  />
                </Tooltip>
                <Button
                  className={cx(
                    styles.flex_center_all,
                    styles.edit_name,
                    styles.edit_button
                  )}
                  variant={'ghost'}
                  height={'40px'}
                  width={'40px'}
                  padding={'0'}
                  boxSizing={'border-box'}
                  display={'flex'}
                  borderRadius={'50%'}
                  onClick={() => {
                    if (storeNameInputDom?.current) {
                      storeNameInputDom.current.focus();
                    }
                  }}
                >
                  <PencilEditIcon height={'16px'} width={'16px'} />
                </Button>
              </div>
              <div className={styles.store_handle_name}>
                <input
                  type="text"
                  aria-label="Store handle"
                  value={storeHandle}
                  onChange={(evt) => {
                    const val = toString(evt?.target?.value || '');

                    if (!val?.startsWith('@')) {
                      setStoreHandle(`@${val}`);
                    } else {
                      setStoreHandle(val);
                    }
                  }}
                  maxLength={maxHandle}
                  ref={storeHandleInputDom}
                  onBlur={updateStoreProperties}
                />
              </div>
            </div>
          </>
        )}

        {fetchingInitialStore && (
          <div className={cx(styles.flex_center_all, styles.loading)}>
            <Spinner height={'26px'} width={'26px'} />
          </div>
        )}

        {noStoreSelected && <NoStoreSelected forStoreOwner />}

        {!noStoreSelected && (
          <div className={cx(styles.flex_center_all, styles.store_cta)}>
            <Button
              height={'50px'}
              width={'240px'}
              variant={'ghost'}
              borderRadius={'100px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              color="#fff"
              className={cx(styles.flex_center_all, styles.nav_qrpage)}
              onClick={navigateToQrPage}
            >
              <p>Go to Store's QR Page</p>
              <ChevronRightIcon height={'20px'} width={'20px'} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(StoreInfo));
