import cx from 'classnames';
import styles from './UpdateCustomerCardInfo.module.scss';
import { Button, Tooltip } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { includes, isEmpty, isFunction, pick, size, trim } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { primaryGreenColor } from 'constants';
import { updateUserCardHolderInfoRequest } from 'api';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';
import { isCorrectEmailFormat } from 'utils/helpers';
import { minName } from 'constants';
import { maxName } from 'constants';
import { maxEmail } from 'constants';

const UpdateCustomerCardInfo = (props) => {
  const {
    customer,
    qrUserId,
    consumeUserProfileFromScannedAction,
    storeIdOrHandle,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [updating, setUpdating] = useState(false);

  const [error, setError] = useState('');

  const errorMessage = useMemo(
    () => error || errors?.email?.message?.toString() || '',
    [error, errors]
  );

  const closeComponent = () => {
    if (updating) {
      return;
    }
    if (isFunction(props?.close)) {
      props.close();
    }
  };

  const save = async (data) => {
    if (updating) {
      return;
    }

    try {
      const userId = qrUserId;
      const userProps = pick(data, ['email', 'fullName']);
      const sameInputs =
        // must be the same user logged in
        (customer?.id === qrUserId || customer?.userId === qrUserId) &&
        customer?.email &&
        userProps?.email === customer?.email &&
        customer?.fullName &&
        userProps?.fullName === customer?.fullName;

      if (isEmpty(userProps) || sameInputs) {
        closeComponent();
        return;
      }

      if (includes(userProps?.fullName, '.')) {
        setError("Account name should not contain '.'(dot) character");
        return;
      }

      if (size(userProps?.fullName) < minName) {
        setError(`Full name should at least contain ${minName} characters.`);
        return;
      }

      if (userProps?.email && !isCorrectEmailFormat(userProps?.email)) {
        setError('Invalid email address.');
        return;
      }

      setUpdating(true);
      const storeId = trim(storeIdOrHandle);
      const updateRes = await updateUserCardHolderInfoRequest(userId, {
        ...userProps,
        storeIdOrHandle: storeId,
      });

      if (updateRes?.user) {
        // success
        closeComponent();

        if (isFunction(consumeUserProfileFromScannedAction)) {
          consumeUserProfileFromScannedAction({
            ...customer,
            ...userProps,
          });
        }
      } else if (updateRes?.networkError) {
        setError('Please check your network connection.');
      } else {
        setError('Something went wrong.');
      }
    } catch (err) {
      console.log('<UpdateCustomerCardInfo/> save() err:', err?.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={styles.update_customer_card_info}>
      <div className={styles.update_customer_card_info_header}>
        <Tooltip label="Back" className={styles.custom_tooltip}>
          <Button
            className={cx(styles.flex_center_all, styles.return)}
            onClick={() => closeComponent()}
          >
            <ChevronLeftIcon />
          </Button>
        </Tooltip>
      </div>
      <form onSubmit={handleSubmit(save)}>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Full name </p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_margin
          )}
        >
          <input
            placeholder="Your name here."
            type="text"
            maxLength={maxName}
            aria-label="Your full name for your account"
            autoComplete="off"
            style={{ background: 'transparent' }}
            {...register('fullName', {
              required: 'Your full name is required',
              value: customer?.fullName || '',
            })}
          />
        </div>

        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Email </p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_margin
          )}
        >
          <input
            placeholder="youremail@domain.com"
            type="text"
            aria-label="Your email for your account"
            autoComplete="off"
            style={{ background: 'transparent' }}
            {...register('email', {
              required: false,
              value: customer?.email || '',
            })}
            maxLength={maxEmail}
          />
        </div>

        <div className={cx(styles.flex_center_all, styles.actions)}>
          {errorMessage && (
            <div className={cx(styles.flex_center_all, styles.error)}>
              <p>{errorMessage}</p>
            </div>
          )}
          <Button
            height={'44px'}
            variant={'ghost'}
            borderRadius={'50px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.save_changes)}
            color="#fff"
            type="submit"
            isLoading={updating}
          >
            <p>Save changes</p>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(UpdateCustomerCardInfo)
);
