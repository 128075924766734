import cx from 'classnames';
import styles from './CustomerPortalCardHolderForm.module.scss';
import { Button, Spinner } from '@chakra-ui/react';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import { primaryGreenColor } from 'constants';
import { useEffect, useMemo, useState } from 'react';
import { loginUserCardHolderRequest } from 'api';
import { isFunction, isNumber } from 'lodash';
import { withModalManagerSettings } from 'managers/modal';
import { withMobileToastSettings } from 'managers/mobileToast';
import { isMobileScreen } from 'utils/helpers';
import UpdateCustomerCardInfo from './UpdateCustomerCardInfo';
import { ChevronRightIcon } from '@radix-ui/react-icons';

const CustomerPortalForCardHolderForm = (props) => {
  const {
    consumeUserProfileFromScannedAction,
    qrUserId,
    storeIdOrHandle,
    unBlockClosingOfModal,
    unblockMobileToastClosing,
    closeModal,
    closeMobileToast,
  } = props;
  const [fetchingCardInfo, setFetchingCardInfo] = useState(true);
  const [showUpdateInfoForm, setShowUpdateInfoForm] = useState(false);

  useEffect(() => {
    const login = async () => {
      console.log('login()');

      try {
        const { customer, networkError, errorMessage, user } =
          await loginUserCardHolderRequest(qrUserId, storeIdOrHandle);

        if (networkError || errorMessage) {
          return;
        }

        if (customer) {
          const totalPoints = isNumber(customer?.points)
            ? customer?.points
            : customer?.totalPoints;

          if (isFunction(consumeUserProfileFromScannedAction)) {
            consumeUserProfileFromScannedAction({
              ...user,
              ...customer,
              totalPoints,
              cardHolder: true,
            });
          }

          setFetchingCardInfo(false);
        }
      } catch (err) {
        console.log('loginUserCardHolderRequest() err:', err?.message);
      }
    };

    login();
  }, [storeIdOrHandle, qrUserId, consumeUserProfileFromScannedAction]);

  const close = () => {
    if (fetchingCardInfo) {
      return;
    }

    if (isMobileScreen()) {
      if (isFunction(closeMobileToast)) {
        unblockMobileToastClosing();
        closeMobileToast(true);
      }
    } else if (isFunction(closeModal)) {
      unBlockClosingOfModal();
      closeModal(true);
    }
  };

  const hideEntryActions = useMemo(
    () => showUpdateInfoForm,
    [showUpdateInfoForm]
  );

  // fetch user info
  // enable login card holders -> Proceed to store button
  // enable update for card holders -> proceed to update properties (email,fullName,etc)
  return (
    <div className={styles.customer_portal_card_holder_form}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        {fetchingCardInfo && (
          <div className={cx(styles.flex_center_all, styles.loading)}>
            <Spinner />
          </div>
        )}
        {showUpdateInfoForm && (
          <UpdateCustomerCardInfo
            qrUserId={qrUserId}
            storeIdOrHandle={storeIdOrHandle}
            close={() => setShowUpdateInfoForm(false)}
          />
        )}

        {!hideEntryActions && (
          <>
            <Button
              height={'44px'}
              variant={'ghost'}
              borderRadius={'4px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              className={cx(styles.flex_center_all, styles.check_points)}
              color="#fff"
              onClick={close}
            >
              <p> Check points</p>
              <ChevronRightIcon height={20} width={20} />
            </Button>
            <Button
              height={'44px'}
              variant={'ghost'}
              borderRadius={'4px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              className={cx(styles.flex_center_all, styles.update_info)}
              color="#fff"
              isLoading={fetchingCardInfo}
              onClick={() => setShowUpdateInfoForm(true)}
            >
              <p> Update info</p>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(
    withModalManagerSettings(
      withMobileToastSettings(CustomerPortalForCardHolderForm)
    )
  )
);
