import cx from 'classnames';
import styles from './StoreRewardForm.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ceil,
  head,
  isEmpty,
  isFunction,
  isNaN,
  isNil,
  isNumber,
  isObject,
  isString,
  toString,
  trim,
} from 'lodash';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import {
  createStoreRewardRequest,
  updateStoreRewardRequest,
  uploadImageFileForStoreReward,
} from 'api/stores';
import { useAuth } from 'hooks';
import RewardDescription from './RewardDescription';
import {
  base64ToBlob,
  getImageBase64,
  isTypeJpeg,
  isTypePng,
  scaleImageToJpeg,
} from 'utils/images';
// create
// edit

export const StoreRewardInputFileId = 'StoreRewardInputFileId';

const StoreRewardForm = (props) => {
  const {
    storeSelected,
    blockClosingOfModal,
    unBlockClosingOfModal,
    targetRewardInfo,
    closeModal,
    createNewStoreReward,
    updateStoreRewardById,
  } = props;

  const [imageUrl, setImageUrl] = useState('');
  const [points, setPoints] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setRewardName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const editorInstance = useRef(null);
  const imagePreviewDom = useRef(null);

  const storeId = useMemo(() => storeSelected?.id || '', [storeSelected]);

  const createMode = useMemo(
    () => isEmpty(targetRewardInfo) || isNil(targetRewardInfo),
    [targetRewardInfo]
  );

  const rewardId = useMemo(
    () => targetRewardInfo?.id || '',
    [targetRewardInfo]
  );

  const hasImage = useMemo(() => !isEmpty(imageUrl), [imageUrl]);

  const toast = useToast({ position: 'top' });

  const { getAuthenticatedHeaders } = useAuth(props);

  useEffect(() => {
    const imgDom = imagePreviewDom?.current;

    if (!isEmpty(imageUrl)) {
      // modify image tag

      if (imgDom) {
        imgDom.setAttribute('src', imageUrl);
      }
    } else if (imgDom) {
      imgDom.removeAttribute('src');
    }
  }, [imageUrl]);

  useEffect(() => {
    if (
      isObject(targetRewardInfo) &&
      !isEmpty(targetRewardInfo?.id) &&
      !targetRewardInfo?.deleted
    ) {
      setRewardName(toString(targetRewardInfo?.name));
      setPoints(toString(targetRewardInfo?.points || 0));
      setImageUrl(toString(targetRewardInfo?.image));
      const editor = editorInstance?.current;
      let description = targetRewardInfo?.description;

      if (description) {
        try {
          if (!isObject(description) || isString(description)) {
            description = JSON.parse(description);
          }
        } catch {
          description = { type: 'doc', content: [] };
        } finally {
          if (!isEmpty(description) && isObject(description)) {
            if (editor) {
              editor.commands.setContent(description);
            }
          }
        }
      }
    }
  }, [targetRewardInfo]);

  const submit = async () => {
    if (submitted || uploadingImage) {
      return;
    }

    if (!createMode && !rewardId) {
      // invalid
      toast({
        title: 'Unable to update reward.',
        duration: 2_500,
        isClosable: true,
      });
      return;
    }

    try {
      const headers = getAuthenticatedHeaders();
      const editor = editorInstance?.current;
      const sanitizedPoints = ceil(parseFloat(trim(points)));
      const sanitizedName = trim(name);
      const updating = !createMode;
      const tiptapJson = editor.getJSON();

      let isSuccess = false;
      let rewardProps = null;

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      if (!name && !updating) {
        setErrorMessage('Name is required');
        return;
      }

      if (!updating && (isNaN(sanitizedPoints) || !isNumber(sanitizedPoints))) {
        setErrorMessage(
          'Invalid points, make sure you provide a valid integer.'
        );

        return;
      }

      const descriptionString = JSON.stringify(tiptapJson);
      setSubmitted(true);

      if (updating) {
        const updatingName =
          !isEmpty(sanitizedName) && sanitizedName !== targetRewardInfo?.name;
        const updatingPoints = sanitizedPoints !== targetRewardInfo?.points;
        const updatingImage = targetRewardInfo?.image !== imageUrl;

        const { reward } = await updateStoreRewardRequest(
          rewardId,
          storeId,
          {
            description: descriptionString,
            ...(updatingImage && { image: imageUrl }),
            ...(updatingName && { name: sanitizedName }),
            ...(updatingPoints && { points: sanitizedPoints }),
          },
          headers
        );

        if (!isEmpty(reward) && isObject(reward)) {
          isSuccess = true;
          rewardProps = reward;
        }
      } else {
        const { reward } = await createStoreRewardRequest(
          storeId,
          sanitizedName,
          {
            description: descriptionString,
            image: imageUrl || '',
            points: sanitizedPoints,
          },
          headers
        );

        if (!isEmpty(reward) && isObject(reward)) {
          isSuccess = true;
          rewardProps = reward;
        }
      }

      if (isSuccess) {
        if (!isEmpty(rewardProps)) {
          // store

          if (isFunction(updateStoreRewardById) && updating) {
            updateStoreRewardById(rewardId, rewardProps);
          } else if (!rewardId && isFunction(createNewStoreReward)) {
            createNewStoreReward(rewardProps);
          }
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }
      } else {
        toast({ status: 'error', title: 'Inva' });
      }
    } catch {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  const clearErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const sendUpload = async (file) => {
    const fileName = file?.name;
    const fileSize = file?.size;
    const fileType = file?.type;
    let blob = file || null;

    if (uploadingImage) {
      return;
    }

    try {
      setUploadingImage(true);

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      toast({
        status: 'loading',
        duration: 500,
        isClosable: false,
        title: 'Uploading..',
      });

      if (
        fileSize > 10_240_000 ||
        // convert file to jpeg if not yet a jpeg
        (!isTypeJpeg(fileType) && !isTypePng(fileType))
      ) {
        const b64 = await getImageBase64(file);
        const { value: newBase64Value, err: scaleError } =
          await scaleImageToJpeg(b64, 'image/jpeg', 0.7);

        if (scaleError) {
          toast({
            title: 'Invalid image. Try again.',
            status: 'warning',
            position: 'top',
            duration: 1_500,
          });
          setUploadingImage(false);
          return;
        }

        if (newBase64Value && !scaleError) {
          blob = base64ToBlob(newBase64Value, 'image/jpeg');
        }
      }

      const headers = getAuthenticatedHeaders();
      const { image, errorMessage, networkError } =
        await uploadImageFileForStoreReward(
          blob || file,
          fileName,
          storeId,
          headers,
          createMode ? '' : rewardId
        );

      if (!errorMessage && !networkError && !isEmpty(image)) {
        // consume image
        // update
        setImageUrl(image);
        toast({ status: 'success', title: 'Successfully Uploaded!' });

        if (!createMode && isFunction(updateStoreRewardById)) {
          updateStoreRewardById(rewardId, { image });
        }
      }
    } catch {
    } finally {
      setUploadingImage(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  const uploadRewardImage = () => {
    if (uploadingImage || submitted) {
      return;
    }

    let input = document.getElementById(StoreRewardInputFileId);

    const onImageSelectHandler = function () {
      const files = this.files;
      const file = head(files);
      const fileType = file?.type;
      const fileSize = file?.size;

      if (fileSize && fileSize > 20_000_000) {
        toast({
          title: 'Image size exceeded limit. Try again.',
          status: 'error',
          duration: 1_500,
        });
        return;
      } else if (!fileType) {
        toast({
          title: 'Invalid image file.',
          status: 'error',
          duration: 1_500,
        });

        return;
      }

      if (!file) {
        return;
      }

      sendUpload(file);
    };

    if (input) {
      if (input?.remove) {
        input.remove();
      }
    }

    input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/avif'
    );

    input.onchange = onImageSelectHandler;
    input.setAttribute('id', StoreRewardInputFileId);
    input.setAttribute('multiple', false);
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'file');
    document.body.appendChild(input);
    input.click();
  };

  return (
    <div className={styles.store_reward_form}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>{createMode ? 'Create a reward' : 'Edit your reward'}</p>
      </div>
      <div className={cx(styles.flex_center_all, styles.image)}>
        {!hasImage && !uploadingImage && (
          <div className={cx(styles.flex_center_all, styles.image_placeholder)}>
            <p>
              Upload a square (1:1) or portrait size image. This is optional.
            </p>
          </div>
        )}
        {uploadingImage && (
          <div className={cx(styles.flex_center_all, styles.uploading)}>
            <Spinner height={'20px'} width={'20px'} />
          </div>
        )}
        <img
          alt="Reward preview"
          ref={imagePreviewDom}
          className={cx(styles.image_raw, { [styles.hide_dom]: !hasImage })}
        />
        <div className={cx(styles.cover_dom, styles.image_cover)}></div>
      </div>

      <div className={cx(styles.flex_center_all, styles.upload_cta)}>
        <Button
          variant={'ghost'}
          className={cx(styles.flex_center_all, styles.upload_button)}
          onClick={uploadRewardImage}
          borderRadius={'20px'}
        >
          <p>
            {imageUrl || targetRewardInfo?.image
              ? 'Replace image'
              : 'Upload image'}
          </p>
        </Button>
      </div>

      <div className={styles.name}>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Reward name </p>
        </div>

        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_margin
          )}
        >
          <input
            placeholder="e.g. 50% discount for 1000 points"
            value={name}
            onChange={(evt) => {
              setRewardName(toString(evt?.target?.value || ''));
              clearErrorMessage();
            }}
            maxLength={50}
            type="text"
            aria-label="Reward name for your store"
          />
        </div>
      </div>

      <div className={styles.points}>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Required Points </p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_margin
          )}
        >
          <input
            placeholder="Your required points here"
            value={points}
            onChange={(evt) => {
              setPoints(toString(evt?.target?.value || ''));
              clearErrorMessage();
            }}
            maxLength={40}
            type="text"
            aria-label="Required points for your reward"
          />
        </div>
      </div>

      <RewardDescription
        storeEditorInstance={(editor) => (editorInstance.current = editor)}
      />

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted || uploadingImage}
        >
          <p>{createMode ? 'Submit' : 'Update'}</p>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(StoreRewardForm))
);
