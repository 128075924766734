import cx from 'classnames';
import styles from './ResetPassword.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { includes, size, toLower, toString } from 'lodash';
import { EyeCloseIcon, EyeIcon } from 'icons';
import { Button, useToast } from '@chakra-ui/react';
import { maxPassword } from 'constants';
import { primaryGreenColor } from 'constants';
import { minPassword } from 'constants';
import { updatePasswordFromForgotPassword } from 'api';

const ResetPassword = (props) => {
  const { isLoggedIn, profileLoaded } = props;
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const tokenId = useMemo(() => toString(params?.tokenId), [params]);

  const toast = useToast({ position: 'top' });

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      if (!password || size(password) < minPassword) {
        setErrorMessage(
          `Password must be at least ${minPassword} characters length.`
        );
        return;
      }

      setErrorMessage('');
      setSubmitted(true);

      const { errorMessage, networkError, success } =
        await updatePasswordFromForgotPassword(password, tokenId);

      if (success) {
        navigate('/');
      } else if (networkError) {
        toast({
          status: 'error',
          title: 'Check your network connection.',
          duration: 2_500,
          isClosable: true,
        });
      } else {
        if (includes(toLower(errorMessage), 'token already consumed')) {
          toast({
            status: 'error',
            title: 'Expired token.',
            duration: 2_500,
            isClosable: true,
          });

          navigate('/');
        } else if (!tokenId || size(tokenId) < 10) {
          toast({
            status: 'error',
            title: 'Invalid token',
            duration: 2_500,
            isClosable: true,
          });

          if (isLoggedIn) {
            navigate('/dashboard');
          } else {
            navigate('/');
          }
        } else {
          setErrorMessage('Something went wrong');
        }
      }
    } catch (err) {
    } finally {
      setSubmitted(false);
    }
  };

  const userIsLoggedIn = useMemo(
    () => profileLoaded && isLoggedIn,
    [isLoggedIn, profileLoaded]
  );

  useEffect(() => {
    if (userIsLoggedIn) {
      navigate('/dashboard');
    }
  }, [navigate, userIsLoggedIn]);

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  return (
    <div className={cx(styles.flex_center_all, styles.reset_password)}>
      <div className={styles.content}>
        <div className={cx(styles.flex_center_all, styles.logo)}>
          <img alt={'App logo'} src={'/images/logo-192.png'} />
        </div>

        <div className={cx(styles.flex_center_all, styles.label)}>
          <p>Reset your password</p>
        </div>

        <div
          className={cx(
            styles.flex_center_all,
            styles.input_label_margin,
            styles.input_label
          )}
        >
          <p>Your new password </p>
        </div>
        <div
          className={cx(
            styles.flex_center_all,
            styles.input,
            styles.input_with_icon,
            styles.input_margin,
            styles.new_password
          )}
        >
          <input
            placeholder="******"
            value={password}
            onChange={(evt) => {
              setPassword(toString(evt?.target?.value || ''));
              clearErrorMessage();
            }}
            maxLength={maxPassword}
            type={showPassword ? 'text' : 'password'}
            aria-label="Fill out your password"
          />
          <div className={cx(styles.flex_center_all, styles.input_right_icon)}>
            <Button
              variant={'unstyled'}
              aria-label="Show password"
              className={cx(styles.flex_center_all, styles.toggle_show)}
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <EyeIcon />
              ) : (
                <EyeCloseIcon height={'18px'} width={'18px'} />
              )}
            </Button>
          </div>
        </div>

        <div className={cx(styles.error, styles.flex_center_all)}>
          <p> {errorMessage}</p>
        </div>

        <div className={cx(styles.flex_center_all, styles.cta)}>
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit)}
            color="#fff"
            onClick={submit}
            isLoading={submitted}
          >
            <p>Submit</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(ResetPassword);
