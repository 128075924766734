import cx from 'classnames';
import styles from './HeaderLogo.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const HeaderLogo = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onLogoClick = () => {
    if (
      location?.pathname?.startsWith('/terms-service') ||
      location?.pathname?.startsWith('/privacy-policy')
    ) {
      navigate('/');
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.headerlogo)}>
      <button
        type="button"
        className={styles.flex_center_all}
        onClick={onLogoClick}
      >
        <img src={'images/logo-192.png'} alt="App logo" />
        <p>melonkarma</p>
      </button>
    </div>
  );
};

export default HeaderLogo;
