import cx from 'classnames';
import styles from './CreateQRCardHolder.module.scss';
import { useForm } from 'react-hook-form';
import { Button, useToast } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { useMemo, useState } from 'react';
import { isEmpty, isFunction, isNaN, pick, size } from 'lodash';
import { withModalManagerSettings } from 'managers/modal';
import { createStoreUserQRForCardHolderRequest } from 'api/storeQR';
import { withStoreManagerSettings } from 'managers/stores';
import { useAuth } from 'hooks';
import { withUserProfileSettings } from 'managers/profile';
import { isCorrectEmailFormat, isMobileScreen } from 'utils/helpers';
import { withMobileToastSettings } from 'managers/mobileToast';
import { minName } from 'constants';

const CreateQRCardHolder = (props) => {
  const {
    blockClosingOfModal,
    unBlockClosingOfModal,
    storeSelected,
    closeModal,
    blockMobileToastClosing,
    unblockMobileToastClosing,
    onCreateSuccessCallback,
    closeMobileToast,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitted, setSubmitted] = useState(false);

  const storeId = useMemo(() => storeSelected?.id, [storeSelected]);

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast();

  const [error, setError] = useState('');

  const submitNewQRForUser = async (data) => {
    const { purchase: unsanitizedPurchase } = data;

    try {
      if (submitted) {
        return;
      }

      const userProps = pick(data, ['email', 'fullName']);
      const purchase = parseFloat(unsanitizedPurchase);

      if (isNaN(purchase) || purchase < 0) {
        setError('Invalid purchase value.');
        return;
      }

      if (userProps?.email && !isCorrectEmailFormat(userProps?.email)) {
        setError('Invalid email address.');
        return;
      }

      if (userProps?.fullName && size(userProps?.fullName) < minName) {
        setError(`Full name should at least contain ${minName} characters.`);
        return;
      }

      setSubmitted(true);
      setError('');

      if (isMobileScreen()) {
        blockMobileToastClosing();
      } else {
        blockClosingOfModal();
      }

      const authHeaders = getAuthenticatedHeaders();
      const { errorMessage, networkError, user, generatedQR, qrUrl } =
        await createStoreUserQRForCardHolderRequest(
          storeId,
          { purchase, ...userProps },
          {},
          authHeaders
        );

      if (
        !errorMessage &&
        !networkError &&
        generatedQR &&
        !isEmpty(generatedQR)
      ) {
        // success
        toast({
          status: 'success',
          title: 'Successfully Created!',
          duration: 2_500,
        });

        if (isMobileScreen() && isFunction(closeMobileToast)) {
          closeMobileToast();
        } else {
          if (isFunction(closeModal)) {
            closeModal(true);
          }
        }

        if (isFunction(onCreateSuccessCallback)) {
          onCreateSuccessCallback({ generatedQR, user, qrUrl });
        }
      } else if (networkError) {
        setError('Network Error. Try again.');
      } else {
        setError('Something went wrong.');
      }
    } catch (err) {
      console.log('submitNewQRForUser() err:', err?.message);
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }

      if (isFunction(unblockMobileToastClosing)) {
        unblockMobileToastClosing();
      }
    }
  };

  const errorMessage = useMemo(
    () => error || errors?.purchase?.message?.toString() || '',
    [errors, error]
  );

  return (
    <div className={styles.create_qr_card_holder}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>{'Generate QR for card holder'}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.tip)}></div>

      <form onSubmit={handleSubmit(submitNewQRForUser)}>
        <div className={styles.purchase}>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label
            )}
          >
            <p>Purchase value </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="1000"
              maxLength={40}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              aria-label="Customer purchase"
              {...register('purchase', {
                required: 'Please provide purchase value.',
              })}
            />
          </div>
        </div>

        <div className={styles.fullName}>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label
            )}
          >
            <p>Full Name (optional) </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="Customer name"
              maxLength={250}
              type="text"
              aria-label="Customer full name"
              autoComplete="off"
              {...register('fullName')}
            />
          </div>
        </div>

        <div className={styles.email}>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label
            )}
          >
            <p>Email address (optional) </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="customer@email.com"
              maxLength={250}
              type="text"
              autoComplete="off"
              aria-label="Customer email"
              {...register('email')}
            />
          </div>
        </div>

        <div className={cx(styles.flex_center_all, styles.cta)}>
          {!isEmpty(errorMessage) && (
            <div className={cx(styles.error_message, styles.flex_center_all)}>
              <p>{errorMessage} </p>
            </div>
          )}
          <Button
            height={'50px'}
            minWidth={'180px'}
            variant={'ghost'}
            borderRadius={'100px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit)}
            color="#fff"
            type="submit"
            isLoading={submitted}
          >
            <p>Submit </p>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(
    withModalManagerSettings(withMobileToastSettings(CreateQRCardHolder))
  )
);
