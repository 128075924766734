import cx from 'classnames';
import styles from './StoreLayout.module.scss';
import NoStoreSelected from 'pages/dashboard/NoStoreSelected';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withStoreManagerSettings } from 'managers/stores';
import { head, isEmpty, isUndefined } from 'lodash';
import { Avatar, Spinner } from '@chakra-ui/react';
import { getHandlePropertyFromObject } from 'utils/helpers';
import { withUserProfileSettings } from 'managers/profile';
import { MoreDotIcon } from 'icons';

const StoreLayout = (props) => {
  const { visitedStoreSelected, fetchingTargetStoreInfo } = props;

  const storeSelected = useMemo(
    () => visitedStoreSelected,
    [visitedStoreSelected]
  );

  const coverPhotoDom = useRef(null);

  const hasCoverPhoto = useMemo(
    () => !isEmpty(storeSelected?.coverPhoto),
    [storeSelected]
  );

  const noStoreSelected = useMemo(
    () =>
      !fetchingTargetStoreInfo &&
      (!storeSelected || isEmpty(storeSelected?.id)),
    [storeSelected, fetchingTargetStoreInfo]
  );

  const [storeName, setStoreName] = useState('');
  const [storeHandle, setStoreHandle] = useState(
    getHandlePropertyFromObject(storeSelected) || ''
  );

  // Handle store switch
  useEffect(() => {
    if (!isUndefined(storeSelected?.businessName)) {
      setStoreName(storeSelected?.businessName || '');
    }
  }, [storeSelected?.businessName]);

  // Handle store switch
  useEffect(() => {
    if (!isUndefined(storeSelected?.handle)) {
      setStoreHandle(
        `@${
          getHandlePropertyFromObject({ handle: storeSelected?.handle }) || ''
        }`
      );
    }
  }, [storeSelected?.handle]);

  useEffect(() => {
    const dom = coverPhotoDom?.current;

    if (dom && !isEmpty(storeSelected?.coverPhoto)) {
      dom.setAttribute('src', storeSelected.coverPhoto);
    } else if (dom) {
      dom.setAttribute('src', '');
    }
  }, [storeSelected]);

  return (
    <div className={cx(styles.flex_center_all, styles.store_layout)}>
      <div
        className={cx(styles.content, {
          [styles.hide_dom]: noStoreSelected,
        })}
      >
        <>
          <div className={cx(styles.flex_center_all, styles.images)}>
            <div className={cx(styles.flex_center_all, styles.cover_photo)}>
              <img
                className={cx({ [styles.hide_dom]: !hasCoverPhoto })}
                ref={coverPhotoDom}
                src={storeSelected?.coverPhoto}
                alt="store banner avatar"
              />
              {fetchingTargetStoreInfo && (
                <div
                  className={cx(
                    styles.flex_center_all,
                    styles.cover_photo_placeholder
                  )}
                >
                  <Spinner height={'30px'} width={'30px'} />
                </div>
              )}
            </div>
            <div className={cx(styles.flex_center_all, styles.display_image)}>
              <div className={cx(styles.flex_center_all, styles.store_avatar)}>
                <div
                  className={cx(styles.flex_center_all, styles.avatar_wrapper)}
                >
                  {storeSelected && (
                    <Avatar
                      src={storeSelected?.image || ''}
                      name={head(storeSelected?.businessName) || ''}
                      height={'120px'}
                      width={'120px'}
                      background={'#000'}
                      backgroundColor={'#000'}
                      color={'#fff'}
                    />
                  )}

                  {!storeSelected && (
                    <div
                      className={cx(
                        styles.flex_center_all,
                        styles.avatar_placeholder
                      )}
                    >
                      <MoreDotIcon height={'24px'} width={'24px'} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={cx(styles.flex_center_all, styles.store_texts)}>
            <div className={styles.store_texts_name}>
              <p> {storeName} </p>
            </div>
            <div className={styles.store_handle_name}>
              <p>{storeHandle}</p>
            </div>
          </div>
        </>
      </div>
      {noStoreSelected && <NoStoreSelected />}
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(StoreLayout));
