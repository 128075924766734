import cx from 'classnames';
import styles from './App.module.scss';
import { useWindowResize, useWindowScroll } from 'hooks';
import { useEffect, useRef, Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  isCapacitorIos,
  isCapacitorMobileApp,
  isIosMobile,
  isMobileScreen,
} from 'utils/helpers';
import NetworkManager from 'managers/network';
import ProfileManager from 'managers/profile';
import StoresManager from 'managers/stores';
import ModalManager from 'managers/modal';
import RouteElementContainer from 'RouteElementContainer';
import Home from 'pages/home';
import PageNotFound from 'pages/not-found';
import Dashboard from 'pages/dashboard';
import AuthRequiredLayout from 'AuthRequiredLayout';
import CustomerPortal from 'pages/customer-portal';
import Account from 'pages/account';
import VerifyEmail from 'pages/verify-email';
import ResetPassword from 'pages/reset-password';
import TermsService from 'pages/terms-service';
import PrivacyPolicy from 'pages/privacy-policy';
import CustomerView from 'pages/dashboard/CustomerView';
import Billing from 'pages/billing';
import MobileToastManager from 'managers/mobileToast';

export const AppRootContainerId = 'AppRootContainerId1';

const AdminPage = lazy(() => import('./pages/admin'));

const portalParams = {
  element: (
    <RouteElementContainer>
      <CustomerPortal />
    </RouteElementContainer>
  ),
  errorElement: (
    <RouteElementContainer>
      <PageNotFound />
    </RouteElementContainer>
  ),
};
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RouteElementContainer>
        <Home />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/home',
    element: (
      <RouteElementContainer>
        <Home />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <AuthRequiredLayout>
        <RouteElementContainer>
          <Dashboard />
        </RouteElementContainer>
      </AuthRequiredLayout>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/store/customer/:customerUserId',
    element: (
      <AuthRequiredLayout>
        <RouteElementContainer>
          <CustomerView />
        </RouteElementContainer>
      </AuthRequiredLayout>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/account',
    element: (
      <AuthRequiredLayout>
        <RouteElementContainer>
          <Account />
        </RouteElementContainer>
      </AuthRequiredLayout>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/billing',
    element: (
      <AuthRequiredLayout>
        <RouteElementContainer>
          <Billing />
        </RouteElementContainer>
      </AuthRequiredLayout>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/terms-service',
    element: (
      <RouteElementContainer>
        <TermsService />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/privacy-policy',
    element: (
      <RouteElementContainer>
        <PrivacyPolicy />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/verify-email',
    element: (
      <RouteElementContainer>
        <VerifyEmail />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/reset-password/:tokenId',
    element: (
      <RouteElementContainer>
        <ResetPassword />
      </RouteElementContainer>
    ),
    errorElement: (
      <RouteElementContainer>
        <PageNotFound />
      </RouteElementContainer>
    ),
  },
  {
    path: '/admin',
    element: (
      <AuthRequiredLayout>
        <RouteElementContainer>
          <AdminPage />
        </RouteElementContainer>
      </AuthRequiredLayout>
    ),
  },

  // paths
  // /store/@storeNameOrIdOrHandle/
  // /portal/@storeNameOrIdOrHandle/ -> show rewards, info
  // /portal/qr/@storeNameOrIdOrHandle -> qr page
  // /portal/login/@storeNameOrIdOrHandle/ -> require email input, but store is selected
  // /portal -> no store selected
  // /portal/login -> require email input, no store selected
  {
    path: '/portal',
    ...portalParams,
  },
  {
    path: '/portal/:storeIdOrHandle',
    ...portalParams,
  },

  {
    path: '/portal/login',
    ...portalParams,
  },
  {
    path: '/portal/qr/:storeIdOrHandle',
    ...portalParams,
  },
  {
    path: '/portal/login/:storeIdOrHandle',
    ...portalParams,
  },
  // customer portal for card holders
  {
    path: '/portal/card-holders/:storeIdOrHandle',
    ...portalParams,
  },
]);

const App = () => {
  const containerRef = useRef();
  const { availableHeight, availableWidth } = useWindowResize();
  const { scrollY } = useWindowScroll();

  /**
   * Mobile screen scroll issue
   */
  useEffect(() => {
    if (isMobileScreen() && scrollY) {
      window.scrollTo(0, 0);
    }
  }, [scrollY, availableHeight]);

  /**
   * Apply font size
   */
  useEffect(() => {
    const apply = () => {
      try {
        const innerWidth = availableWidth;
        const BASE_FONT_SIZE = 16;
        const BASE_WIDTH_FONT_SIZE = 1536;
        const r = document.getElementById('root');
        const cal = Math.floor(
          innerWidth * (BASE_FONT_SIZE / BASE_WIDTH_FONT_SIZE)
        );
        let fontSizeVal = `${
          cal > 16 ? (innerWidth > 1900 ? 15.7 : 15.5) : cal
        }px`;

        if (!r) {
          return;
        } else if (innerWidth <= 910) {
          fontSizeVal = '14.2px';
        } else if (innerWidth <= 1480) {
          fontSizeVal = '14.6px';
        } else if (innerWidth <= 1200) {
          fontSizeVal = '14.2px';
        } else {
          fontSizeVal = '17px';
        }

        if (r) {
          r.style.fontSize = fontSizeVal;
        }

        if (document && document.body) {
          document.body.style.fontSize = fontSizeVal;
        }
      } catch (err) {
        console.log(`handleFontSize err ${err?.message}`);
      }
    };

    apply();
  }, [availableHeight, availableWidth]);

  /**
   * Redirect user to domain without 'www' subdomain
   */
  useEffect(() => {
    try {
      if (!isCapacitorMobileApp()) {
        const origin = toString(window.location.origin || '');

        if (
          origin &&
          (origin?.startsWith('www.') || origin?.startsWith('https://www.'))
        ) {
          // redirect
          window.location.replace('https://melonkarma.com/');
        }
      }
    } catch {}
  }, []);

  useEffect(() => {
    const dom = containerRef.current;
    const body = document.body;

    if (dom) {
      const newH = `${availableHeight}px`;
      dom.style.height = newH;

      if (isMobileScreen()) {
        // body.style.height = newH;
        // body.style.maxHeight = newH;
        dom.style.minHeight = newH;
        dom.style.maxHeight = newH;
        dom.style.overflow = 'hidden';
        dom.style.overflowY = 'scroll';
      } else if (dom?.style.overflow) {
        body.style.height = '';
        body.style.maxHeight = '';

        dom.style.minHeight = '';
        dom.style.maxHeight = '';
        dom.style.overflow = '';
        dom.style.overflowY = '';
      }
    }

    if (body) {
      if (!isMobileScreen()) {
        body.style.overflow = '';
      }
    }
  }, [availableHeight]);

  return (
    <Suspense>
      <NetworkManager>
        <ProfileManager router={router}>
          <StoresManager>
            <ModalManager router={router}>
              <MobileToastManager>
                <div
                  id={AppRootContainerId}
                  ref={containerRef}
                  className={cx(styles.container, {
                    // for ios mobile
                    [styles.container_ios]: isIosMobile(),
                    [styles.container_ios_mobile_app]: isCapacitorIos(),
                  })}
                >
                  <RouterProvider router={router} />
                </div>
              </MobileToastManager>
            </ModalManager>
          </StoresManager>
        </ProfileManager>
      </NetworkManager>
    </Suspense>
  );
};

export default App;
