import cx from 'classnames';
import styles from './DeleteStore.module.scss';
import { withStoreManagerSettings } from 'managers/stores';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { withModalManagerSettings } from 'managers/modal';
import { isFunction, trim } from 'lodash';
import { useState } from 'react';
import { deleteOwnedStoreRequest } from 'api/stores';
import { withUserProfileSettings } from 'managers/profile';
import { useAuth } from 'hooks';

const DeleteStore = (props) => {
  const {
    closeModal,
    removeStore,
    unBlockClosingOfModal,
    blockClosingOfModal,
    storeSelected,
  } = props;
  const { getAuthenticatedHeaders } = useAuth(props);

  const [submitted, setSubmitted] = useState(false);

  const toast = useToast({ position: 'top' });

  const confirm = async () => {
    try {
      if (submitted) {
        return;
      }

      setSubmitted(true);

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }
      const headers = getAuthenticatedHeaders();
      const storeId = trim(storeSelected?.id);

      if (!storeId) {
        if (isFunction(closeModal)) {
          closeModal(true);
        }
        return;
      }

      const { success, networkError, errorMessage } =
        await deleteOwnedStoreRequest(storeId, headers);

      if (success && !errorMessage) {
        if (isFunction(removeStore)) {
          removeStore(storeId);
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }

        toast({
          status: 'success',
          title: 'Successfully Deleted!',
          duration: 3_500,
          isClosable: true,
        });
      } else if (networkError) {
        toast({
          status: 'error',
          title: 'Check your network connection.',
          duration: 3_500,
        });
      } else if (errorMessage) {
        toast({
          status: 'error',
          title: 'Something went wrong. Try again',
          duration: 3_500,
        });
      }
    } catch {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  const cancel = () => {
    if (isFunction(closeModal)) {
      closeModal();
    }
  };

  return (
    <div className={styles.delete_store}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Confirm delete store profile?</p>
      </div>
      <div className={cx(styles.flex_center_all, styles.cta)}>
        {!submitted && (
          <Button
            height={'40px'}
            borderRadius={'6px'}
            variant={'ghost'}
            minWidth={'90px'}
            maxHeight={'140px'}
            background={'transparent'}
            className={cx(styles.cancel, styles.flex_center_all)}
            onClick={cancel}
          >
            <p>Cancel</p>
          </Button>
        )}

        {!submitted && (
          <Button
            height={'40px'}
            borderRadius={'6px'}
            minWidth={'90px'}
            maxHeight={'140px'}
            className={cx(styles.flex_center_all, styles.confirm)}
            variant={'ghost'}
            onClick={confirm}
            isLoading={submitted}
          >
            <p> Confirm</p>
          </Button>
        )}

        {submitted && (
          <div className={cx(styles.loading, styles.flex_center_all)}>
            <Spinner height={'24px'} width={'24px'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(DeleteStore))
);
