import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { isCapacitorMobileApp } from 'utils/helpers';
import ChakraUIWrapper from 'ChakraUIWrapper';

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<ChakraUIWrapper />);
};

if (window?.cordova || isCapacitorMobileApp()) {
  document.addEventListener(
    'deviceready',
    () => {
      renderReactDom();
    },
    false
  );
} else {
  renderReactDom();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
