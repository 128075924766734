import cx from 'classnames';
import styles from './DeleteReward.module.scss';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { withModalManagerSettings } from 'managers/modal';
import { isFunction } from 'lodash';
import { deleteStoreRewardRequest } from 'api/stores';
import { withStoreManagerSettings } from 'managers/stores';
import { useAuth } from 'hooks';
import { withUserProfileSettings } from 'managers/profile';
import { useState } from 'react';

const DeleteReward = (props) => {
  const {
    targetRewardInfo,
    closeModal,
    blockClosingOfModal,
    unBlockClosingOfModal,
    storeSelected,
    removeStoreRewardById,
  } = props;

  const { getAuthenticatedHeaders } = useAuth(props);
  const toast = useToast({ position: 'top' });
  const [submitted, setSubmitted] = useState(false);

  const confirm = async () => {
    if (!targetRewardInfo) {
      if (isFunction(closeModal)) {
        closeModal(true);
      }

      return;
    }

    if (submitted) {
      return;
    }

    try {
      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      setSubmitted(true);

      const rewardId = targetRewardInfo?.id;
      const storeId = storeSelected?.id;
      const headers = getAuthenticatedHeaders();
      const { success } = await deleteStoreRewardRequest(
        rewardId,
        storeId,
        headers
      );

      if (success) {
        if (isFunction(closeModal)) {
          closeModal(true);
        }

        if (isFunction(removeStoreRewardById)) {
          removeStoreRewardById(rewardId);
        }

        toast({
          status: 'success',
          title: 'Successfully Deleted!',
          duration: 2_500,
          isClosable: true,
        });
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong. Try again',
          duration: 1_500,
        });
        // error toast
      }
    } catch {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  };

  const cancel = () => {
    if (isFunction(closeModal)) {
      closeModal(true);
    }
  };

  return (
    <div className={styles.delete_reward}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Confirm delete reward?</p>
      </div>
      <div className={cx(styles.flex_center_all, styles.cta)}>
        {!submitted && (
          <Button
            height={'40px'}
            borderRadius={'6px'}
            variant={'ghost'}
            minWidth={'90px'}
            maxHeight={'140px'}
            background={'transparent'}
            className={cx(styles.cancel, styles.flex_center_all)}
            onClick={cancel}
          >
            <p>Cancel</p>
          </Button>
        )}

        {!submitted && (
          <Button
            height={'40px'}
            minWidth={'90px'}
            variant={'ghost'}
            borderRadius={'6px'}
            className={cx(styles.flex_center_all, styles.delete)}
            color="#fff"
            onClick={confirm}
            isLoading={submitted}
          >
            <p>Delete </p>
          </Button>
        )}

        {submitted && (
          <div className={cx(styles.flex_center_all, styles.loading)}>
            <Spinner height={'24px'} width={'24px'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(DeleteReward))
);
