import { withUserProfileSettings } from 'managers/profile';
import cx from 'classnames';
import styles from './Header.module.scss';
import HeaderLogo from './HeaderLogo';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { isFunction } from 'lodash';
import Login from 'components/login';
import SignUp from 'components/sign-up';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderHome = (props) => {
  const { setActiveModalDom, user, isLoggedIn, profileLoaded } = props;

  const navigate = useNavigate();

  const showLogin = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Login />);
    }
  };

  const showSignup = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<SignUp />);
    }
  };

  const userIsLoggedIn = useMemo(
    () => user?.storeOwner && isLoggedIn && profileLoaded,
    [user, isLoggedIn, profileLoaded]
  );

  const navigateToPricing = () => {
    window.location.replace(
      'https://robertespina.gumroad.com/l/melonkarmalifetimepremium'
    );
  };

  return (
    <div className={cx(styles.flex_center_all, styles.header_home)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={styles.left}>
          <HeaderLogo />
        </div>
        <div className={cx(styles.flex_center_all, styles.right)}>
          <Button
            background={'transparent'}
            backgroundColor={'transparent'}
            variant={'ghost'}
            height={'50px'}
            color="#fff"
            className={cx(styles.flex_center_all, styles.pricing)}
            onClick={navigateToPricing}
          >
            <p>Pricing</p>
          </Button>

          {!userIsLoggedIn && (
            <>
              <Button
                background={'transparent'}
                backgroundColor={'transparent'}
                variant={'ghost'}
                height={'50px'}
                color="#fff"
                className={cx(styles.flex_center_all, styles.login)}
                onClick={showLogin}
              >
                <p>Log in</p>
              </Button>
              <Button
                height={'50px'}
                minWidth={'180px'}
                variant={'ghost'}
                borderRadius={'100px'}
                background={primaryGreenColor}
                backgroundColor={primaryGreenColor}
                color="#fff"
                className={cx(styles.flex_center_all, styles.signup)}
                onClick={showSignup}
              >
                <p>Sign up for free</p>
              </Button>
            </>
          )}

          {userIsLoggedIn && (
            <Button
              height={'50px'}
              minWidth={'180px'}
              variant={'ghost'}
              borderRadius={'100px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              color="#fff"
              className={cx(styles.flex_center_all, styles.to_dashboard)}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <p>Go to dashboard</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(withModalManagerSettings(HeaderHome));
