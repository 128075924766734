import cx from 'classnames';
import styles from './Billing.module.scss';
import Header from 'pages/dashboard/Header';
import { useMemo } from 'react';
import { withUserProfileSettings } from 'managers/profile';
import { Spinner } from '@chakra-ui/react';
import { planTypePremiumStore } from 'constants';

const Billing = (props) => {
  const { user, profileLoaded } = props;

  const isLoading = useMemo(
    () => !user?.plan || !profileLoaded,
    [profileLoaded, user]
  );

  const isUserPremium = useMemo(
    () => user?.plan?.type === planTypePremiumStore,
    [user]
  );

  return (
    <div className={cx(styles.flex_center_all, styles.billing_page)}>
      <Header />
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.label)}>
          <p>Billing </p>
        </div>

        {isLoading && (
          <div className={cx(styles.loading, styles.flex_center_all)}>
            <Spinner height={'20px'} width={'20px'} />
          </div>
        )}

        {!isLoading && (
          <div className={cx(styles.card, styles.flex_center_all)}>
            <div className={cx(styles.flex_center_all, styles.info)}>
              <div className={cx(styles.flex_center_all, styles.texts)}>
                <div className={cx(styles.flex_center_all, styles.title)}>
                  <p>{isUserPremium ? 'Premium Plan' : 'Free Plan'}</p>
                  {user?.plan?.lifetime && (
                    <p className={cx(styles.flex_center_all, styles.lifetime)}>
                      Lifetime Access
                    </p>
                  )}
                </div>
                <p className={styles.description}>
                  {isUserPremium
                    ? 'You have access to all premium features.'
                    : "You're on free plan."}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(Billing);
