import cx from 'classnames';
import styles from './GenerateUserQr.module.scss';
import CreateQRCardHolder from 'components/create-qr-card-holder';
import GeneratedUserQrItem from './GeneratedUserQrItem';
import { Button } from '@chakra-ui/react';
import { withStoreManagerSettings } from 'managers/stores';
import { primaryGreenColor } from 'constants';
import { AddIcon } from '@chakra-ui/icons';
import { withModalManagerSettings } from 'managers/modal';
import { filter, includes, isEmpty, isFunction, last, map, size } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { withUserProfileSettings } from 'managers/profile';
import { getStoreCreatedUserQRForCardHoldersByPageRequest } from 'api/storeQR';
import { useAuth } from 'hooks';
import { isMobileScreen } from 'utils/helpers';
import { withMobileToastSettings } from 'managers/mobileToast';

const GenerateUserQR = (props) => {
  const {
    setActiveModalDom,
    setActiveDomForMobileToast,
    storeSelected,
    profileLoaded,
  } = props;

  const [generatedQRList, setGeneratedQRList] = useState([]);
  const storeId = useMemo(() => storeSelected?.id, [storeSelected?.id]);
  const [firstFetchDone, setFirstFetchDone] = useState(false);
  const [fetchingList, setFetchingList] = useState(false);
  const { getAuthenticatedHeaders } = useAuth(props);

  const getNextPageList = async () => {
    if (fetchingList) {
      return;
    }

    try {
      setFetchingList(true);
      const startAfterId =
        size(generatedQRList) >= 20 ? last(generatedQRList)?.id : '';
      const { errorMessage, networkError, list } =
        await getStoreCreatedUserQRForCardHoldersByPageRequest(
          storeId,
          startAfterId,
          getAuthenticatedHeaders()
        );

      if (!errorMessage && !networkError) {
        const currentIds = filter(map(generatedQRList, (qrData) => qrData?.id));
        const newList = filter(
          list,
          (qrData) => !isEmpty(qrData) && !includes(currentIds, qrData?.id)
        );

        setGeneratedQRList([...generatedQRList, ...newList]);
      }
    } catch (err) {
      console.log('getNextPageList() err:', err?.message);
    } finally {
      setFetchingList(false);
    }
  };

  const getLatestList = useCallback(async () => {
    if (fetchingList) {
      return;
    }

    setFirstFetchDone(true);
    setFetchingList(true);
    const { errorMessage, networkError, list } =
      await getStoreCreatedUserQRForCardHoldersByPageRequest(
        storeId,
        '',
        getAuthenticatedHeaders()
      );

    if (!errorMessage && !networkError) {
      setGeneratedQRList([...list]);
    }

    setFetchingList(false);
  }, [getAuthenticatedHeaders, fetchingList, storeId]);

  const onSuccessCreate = async (props) => {
    if (!isEmpty(props)) {
      // get first page
      // descending order time created
      await getLatestList();
    }
  };

  /**
   * Show form
   */
  const showForm = () => {
    if (isMobileScreen() && isFunction(setActiveModalDom)) {
      setActiveDomForMobileToast(
        <CreateQRCardHolder onCreateSuccessCallback={onSuccessCreate} />
      );
    }

    if (!isMobileScreen() && isFunction(setActiveModalDom)) {
      setActiveModalDom(
        <CreateQRCardHolder onCreateSuccessCallback={onSuccessCreate} />
      );
    }
  };

  /**
   * Init fetch first page
   */
  useEffect(() => {
    if (profileLoaded && !firstFetchDone) {
      getLatestList();
    }
  }, [
    profileLoaded,
    firstFetchDone,
    storeId,
    getLatestList,
    getAuthenticatedHeaders,
  ]);

  const hasNextPage = useMemo(() => {
    const currentSize = size(generatedQRList);

    // 20 list per page
    if (currentSize >= 20 && currentSize % 20 === 0) {
      return true;
    }

    return false;
  }, [generatedQRList]);

  // create user id and reserve user id on db
  // attach store id to user id
  return (
    <div className={styles.generate_user_qr}>
      <div className={styles.label}>
        <h1> Generate QR for user card holder.</h1>
      </div>
      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.create_new_button)}
          color="#fff"
          onClick={showForm}
        >
          <AddIcon />
          <p>Create</p>
        </Button>
      </div>

      <div className={styles.generated_list}>
        <div className={styles.generated_list_label}>
          <h2> History of generated card holders.</h2>
        </div>

        {isEmpty(generatedQRList) && !fetchingList && (
          <div
            className={cx(styles.flex_center_all, styles.generated_list_empty)}
          >
            <p>Empty list.</p>
          </div>
        )}

        {!isEmpty(generatedQRList) && (
          <ul>
            {map(generatedQRList, (qrData) => {
              const key = `GeneratedUserQRItem${qrData?.id}`;
              return (
                <li key={key}>
                  <GeneratedUserQrItem qrData={qrData} />
                </li>
              );
            })}
          </ul>
        )}
        {hasNextPage && (
          <div className={cx(styles.flex_center_all, styles.show_more_list)}>
            <Button
              height={'50px'}
              minWidth={'180px'}
              variant={'ghost'}
              borderRadius={'100px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              className={cx(styles.flex_center_all, styles.show_more_button)}
              color="#fff"
              onClick={getNextPageList}
            >
              <p>Show more</p>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(
    withModalManagerSettings(withMobileToastSettings(GenerateUserQR))
  )
);
