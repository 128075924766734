import cx from 'classnames';
import styles from './NotFound.module.scss';

const PageNotFound = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.not_found)}>
      <div className={cx(styles.content, styles.flex_center_all)}>
        <h1> 404</h1>
        <p>Sorry, we can't seem to find this page.</p>
      </div>
    </div>
  );
};
export default PageNotFound;
