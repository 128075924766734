const HomeGradientIcon1 = (p) => {
  return (
    <svg
      width="727"
      height="802"
      viewBox="0 0 727 802"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g filter="url(#filter0_f_1_2763)">
        <path
          d="M494.564 304.781C574.189 431.458 628.451 799.256 497.2 587.027C365.948 374.799 493.864 621.604 322.742 593.23C151.62 564.856 103.514 234.201 200.04 173.528C296.565 112.855 414.939 178.105 494.564 304.781Z"
          fill="#1DBF73"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1_2763"
          x="0.889648"
          y="0.60498"
          width="725.464"
          height="801.037"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="75"
            result="effect1_foregroundBlur_1_2763"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomeGradientIcon1;
