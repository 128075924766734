import cx from 'classnames';
import styles from './Footer.module.scss';

export const storePortalFooterDomId = 'storePortalFooterDomId';

const Footer = () => {
  return (
    <div
      id={storePortalFooterDomId}
      className={cx(styles.flex_center_all, styles.footer)}
    >
      <div className={styles.content}>
        <div className={cx(styles.flex_center_all, styles.claim)}>
          <p>
            Powered by <span>Melonkarma</span>{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
