import { ChakraProvider } from '@chakra-ui/react';
// 1. import `ChakraProvider` component
import App from 'App';

// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
import chakraTheme from '@chakra-ui/theme';

const ChakraUIWrapper = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <App />
    </ChakraProvider>
  );
};

export default ChakraUIWrapper;
