import { withStoreManagerSettings } from 'managers/stores';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { AppRootContainerId } from 'App';
import cx from 'classnames';
import styles from './Dashboard.module.scss';
import Header from './Header';
import StoreInfo from './StoreInfo';
import Panels from './Panels';

const Dashboard = (props) => {
  const { storeSelected } = props;

  // scroll to top on mount
  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    window.scrollTo(0, 0);

    if (dom?.scrollTo) {
      dom.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={styles.dashboard}>
      <Header />
      <div className={cx(styles.flex_center_all, styles.content)}>
        <StoreInfo />
        {!isEmpty(storeSelected) && <Panels />}
      </div>
    </div>
  );
};

export default withStoreManagerSettings(Dashboard);
