import cx from 'classnames';
import styles from './Toast.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isIosMobile, timeout } from 'utils/helpers';
import { isEmpty, isFunction } from 'lodash';
import { IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useWindowResize } from 'hooks';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { withUserProfileSettings } from './profile';

const Toast = (props) => {
  const { close, dom, blockClosingMobileToast } = props;
  const containerDom = useRef(null);
  const [show, setShow] = useState(true);
  const { availableHeight } = useWindowResize(props);

  const onClose = useCallback(async () => {
    if (blockClosingMobileToast) {
      return;
    }

    setShow(false);
    await timeout(250);

    if (isFunction(close)) {
      close();
    }
  }, [close, blockClosingMobileToast]);

  useEffect(() => {
    clearAllBodyScrollLocks();

    return clearAllBodyScrollLocks;
  }, []);

  /**
   * Apply container height
   */
  useEffect(() => {
    const dom = containerDom?.current;

    if (dom) {
      const newH = `${availableHeight}px`;
      dom.style.height = newH;

      if (isIosMobile() && window?.scrollY) {
        window.scrollTo(0, 0);
      }
    }
  }, [availableHeight]);

  /**
   * Close on outside click/press
   */
  useEffect(() => {
    const onClick = (evt) => {
      const target = evt?.target;

      if (
        !isEmpty(target?.classList) &&
        target?.classList?.contains(styles.toast)
      ) {
        onClose();
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [show, close, onClose]);

  return (
    <div
      ref={containerDom}
      className={cx(styles.toast, styles.flex_center_all)}
    >
      <div
        className={cx(styles.content, {
          [styles.show]: show,
          [styles.hide]: !show,
        })}
      >
        <div className={cx(styles.flex_center_all, styles.header)}>
          <IconButton
            className={cx(styles.close, {
              [styles.hide_dom]: blockClosingMobileToast,
            })}
            variant="solid"
            background="transparent"
            aria-label="Close toast"
            icon={<CloseIcon height={3} width={3} />}
            onClick={() => onClose()}
          />
        </div>
        <div className={styles.content_dom}>{dom || null}</div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(Toast);
