import cx from 'classnames';
import styles from './SignUp.module.scss';
import Login from 'components/login';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';
import { useEffect, useMemo, useState } from 'react';
import {
  includes,
  isArray,
  isEmpty,
  isFunction,
  size,
  toLower,
  toString,
} from 'lodash';
import { Button, useToast } from '@chakra-ui/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { EyeCloseIcon, EyeIcon } from 'icons';
import { primaryGreenColor } from 'constants';
import { Checkbox } from '@chakra-ui/react';
import { Browser } from '@capacitor/browser';
import { withModalManagerSettings } from 'managers/modal';
import {
  isCapacitorMobileApp,
  isDev,
  isMobileScreen,
  isSafeEmailAddress,
  timeout,
} from 'utils/helpers';
import { sendOwnerSignupRequest } from 'api';
import { minBusinessName } from 'constants';
import { minName } from 'constants';
import { minPassword } from 'constants';
import { maxPassword } from 'constants';
import { maxEmail } from 'constants';
import { maxName } from 'constants';
import { maxBusinessName } from 'constants';

const SignUp = (props) => {
  const {
    setActiveModalDom,
    closeModal,
    targetBusinessName = '',
    consumeUserForLogin,
    blockClosingOfModal,
    unBlockClosingOfModal,
    profile,
    consumeStoresInfo,
    browserRouter,
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [businessName, setBusinessName] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const toast = useToast({ position: 'top' });

  const navigate = useMemo(() => browserRouter?.navigate, [browserRouter]);

  const submit = async () => {
    try {
      if (submitted) {
        return;
      }

      if (!isDev()) {
        // block prod sign up for now
        // setErrorMessage(
        //   'Not yet available. Reach us out via facebook.com/melonkarma'
        // );
        // toast({
        //   status: 'error',
        //   title: 'Unavailable.',
        //   duration: 4_500,
        //   isClosable: true,
        // });
        // setSubmitted(false);
        // return;
      }

      if (!agreeTerms) {
        setErrorMessage(
          'You must agree to our Terms of Service and Privacy Policy.'
        );

        return;
      }

      if (!isSafeEmailAddress(email)) {
        setErrorMessage('Invalid email address.');
        return;
      }

      if (includes(businessName, '.')) {
        setErrorMessage("Business name should not contain '.'(dot) character");
        return;
      }

      if (includes(fullName, '.')) {
        setErrorMessage("Account name should not contain '.'(dot) character");
        return;
      }

      if (size(businessName) < minBusinessName) {
        setErrorMessage(
          `Business name should at least contain ${minBusinessName} characters.`
        );
        return;
      }

      if (size(fullName) < minName) {
        setErrorMessage(
          `Full name should at least contain ${minName} characters.`
        );
        return;
      }

      if (size(password) < minPassword) {
        setErrorMessage(
          `Account password should at least contain ${minPassword} characters.`
        );

        return;
      }

      setSubmitted(true);
      setErrorMessage('');

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      // cityOrRegion
      const { user, token, stores, networkError, errorMessage } =
        await sendOwnerSignupRequest(
          email,
          fullName,
          password,
          businessName,
          profile?.country || '',
          profile?.region || '',
          ''
        );

      if (networkError) {
        toast({
          title: 'Check your network connection.',
          status: 'error',
          duration: 3_500,
          isClosable: true,
        });
      } else if (errorMessage) {
        const sanitizedErrorMessage = toLower(errorMessage);

        if (
          (includes(sanitizedErrorMessage, 'email') &&
            includes(sanitizedErrorMessage, 'taken')) ||
          includes(sanitizedErrorMessage, 'email already taken')
        ) {
          setErrorMessage('Email is already taken');

          if (isMobileScreen()) {
            toast({
              status: 'error',
              duration: 2_500,
              isClosable: true,
              title: 'Email is already taken',
            });
          }
        } else {
          setErrorMessage('Something went wrong.');
        }
      } else if (!isEmpty(user)) {
        if (isFunction(consumeUserForLogin)) {
          consumeUserForLogin(token, user);
        }

        if (
          isArray(stores) &&
          !isEmpty(stores) &&
          isFunction(consumeStoresInfo)
        ) {
          consumeStoresInfo(stores);
        }

        if (isFunction(closeModal)) {
          closeModal(true);
        }

        toast({
          title: 'Successfully Created!',
          status: 'success',
          duration: 4_500,
          isClosable: true,
        });

        await timeout(400);
        navigate('/dashboard');
      }
    } catch (err) {
    } finally {
      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }

      setSubmitted(false);
    }
  };

  const showLogin = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Login />);
    }
  };

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  useEffect(() => {
    setBusinessName(toString(targetBusinessName));
  }, [targetBusinessName]);

  return (
    <div className={cx(styles.flex_center_all, styles.signup)}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Create your account</p>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Business name</p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="Your business name"
          value={businessName}
          onChange={(evt) => {
            setBusinessName(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          maxLength={maxBusinessName}
          type="text"
          aria-label="Your business name for your new account"
        />
        <div
          className={cx(styles.flex_center_all, styles.input_right_icon, {
            [styles.hide_dom]: isEmpty(businessName),
          })}
        >
          <Button
            variant={'unstyled'}
            aria-label="Clear business name input"
            className={cx(styles.flex_center_all, styles.clear)}
            onClick={() => {
              setBusinessName('');
              clearErrorMessage();
            }}
          >
            <Cross2Icon height={'18px'} width={'18px'} />
          </Button>
        </div>
      </div>

      <div className={cx(styles.flex_center_all, styles.tip)}>
        <p>Don't worry you can edit this later.</p>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Full name </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="Your name here"
          value={fullName}
          onChange={(evt) => {
            setFullName(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          maxLength={maxName}
          type="text"
          aria-label="Your name for your account"
        />
        <div
          className={cx(styles.flex_center_all, styles.input_right_icon, {
            [styles.hide_dom]: isEmpty(fullName),
          })}
        >
          <Button
            variant={'unstyled'}
            aria-label="Clear name input"
            className={cx(styles.flex_center_all, styles.clear)}
            onClick={() => {
              setFullName('');
              clearErrorMessage();
            }}
          >
            <Cross2Icon height={'18px'} width={'18px'} />
          </Button>
        </div>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Email </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="youremail@domain.com"
          value={email}
          onChange={(evt) => {
            setEmail(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          maxLength={maxEmail}
          type="text"
          aria-label="Your email for your new account"
        />
        <div
          className={cx(styles.flex_center_all, styles.input_right_icon, {
            [styles.hide_dom]: isEmpty(email),
          })}
        >
          <Button
            variant={'unstyled'}
            aria-label="Clear email input"
            className={cx(styles.flex_center_all, styles.clear)}
            onClick={() => {
              setEmail('');
              clearErrorMessage();
            }}
          >
            <Cross2Icon height={'18px'} width={'18px'} />
          </Button>
        </div>
      </div>

      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Password </p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="******"
          value={password}
          onChange={(evt) => {
            setPassword(toString(evt?.target?.value || ''));
            clearErrorMessage();
          }}
          maxLength={maxPassword}
          type={showPassword ? 'text' : 'password'}
          aria-label="Your password for your new account"
        />
        <div className={cx(styles.flex_center_all, styles.input_right_icon)}>
          <Button
            variant={'unstyled'}
            aria-label="Show password"
            className={cx(styles.flex_center_all, styles.toggle_show)}
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <EyeIcon />
            ) : (
              <EyeCloseIcon height={'18px'} width={'18px'} />
            )}
          </Button>
        </div>
      </div>

      <div className={cx(styles.flex_center_all, styles.terms)}>
        <Checkbox
          size="lg"
          colorScheme="green"
          defaultChecked={false}
          checked={agreeTerms}
          onChange={() => {
            setAgreeTerms(!agreeTerms);

            if (errorMessage) {
              clearErrorMessage();
            }
          }}
        >
          <p className={styles.terms_text}>
            By creating an account you agree with{' '}
            <span
              onClick={() => {
                if (isCapacitorMobileApp()) {
                  Browser.open({
                    url: 'https://melonkarma.com/privacy-policy',
                  });
                } else {
                  browserRouter?.navigate('/privacy-policy');

                  if (isFunction(closeModal)) {
                    closeModal();
                  }
                }
              }}
            >
              Privacy Policy
            </span>{' '}
            and
            <span
              onClick={() => {
                if (isCapacitorMobileApp()) {
                  Browser.open({ url: 'https://melonkarma.com/terms-service' });
                } else {
                  browserRouter?.navigate('/terms-service');

                  if (isFunction(closeModal)) {
                    closeModal();
                  }
                }
              }}
            >
              {' '}
              Terms of Service.
            </span>
          </p>
        </Checkbox>
      </div>

      <div className={cx(styles.error, styles.flex_center_all)}>
        <p> {errorMessage}</p>
      </div>

      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted}
        >
          <p>Submit</p>
        </Button>

        <div className={cx(styles.flex_center_all, styles.login)}>
          <p>Already have have an account?</p>
          <Button
            variant={'unstyled'}
            className={styles.flex_center_all}
            background={'transparent'}
            aria-label="Log in instead"
            onClick={showLogin}
          >
            <p>Log in</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(SignUp))
);
