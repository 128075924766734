import cx from 'classnames';
import styles from './NoStoreSelected.module.scss';

const NoStoreSelected = (props) => {
  const { forStoreOwner } = props;

  return (
    <div className={cx(styles.flex_center_all, styles.nostoreselected)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <h1>
          {forStoreOwner
            ? 'Pick a store to modify.'
            : 'Pick a store to check your points.'}
        </h1>
        <p>
          {forStoreOwner
            ? 'Create your first store.'
            : "Make sure you have already visited the business you're looking for."}
        </p>
      </div>
    </div>
  );
};

export default NoStoreSelected;
