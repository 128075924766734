import { AppRootContainerId } from 'App';
import { isNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState(window?.scrollY || 0);
  const [totallyScrolledBottom, setTotallyScrolledBottom] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isBottom = scrollTop + windowHeight >= documentHeight;
      setScrollY(isNumber(window?.scrollY) ? window.scrollY : 0);
      setTotallyScrolledBottom(!!isBottom);
    };

    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [setScrollY]);

  /**
   * Scroll to top when landing this page
   */
  const scrollUpMostTop = useCallback(() => {
    try {
      const dom = document.getElementById(AppRootContainerId);

      if (dom) {
        dom.scrollTo(0, 0);
      }

      window.scrollTo(0, 0);
    } catch {}
  }, []);

  return {
    scrollUpMostTop,
    totallyScrolledBottom,
    scrollY,
  };
};

export default useWindowScroll;
