import cx from 'classnames';
import styles from './Panels.module.scss';
import Notifications from './panels/Notifications';
import Customers from './panels/Customers';
import Settings from './panels/Settings';
import Rewards from './panels/Rewards';
import Analytics from './panels/Analytics';
import QRCodes from './panels/QRCodes';
import { withStoreManagerSettings } from 'managers/stores';
import { includes, isEmpty } from 'lodash';
import { useState, Suspense } from 'react';
import { withUserProfileSettings } from 'managers/profile';
import { useSearchParams } from 'react-router-dom';
import { useRouter } from 'hooks';

export const notificationPanel = 'notificationPanel';
export const customerPanel = 'customerPanel';
export const teamPanel = 'teamPanel';
export const settingsPanel = 'settingsPanel';
export const rewardsPanel = 'rewardsPanel';
export const analyticsPanel = 'analyticsPanel';
export const qrCodesPanel = 'qrCodesPanel';

export const validPanels = [
  notificationPanel,
  customerPanel,
  teamPanel,
  settingsPanel,
  rewardsPanel,
  analyticsPanel,
  qrCodesPanel,
];

const Panels = (props) => {
  const { storeSelected } = props;

  const [searchParams] = useSearchParams();
  const [activePanel, setActivePanel] = useState(
    includes(validPanels, searchParams?.get('panel'))
      ? searchParams?.get('panel')
      : notificationPanel
  );

  const { setQuery } = useRouter();

  const selectPanel = (type = '') => {
    if (!isEmpty(type) && type !== activePanel) {
      setActivePanel(type);
    }
  };

  return (
    <Suspense>
      <div
        className={cx(styles.flex_center_all, styles.panels, {
          [styles.hide_dom]: !storeSelected || isEmpty(storeSelected),
        })}
      >
        <div className={styles.content}>
          <div className={cx(styles.flex_center_all, styles.menu_bar)}>
            <ul>
              <li
                className={cx({
                  [styles.menu_active]: activePanel === notificationPanel,
                  [styles.menu_inactive]: activePanel !== notificationPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(notificationPanel);
                    setQuery('panel', notificationPanel);
                  }}
                >
                  <p>Notifications</p>
                </button>
              </li>
              <li
                className={cx({
                  [styles.menu_active]: activePanel === customerPanel,
                  [styles.menu_inactive]: activePanel !== customerPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(customerPanel);
                    setQuery('panel', customerPanel);
                  }}
                >
                  <p>Customers</p>
                </button>
              </li>

              <li
                className={cx({
                  [styles.menu_active]: activePanel === qrCodesPanel,
                  [styles.menu_inactive]: activePanel !== qrCodesPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(qrCodesPanel);
                    setQuery('panel', qrCodesPanel);
                  }}
                >
                  <p>QR Codes</p>
                </button>
              </li>

              <li
                className={cx({
                  [styles.menu_active]: activePanel === rewardsPanel,
                  [styles.menu_inactive]: activePanel !== rewardsPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(rewardsPanel);
                    setQuery('panel', rewardsPanel);
                  }}
                  className={styles.flex_center_all}
                >
                  <p>Rewards</p>
                </button>
              </li>

              <li
                className={cx({
                  [styles.menu_active]: activePanel === analyticsPanel,
                  [styles.menu_inactive]: activePanel !== analyticsPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(analyticsPanel);
                    setQuery('panel', analyticsPanel);
                  }}
                  className={styles.flex_center_all}
                >
                  <p>Analytics </p>
                </button>
              </li>

              <li
                className={cx({
                  [styles.menu_active]: activePanel === settingsPanel,
                  [styles.menu_inactive]: activePanel !== settingsPanel,
                })}
              >
                <button
                  onClick={() => {
                    selectPanel(settingsPanel);
                    setQuery('panel', settingsPanel);
                  }}
                >
                  <p>Settings</p>
                </button>
              </li>
            </ul>
          </div>

          <div className={styles.dynamic_content}>
            {activePanel === notificationPanel && <Notifications />}
            {activePanel === customerPanel && <Customers />}
            {activePanel === settingsPanel && <Settings />}
            {activePanel === rewardsPanel && <Rewards />}
            {activePanel === analyticsPanel && <Analytics />}
            {activePanel === qrCodesPanel && <QRCodes />}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(Panels));
