import Header from 'pages/dashboard/Header';
import cx from 'classnames';
import styles from './Account.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty, isFunction, isString, size, toLower, toString } from 'lodash';
import Security from './Security';
import { maxEmail } from 'constants';
import { maxName } from 'constants';
import { maxHandle } from 'constants';
import { primaryGreenColor } from 'constants';
import { Button, useToast } from '@chakra-ui/react';
import { useAuth } from 'hooks';
import { updateUserAccountInfoRequest } from 'api';
import { isCorrectEmailFormat, isSafeEmailAddress } from 'utils/helpers';
import { minHandle } from 'constants';
import { minName } from 'constants';
import { withUserProfileSettings } from 'managers/profile';
import { AppRootContainerId } from 'App';

const AccountSettings = (props) => {
  const { user, updateUserProps } = props;

  const [newEmail, setNewEmail] = useState(user?.email || '');
  const [newFullName, setNewFullName] = useState(user?.fullName || '');
  const [newHandle, setNewHandle] = useState(user?.handle || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // update email
  useEffect(() => {
    if (isString(user?.email) && !isEmpty(user?.email)) {
      setNewEmail(user?.email);
    }
  }, [user?.email]);

  // update full name
  useEffect(() => {
    if (isString(user?.fullName) && !isEmpty(user?.fullName)) {
      setNewFullName(user?.fullName);
    }
  }, [user?.fullName]);

  // update account
  useEffect(() => {
    if (isString(user?.handle) && !isEmpty(user?.handle)) {
      setNewHandle(user?.handle);
    }
  }, [user?.handle]);

  // scroll to top on mount
  useEffect(() => {
    try {
      const dom = document.getElementById(AppRootContainerId);

      if (window?.scrollTo) {
        window.scrollTo(0, 0);
      }

      if (dom?.scrollTo) {
        dom.scrollTo(0, 0);
      }
    } catch {}
  }, []);

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({
    position: 'top',
    duration: 3_000,
    isClosable: true,
  });

  const withPropertyToUpdate = useMemo(() => {
    return (
      user?.email !== newEmail ||
      user?.handle !== newHandle ||
      user?.fullName !== newFullName
    );
  }, [user, newEmail, newHandle, newFullName]);

  const clearErrorMessage = () => {
    if (!errorMessage) {
      return;
    }

    setErrorMessage('');
  };

  const updateAccountProperty = async () => {
    try {
      if (!withPropertyToUpdate) {
        toast({ status: 'error', title: 'Nothing to update', duration: 2_000 });
        return;
      }

      const headers = getAuthenticatedHeaders();
      const updatingFullName = user?.fullName !== newFullName;
      const updatingHandle = user?.handle !== newHandle;
      const updatingEmail = user?.email !== newEmail;

      let params = {};

      if (!isSafeEmailAddress(newEmail) || !isCorrectEmailFormat(newEmail)) {
        setNewEmail(user?.email);
        setErrorMessage('Invalid email address');
        return;
      } else if (updatingEmail) {
        params = { email: newEmail };
      }

      if (updatingHandle && size(newHandle) < minHandle) {
        setErrorMessage(
          `Account handle must at least contain ${minHandle} characters`
        );
        setNewHandle(user?.handle);

        return;
      } else if (updatingHandle) {
        params = { ...params, handle: newHandle };
      }

      if (updatingFullName && size(newFullName) < minName) {
        setErrorMessage(
          `Account name must at least contain ${minName} characters`
        );
        setNewFullName(user?.fullName);
        return;
      } else if (updatingFullName) {
        params = { ...params, fullName: newFullName };
      }

      setSubmitted(true);

      const {
        success,
        user: updatedUser,
        errorMessage,
        networkError,
      } = await updateUserAccountInfoRequest(headers, params);
      const sanitizedErrorMessage = toLower(errorMessage);

      if (success) {
        toast({
          status: 'success',
          title: 'Successfull updated!',
          duration: 3_000,
          isClosable: true,
        });

        if (isFunction(updateUserProps)) {
          updateUserProps(updatedUser);
        }
      } else if (networkError) {
        toast({ status: 'error', title: 'Check your network connection.' });
      } else if (
        sanitizedErrorMessage?.includes('taken') &&
        sanitizedErrorMessage?.includes('email')
      ) {
        setErrorMessage('Email is already taken.');
      } else if (
        sanitizedErrorMessage?.includes('taken') &&
        sanitizedErrorMessage?.includes('handle')
      ) {
        setErrorMessage('Handle is already taken.');
      } else {
        setErrorMessage('Something went wrong');
      }
    } catch {
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.account_settings)}>
      <Header />
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={styles.title}>
          <p>Account Settings</p>
        </div>
        <div className={cx(styles.label_profile, styles.label)}>
          <p>Profile </p>
        </div>
        <section className={styles.inputs}>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label,
              styles.input_label_account
            )}
          >
            <p>Email </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="youremail@domain.com"
              value={newEmail}
              onChange={(evt) => {
                setNewEmail(toString(evt?.target?.value || ''));
                clearErrorMessage();
              }}
              type="text"
              maxLength={maxEmail}
              aria-label="Your email for login"
            />
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label,
              styles.input_label_account
            )}
          >
            <p>Full name </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="Your account's name here"
              value={newFullName}
              onChange={(evt) => {
                setNewFullName(toString(evt?.target?.value || ''));
                clearErrorMessage();
              }}
              maxLength={maxName}
              type="text"
              aria-label="Edit your account name"
            />
          </div>{' '}
          <div
            className={cx(
              styles.flex_center_all,
              styles.input_label_margin,
              styles.input_label,
              styles.input_label_account
            )}
          >
            <p>Handle </p>
          </div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_margin
            )}
          >
            <input
              placeholder="Your account handle here"
              value={newHandle}
              onChange={(evt) => {
                setNewHandle(toString(evt?.target?.value || ''));
                clearErrorMessage();
              }}
              maxLength={maxHandle}
              type="text"
              aria-label="Edit your account handle"
            />
          </div>
          <div className={cx(styles.error, styles.flex_center_all)}>
            <p> {errorMessage}</p>
          </div>
          <div className={cx(styles.flex_center_all, styles.cta)}>
            <Button
              height={'50px'}
              minWidth={'180px'}
              variant={'ghost'}
              borderRadius={'100px'}
              background={primaryGreenColor}
              backgroundColor={primaryGreenColor}
              className={cx(styles.flex_center_all, styles.submit)}
              color="#fff"
              onClick={updateAccountProperty}
              isLoading={submitted}
            >
              <p>Update </p>
            </Button>
          </div>
        </section>

        <div className={cx(styles.label_security, styles.label)}>
          <p>Security </p>
        </div>

        <section className={styles.inputs}>
          <Security />
        </section>
      </div>
    </div>
  );
};

export default withUserProfileSettings(AccountSettings);
