import cx from 'classnames';
import styles from './StoreContent.module.scss';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { withStoreManagerSettings } from 'managers/stores';
import { withUserProfileSettings } from 'managers/profile';
import { isEmpty, isObject, last, map, size } from 'lodash';
import StoreReward from 'components/store-reward';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { DownloadIcon, FileTextIcon } from '@radix-ui/react-icons';
import { downloadURI } from 'utils/helpers';
import { storePortalHeaderCopyLinkBtnId } from './Header';
import { storePortalFooterDomId } from './Footer';

const QRContentDomId = 'QRContentDomId';

export const storePortalRewardsDomId = 'storePortalRewardsDomId';
export const storeSaveQrAsImageBtnId = 'storeSaveQrAsImageBtnId';
export const storePrintQrPageBtnId = 'storePrintQrPageBtnId';

const StoreContent = (props) => {
  const { fetchingTargetStoreInfo, user, customer, visitedStoreSelected } =
    props;
  const location = useLocation();
  const doneQR = useRef(false);
  const qrCodeInstance = useRef(null);

  const targetCustomerInfo = useMemo(
    () => (!isEmpty(customer) && isObject(customer) ? customer : user),
    [user, customer]
  );

  const visitedStoreId = useMemo(
    () => visitedStoreSelected?.id || '',
    [visitedStoreSelected]
  );

  const fullUrl = useMemo(() => {
    let origin = window.location.origin;

    if (last(origin) === '/') {
      origin = origin.substring(0, size(origin) - 1);
    }

    return `${origin}/portal/login/${visitedStoreId}?scanned=true`;
  }, [visitedStoreId]);

  const showRewards = useMemo(
    () =>
      // must be not from QR page
      !location?.pathname?.startsWith('/portal/qr') &&
      ((location?.pathname?.startsWith('/portal') &&
        !location?.pathname?.startsWith('/portal/login') &&
        !isEmpty(targetCustomerInfo)) ||
        (location?.pathname?.startsWith('/portal/login') &&
          !isEmpty(customer))),
    [customer, targetCustomerInfo, location]
  );

  const showQR = useMemo(
    () => location?.pathname?.startsWith('/portal/qr'),
    [location]
  );

  const isStoreOwner = useMemo(
    () => !isEmpty(user?.id) && visitedStoreSelected?.owner === user?.id,
    [user?.id, visitedStoreSelected]
  );

  useEffect(() => {
    const QRCode = window.QRCode;

    if (
      !fullUrl ||
      !visitedStoreId ||
      doneQR.current ||
      !QRCode?.CorrectLevel
    ) {
      return;
    }

    console.log('url ', fullUrl);

    doneQR.current = true;
    qrCodeInstance.current = new QRCode(
      document.getElementById(QRContentDomId),
      {
        text: fullUrl,
        width: 220,
        height: 220,
        colorDark: '#000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      }
    );
  }, [fullUrl, visitedStoreId]);

  useMemo(() => {
    return () => {
      qrCodeInstance.current.clear();
    };
  }, []);

  const rewards = useMemo(
    () => visitedStoreSelected?.rewards,
    [visitedStoreSelected]
  );

  const saveQrImage = () => {
    const dataUrl = document
      .querySelector(`#${QRContentDomId}`)
      .querySelector('img').src;

    if (dataUrl) {
      downloadURI(dataUrl, 'qrcode.png');
    }
  };

  const printQrPage = () => {
    try {
      const rewardsListDom = document.getElementById(storePortalRewardsDomId);
      const saveQrAsImageBtnDom = document.getElementById(
        storeSaveQrAsImageBtnId
      );
      const printStoreQrBtnDom = document.getElementById(storePrintQrPageBtnId);
      const storeHeaderCopyLinkBtnDom = document.getElementById(
        storePortalHeaderCopyLinkBtnId
      );
      const storePortalFooterDom = document.getElementById(
        storePortalFooterDomId
      );

      // hide not needed doms

      if (storePortalFooterDom) {
        storePortalFooterDom.style.display = 'none';
      }
      if (printStoreQrBtnDom) {
        printStoreQrBtnDom.style.display = 'none';
      }
      if (saveQrAsImageBtnDom) {
        saveQrAsImageBtnDom.style.display = 'none';
      }

      if (storeHeaderCopyLinkBtnDom) {
        storeHeaderCopyLinkBtnDom.style.display = 'none';
      }

      if (rewardsListDom) {
        rewardsListDom.style.display = 'none';
      }

      window.print();

      // unhide after print action was triggered

      if (storePortalFooterDom) {
        storePortalFooterDom.style.display = '';
      }

      if (rewardsListDom) {
        rewardsListDom.style.display = '';
      }

      if (storeHeaderCopyLinkBtnDom) {
        storeHeaderCopyLinkBtnDom.style.display = '';
      }

      if (saveQrAsImageBtnDom) {
        saveQrAsImageBtnDom.style.display = '';
      }

      if (printStoreQrBtnDom) {
        printStoreQrBtnDom.style.display = '';
      }
    } catch {}
  };

  return (
    <div
      className={cx(styles.flex_center_all, styles.store_content, {
        [styles.hide_dom]: fetchingTargetStoreInfo,
      })}
    >
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div
          className={cx(styles.rewards_page, styles.flex_center_all, {
            [styles.hide_dom]: !showRewards,
          })}
        >
          {!isEmpty(targetCustomerInfo?.fullName) && (
            <div className={cx(styles.flex_center_all, styles.customer_name)}>
              <p>{targetCustomerInfo?.fullName || ''}</p>
            </div>
          )}

          {!isEmpty(targetCustomerInfo?.email) && (
            <div className={cx(styles.flex_center_all, styles.customer_email)}>
              <p>{targetCustomerInfo?.email || ''}</p>
            </div>
          )}

          {targetCustomerInfo?.cardHolder && (
            <div className={cx(styles.flex_center_all, styles.card_holder)}>
              <p>Card Holder</p>
            </div>
          )}

          <div className={cx(styles.flex_center_all, styles.customer_points)}>
            <p>{`${
              targetCustomerInfo?.points || targetCustomerInfo?.totalPoints || 0
            } points`}</p>
          </div>
        </div>

        <div
          className={cx(styles.qr_page, styles.flex_center_all, {
            [styles.hide_dom]: !showQR,
          })}
        >
          <div className={cx(styles.flex_center_all, styles.qr_container)}>
            <div>
              <div className={styles.qr_raw} id={QRContentDomId}></div>
            </div>
          </div>

          {isStoreOwner && (
            <div className={cx(styles.flex_center_all, styles.save_qr_image)}>
              <Button
                height={'50px'}
                minWidth={'180px'}
                variant={'ghost'}
                borderRadius={'50px'}
                background={primaryGreenColor}
                backgroundColor={primaryGreenColor}
                className={cx(styles.flex_center_all, styles.save)}
                onClick={saveQrImage}
                id={storeSaveQrAsImageBtnId}
              >
                <DownloadIcon height={'20px'} width={'20px'} />
                <p>Save QR code as image </p>
              </Button>
            </div>
          )}

          {isStoreOwner && (
            <div className={cx(styles.flex_center_all, styles.print_qr)}>
              <Button
                height={'50px'}
                width={'240px'}
                variant={'ghost'}
                borderRadius={'100px'}
                background={primaryGreenColor}
                backgroundColor={primaryGreenColor}
                color="#fff"
                className={cx(styles.flex_center_all, styles.print_button)}
                onClick={printQrPage}
                id={storePrintQrPageBtnId}
              >
                <FileTextIcon height={'20px'} width={'20px'} />
                <p>Print Store's QR Page</p>
              </Button>
            </div>
          )}
        </div>

        {!isEmpty(rewards) && (
          <div
            id={storePortalRewardsDomId}
            className={cx(styles.flex_center_all, styles.rewards)}
          >
            <div className={cx(styles.flex_center_all, styles.rewards_label)}>
              <p>Available rewards </p>
            </div>
            <ul>
              {map(rewards, (reward) => {
                const key = `customerPortalReward${reward?.id}`;

                return (
                  <li key={key}>
                    <StoreReward reward={reward} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(StoreContent));
