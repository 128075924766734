import styles from './ModalWrapper.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { isFunction, isNil, isString } from 'lodash';
import { withUserProfileSettings } from './profile';
import { isIosMobile, isMobileScreen } from 'utils/helpers';
import { useWindowResize } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';

const ModalWrapper = (props) => {
  const {
    dom,
    domAction,
    children,
    domTitle,
    modalActive,
    closeModal,
    blockClosing,
    profileLoaded,
    hideClose,
  } = props;
  const isOpen = useMemo(
    () => profileLoaded && (modalActive || blockClosing),
    [profileLoaded, modalActive, blockClosing]
  );
  const updateModalHeightTimeoutId = useRef(null);
  const { availableHeight, availableWidth } = useWindowResize();
  const onClose = () => {
    if (blockClosing) {
      return;
    }

    isFunction(closeModal) && closeModal();
  };

  /**
   * Update modal height for ios screens
   */
  useEffect(() => {
    const updateModalHeightForIos = () => {
      updateModalHeightTimeoutId.current = setTimeout(() => {
        const modalContainer = document.querySelector(
          '.chakra-modal__content-container'
        );

        clearTimeout(updateModalHeightTimeoutId.current);

        if (isIosMobile() && isOpen && isMobileScreen() && modalContainer) {
          modalContainer.style.height = `${availableHeight}px`;
        } else if (modalContainer?.style?.height && !isMobileScreen()) {
          modalContainer.style.height = '';
        }
      }, 400);
    };

    clearTimeout(updateModalHeightTimeoutId.current);
    updateModalHeightForIos();
  }, [availableHeight, availableWidth, dom, modalActive, isOpen]);

  return (
    <>
      {children}
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        {...(blockClosing && { closeOnOverlayClick: false })}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={'520px'}
          {...(isMobileScreen() && {
            width: window.innerWidth > 530 ? '520px' : '94vw',
            maxWidth: window.innerWidth > 530 ? '520px' : '94vw',
          })}
          background={'#232323'}
          borderRadius={'8px'}
        >
          <ModalHeader className={styles.modal_header}>
            {isString(domTitle) ? <h5>{domTitle} </h5> : domTitle || <></>}
          </ModalHeader>
          {!hideClose && <ModalCloseButton />}
          <ModalBody>{dom || <></>} </ModalBody>
          <ModalFooter>{!isNil(domAction) ? domAction : <></>}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withUserProfileSettings(ModalWrapper);
