import cx from 'classnames';
import styles from './NonPremiumCTA.module.scss';
import { unlockPremiumFeature } from 'utils/helpers';
import { LockClosedIcon } from '@radix-ui/react-icons';
import { Button } from '@chakra-ui/react';
import { isFunction } from 'lodash';

const NonPremiumCTA = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.cta_premium)}>
      <Button
        height={'unset'}
        boxSizing={'border-box'}
        padding={'16px 20px'}
        background={'#7d5dc1'}
        borderRadius={'50px'}
        display={'flex'}
        justifyContent={'center'}
        backgroundColor={'#7d5dc1'}
        onClick={() => {
          if (isFunction(unlockPremiumFeature)) {
            unlockPremiumFeature();
          }
        }}
      >
        <LockClosedIcon height={'20px'} width={'20px'} />
        <p>Unlock this feature with premium</p>
      </Button>
    </div>
  );
};

export default NonPremiumCTA;
