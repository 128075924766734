import { withUserProfileSettings } from 'managers/profile';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthRequiredLayout = (props) => {
  const { children, profileLoaded, isLoggedIn, user } = props;
  const navigate = useNavigate();

  /**
   * Navigate to home
   */
  useEffect(() => {
    if ((!isLoggedIn || !user?.storeOwner) && profileLoaded) {
      navigate('/');
    }
  }, [navigate, isLoggedIn, user, profileLoaded]);

  return children;
};

export default withUserProfileSettings(AuthRequiredLayout);
