import cx from 'classnames';
import styles from './CustomerView.module.scss';
import * as Switch from '@radix-ui/react-switch';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  filter,
  head,
  includes,
  isBoolean,
  isEmpty,
  isFunction,
  isNumber,
  map,
  omit,
  toLower,
  toUpper,
  trim,
} from 'lodash';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import Header from './Header';
import { Avatar, Button, Spinner } from '@chakra-ui/react';
import {
  getAuthenticatedHeaders,
  getUserDisplayNameFromObject,
} from 'utils/helpers';
import {
  getStoreUserActivitiesByPageRequest,
  updateStoreCustomerPropertyRequest,
} from 'api/stores';
import { isArray } from 'lodash';
import { getUserInfoRequest } from 'api';
import { primaryGreenColor } from 'constants';
import { getName as getCountryName } from 'country-list';
import { withModalManagerSettings } from 'managers/modal';
import UpdateCustomerPoints from 'components/update-customer-points';
import { userActivityScanAddPoints } from 'constants';
import { userActivityAddPoints } from 'constants';
import CustomerActivity from './CustomerActivity';
import { AppRootContainerId } from 'App';

const CustomerView = (props) => {
  const {
    storeCustomer,
    selectStore,
    setActiveModalDom,
    profileLoaded,
    stores,
    storeSelected,
    updateStoreCustomerProperty,
  } = props;
  const updateStoreCustomerTimeoutId = useRef(null);
  const [fetchingActivities, setFetchingActivities] = useState(false);
  const [customer, setCustomer] = useState({ ...storeCustomer });
  const [page, setPage] = useState(1);
  const [activitiesHasNext, setActivitiesHasNext] = useState(false);
  const [activities, setActivities] = useState([]);
  const initialFetch = useRef(false);
  const initialFetchUserInfo = useRef(false);
  const [fetchingUserInfo, setFetchingUserInfo] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const [query] = useSearchParams();

  const customerUserId = useMemo(
    () => params?.customerUserId || '',
    [params?.customerUserId]
  );

  const sanitizedActivities = useMemo(() => {
    return filter(
      activities,
      (activity) =>
        !isEmpty(activity) &&
        includes(
          [userActivityScanAddPoints, userActivityAddPoints],
          activity?.type
        )
    );
  }, [activities]);

  const headers = useMemo(
    () => getAuthenticatedHeaders(props?.user) || {},
    [props?.user]
  );

  const storeId = useMemo(
    () => query?.storeId || storeSelected?.id || '',
    [query?.storeId, storeSelected]
  );

  const isFetchingInfo = useMemo(
    () =>
      (fetchingUserInfo && (!storeCustomer || !customer?.email)) ||
      isEmpty(customer?.email),
    [storeCustomer, fetchingUserInfo, customer?.email]
  );

  const customerPoints = useMemo(
    () =>
      isNumber(storeCustomer?.totalPoints)
        ? storeCustomer?.totalPoints
        : customer?.totalPoints,
    [customer, storeCustomer]
  );

  const fetchUserActivities = useCallback(async () => {
    if (fetchingActivities) {
      return;
    }

    setFetchingActivities(true);

    try {
      const {
        customer: latestCustomerInfo,
        activities: userActivities,
        errorMessage,
        networkError,
        hasNext,
      } = await getStoreUserActivitiesByPageRequest(
        storeId,
        page,
        customerUserId,
        headers
      );
      const sanitizedErrorMessage = toLower(trim(errorMessage));

      if (sanitizedErrorMessage?.includes('not store owner/member')) {
        // unauthorized to access this page
        navigate('/dashboard');
      } else if (!errorMessage && !networkError && isArray(userActivities)) {
        setActivities([...userActivities]);
        setActivitiesHasNext(hasNext);

        if (!isEmpty(latestCustomerInfo)) {
          setCustomer({
            ...customer,
            ...omit(latestCustomerInfo, [
              'id',
              'refId',
              'created',
              'createdMins',
            ]),
          });
        }
      }
    } catch {
    } finally {
      setFetchingActivities(false);
    }
  }, [
    navigate,
    fetchingActivities,
    customer,
    customerUserId,
    headers,
    storeId,
    page,
  ]);

  useEffect(() => {
    const dom = document.getElementById(AppRootContainerId);

    if (isFunction(dom?.scrollTo)) {
      dom.scrollTo(0, 0);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !isEmpty(stores) &&
      isFunction(selectStore) &&
      (isEmpty(storeSelected) || storeSelected?.id !== storeId)
    ) {
      // select store
      selectStore(storeId);
    }
  }, [selectStore, stores, storeId, storeSelected]);

  /**
   * store 'storeCustomer' var changes
   */
  // useEffect(() => {
  //   if (!isEmpty(storeCustomer)) {
  //     setCustomer({ ...storeCustomer, ...(customer || {}) });
  //   }
  // }, [storeCustomer, customer]);

  /**
   * Fetch initial first page
   */
  useEffect(() => {
    if (
      fetchingActivities ||
      initialFetch?.current ||
      !storeId ||
      !customer ||
      !customerUserId
    ) {
      return;
    }

    initialFetch.current = true;
    fetchUserActivities();
  }, [
    fetchUserActivities,
    storeId,
    customer,
    customerUserId,
    fetchingActivities,
  ]);

  useEffect(() => {
    if (!customerUserId || !profileLoaded || initialFetchUserInfo.current) {
      return;
    }

    const fetchUser = async () => {
      if (initialFetchUserInfo.current) {
        return;
      }

      initialFetchUserInfo.current = true;
      setFetchingUserInfo(true);

      try {
        const { user: latestUserInfo } = await getUserInfoRequest(
          customerUserId,
          headers
        );

        if (!isEmpty(latestUserInfo)) {
          setCustomer({ ...latestUserInfo, ...customer });
        }
      } catch (err) {
      } finally {
        setFetchingUserInfo(false);
      }
    };

    fetchUser();
  }, [customerUserId, headers, customer, profileLoaded]);

  const countryName = useMemo(() => {
    const code = customer?.country || customer?.countryFromSignUp || 'PH';

    if (!isEmpty(code)) {
      return getCountryName(toUpper(code));
    }

    return '';
  }, [customer?.country, customer?.countryFromSignUp]);

  const triggerEditUserPoints = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<UpdateCustomerPoints targetCustomerInfo={customer} />);
    }
  };

  const updateStoreCustomerPropertyDebounced = async (property = '', value) => {
    clearTimeout(updateStoreCustomerTimeoutId.current);

    updateStoreCustomerTimeoutId.current = setTimeout(() => {
      const customerUserId = customer?.id;
      const updatedProps = { [property]: value };
      const prevCustomerProps = { ...customer };

      const save = async () => {
        if (isFunction(updateStoreCustomerProperty)) {
          updateStoreCustomerProperty(customerUserId, { ...updatedProps });
          setCustomer({ ...customer, ...updatedProps });
        }

        const headers = getAuthenticatedHeaders(props?.user);
        const { storeUser, errorMessage, networkError } =
          await updateStoreCustomerPropertyRequest(
            storeId,
            customerUserId,
            { ...updatedProps },
            headers
          );
        const isSuccess = !errorMessage && !networkError && storeUser;

        if (!isSuccess) {
          setCustomer(prevCustomerProps);
        }
      };

      save();
      clearTimeout(updateStoreCustomerTimeoutId.current);
    }, 200);
  };

  return (
    <div className={cx(styles.flex_center_all, styles.customer_view)}>
      <Header />
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div
          className={cx(styles.flex_center_all, styles.info, {
            [styles.hide_dom]: isFetchingInfo,
          })}
        >
          <div className={cx(styles.flex_center_all, styles.avatar)}>
            <Avatar
              height={'80px'}
              width={'80px'}
              background={'#000'}
              backgroundColor={'#000'}
              src={customer?.image}
              name={head(
                getUserDisplayNameFromObject(customer) || customer?.email || ''
              )}
            />
          </div>
          <div className={cx(styles.flex_center_all, styles.texts)}>
            <p className={styles.name}>
              {customer?.fullName || customer?.email || ''}
            </p>
            {!isEmpty(customer?.fullName) && (
              <p className={styles.email}>{customer?.email}</p>
            )}

            {isNumber(customerPoints) && (
              <div className={cx(styles.flex_center_all, styles.points)}>
                <p>{`${customerPoints} ${
                  customerPoints === 1 ? 'point' : 'points'
                }`}</p>

                <Button
                  className={cx(styles.flex_center_all, styles.edit_points)}
                  variant={'ghost'}
                  height={'38px'}
                  borderRadius={'20px'}
                  background={primaryGreenColor}
                  backgroundColor={primaryGreenColor}
                  onClick={triggerEditUserPoints}
                >
                  <p> Edit points</p>
                </Button>
              </div>
            )}

            {isBoolean(customer?.approved) && (
              <div
                className={cx(styles.flex_center_all, styles.customer_state)}
              >
                <Switch.Root
                  checked={customer?.approved}
                  onClick={() => {
                    updateStoreCustomerPropertyDebounced(
                      'approved',
                      // toggle value
                      !customer?.approved
                    );
                  }}
                  className={styles.common_theme_switch}
                  aria-label="Toggle active user state"
                >
                  <Switch.Thumb className={styles.common_theme_switch_thumb} />
                </Switch.Root>

                <p> Activated </p>
              </div>
            )}

            {!isEmpty(countryName) && (
              <div className={cx(styles.flex_center_all, styles.country)}>
                <p>{`${countryName}`}</p>
              </div>
            )}
          </div>
        </div>
        {!isFetchingInfo && !isEmpty(sanitizedActivities) && (
          <div className={styles.activities}>
            <ul>
              {map(sanitizedActivities, (activityInfo) => {
                const activityId = activityInfo?.id;
                const key = `customerViewActivity${activityId}`;

                return (
                  <li key={key}>
                    <CustomerActivity activity={activityInfo} />
                  </li>
                );
              })}
            </ul>

            {activitiesHasNext && (
              <div
                className={cx(
                  styles.flex_center_all,
                  styles.show_more_activties
                )}
              >
                <Button
                  variant={'ghost'}
                  height={'40px'}
                  borderRadius={'20px'}
                  padding={'12px 24px'}
                  boxSizing={'border-box'}
                  background={'#292A2B'}
                  backgroundColor={'#292A2B'}
                  className={cx(
                    styles.flex_center_all,
                    styles.show_more_activties_button
                  )}
                >
                  <p> Load more</p>
                </Button>
              </div>
            )}
          </div>
        )}

        {(isFetchingInfo || fetchingActivities) && (
          <div className={cx(styles.loading, styles.flex_center_all)}>
            <Spinner height={'26px'} width={'26px'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(CustomerView))
);
