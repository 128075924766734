import cx from 'classnames';
import styles from './CustomerCardQR.module.scss';
import { useEffect, useMemo, useRef } from 'react';
import { last, size } from 'lodash';
import { withStoreManagerSettings } from 'managers/stores';
import { withModalManagerSettings } from 'managers/modal';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { downloadURI } from 'utils/helpers';

export const customerCardQRCodeId = 'customerCardQRCodeId';

const CustomerCardQR = (props) => {
  const { qrData, storeSelected } = props;

  const storeId = useMemo(() => storeSelected?.id, [storeSelected]);
  const doneQR = useRef(false);
  const qrCodeInstance = useRef(null);

  const fullUrl = useMemo(() => {
    let origin = window.location.origin;
    const userId = qrData?.id;

    if (last(origin) === '/') {
      origin = origin.substring(0, size(origin) - 1);
    }

    return `${origin}/portal/card-holders/${storeId}?qrUserId=${userId}`;
  }, [qrData, storeId]);

  useEffect(() => {
    const QRCode = window?.QRCode;

    if (
      !fullUrl ||
      !storeId ||
      !qrData?.id ||
      doneQR.current ||
      !QRCode?.CorrectLevel
    ) {
      return;
    }

    console.log('fullUrl', fullUrl);

    doneQR.current = true;
    qrCodeInstance.current = new QRCode(
      document.getElementById(customerCardQRCodeId),
      {
        text: fullUrl,
        width: 220,
        height: 220,
        colorDark: '#000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      }
    );
  }, [fullUrl, storeId, qrData]);

  const saveQrImage = () => {
    const dataUrl = document
      .querySelector(`#${customerCardQRCodeId}`)
      .querySelector('img').src;

    if (dataUrl) {
      downloadURI(
        dataUrl,
        qrData?.id ? `qrcode-${qrData?.id}.png` : 'qrcode.png'
      );
    }
  };

  return (
    <div className={cx(styles.flex_center_all, styles.customer_card_qr)}>
      <div className={cx(styles.flex_center_all, styles.qr_code)}>
        <div id={customerCardQRCodeId}></div>
      </div>
      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'40px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.save_image)}
          color="#fff"
          type="submit"
          onClick={saveQrImage}
        >
          <p>Download image </p>
        </Button>
      </div>
    </div>
  );
};

export default withStoreManagerSettings(
  withModalManagerSettings(CustomerCardQR)
);
