import cx from 'classnames';
import styles from './RewardCustomers.module.scss';
import Login from 'components/login';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withUserProfileSettings } from 'managers/profile';
import { isFunction } from 'lodash';
import { withModalManagerSettings } from 'managers/modal';
import { isMobileScreen } from 'utils/helpers';
import { AppRootContainerId } from 'App';

const RewardsCustomers = (props) => {
  const { isLoggedIn, setActiveModalDom } = props;

  const showLogin = () => {
    if (isMobileScreen()) {
      const dom = document.getElementById(AppRootContainerId);

      if (dom) {
        dom.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<Login />);
    }
  };

  return (
    <div className={cx(styles.reward_customers, styles.flex_center_all)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <p className={styles.headline}>
          Start, and reward your loyal customers!
        </p>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          color="#fff"
          className={cx(styles.flex_center_all, styles.signup, {
            [styles.hide_dom]: isLoggedIn,
          })}
          onClick={showLogin}
        >
          <p>Sign up for free</p>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withModalManagerSettings(RewardsCustomers)
);
