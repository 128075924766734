import { isNetworkError, isSuccessStatus } from 'api';
import { getStoresApiPathname } from './urls';
import { pick, trim } from 'lodash';
import { axiosGetRequest, axiosPostRequest } from './axios';

export async function createStoreUserQRForCardHolderRequest(
  storeId = '',
  props = {},
  meta = {},
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let user = null;
  let generatedQR = null;
  let qrUrl = '';

  try {
    const sanitizedProps = pick(props, ['email', 'purchase', 'fullName']);
    const url = getStoresApiPathname() + `/qr/user`;
    const res = await axiosPostRequest(
      url,
      { ...headers },
      {
        storeId,
        meta,
        ...sanitizedProps,
      }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.value && resData?.user) {
      user = resData?.user;
      generatedQR = resData?.value;

      qrUrl = `/portal/card-holders/${storeId}?qrUserId=${generatedQR?.id}`;
    } else if (resData?.message) {
      errorMessage = resData?.message;

      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    user,
    generatedQR,
    errorMessage,
    networkError,
    qrUrl,
  };
}

export async function getStoreCreatedUserQRForCardHoldersByPageRequest(
  storeId = '',
  startAfterId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let list = [];

  try {
    const url =
      getStoresApiPathname() +
      `/qr/users/${trim(storeId)}?startAfterId=${trim(startAfterId)}`;
    const res = await axiosGetRequest(url, { ...headers });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.list) {
      list = [...resData?.list];
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    list,
  };
}
