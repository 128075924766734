import cx from 'classnames';
import styles from './Header.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { withStoreManagerSettings } from 'managers/stores';
import LogoIcon from 'components/logo-icon';
import { Avatar, Button, useToast } from '@chakra-ui/react';
import { head, isEmpty, isObject, last, size, trim } from 'lodash';
import {
  copyTextToClipboard,
  getUserDisplayNameFromObject,
  isMobileScreen,
} from 'utils/helpers';
import Stores from 'components/stores';
import { HeaderCustomerPortalUserOptionsClassname } from '.';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { Link2Icon } from '@radix-ui/react-icons';
import { useWindowResize } from 'hooks';

export const storePortalHeaderCopyLinkBtnId = 'storePortalHeaderCopyLinkBtnId';
const HeaderCustomerPortal = (props) => {
  const {
    user,
    customer,
    isLoggedIn,
    stores,
    fetchingTargetStoreInfo,
    visitedStoreSelected,
  } = props;

  const { availableWidth } = useWindowResize();
  const targetCustomerInfo = useMemo(
    () => (!isEmpty(customer) && isObject(customer) ? customer : user),
    [user, customer]
  );

  const navigate = useNavigate();

  const toast = useToast({ position: 'bottom' });

  const onLogoClick = () => {
    if (isLoggedIn && (!isEmpty(stores) || user?.storeOwner)) {
      navigate('/dashboard');
    }
  };

  const isUserStoreOwner = useMemo(
    () => !isEmpty(user?.id) && user?.id === visitedStoreSelected?.owner,
    [visitedStoreSelected, user]
  );

  const copyQrPageLink = async () => {
    try {
      if (!visitedStoreSelected?.id) {
        return;
      }

      let origin = trim(window.location.origin);
      if (last(origin) === '/') {
        origin = origin.substring(0, size(origin) - 1);
      }

      const fullUrl = `${window.location.origin}/portal/qr/${visitedStoreSelected?.id}`;

      await copyTextToClipboard(fullUrl);

      toast({
        status: 'success',
        position: isMobileScreen() ? 'bottom' : 'top',
        title: 'Copied to clipboard!',
        duration: 2_500,
        isClosable: true,
      });
    } catch {}
  };

  return (
    <div className={cx(styles.flex_center_all, styles.header_portal)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.left)}>
          <LogoIcon onClick={onLogoClick} />
          {!fetchingTargetStoreInfo && <Stores />}
        </div>
        <div className={cx(styles.flex_center_all, styles.right)}>
          {isUserStoreOwner && (
            <div
              id={storePortalHeaderCopyLinkBtnId}
              className={cx(styles.flex_center_all, styles.copy_link)}
            >
              <Button
                height={'40px'}
                padding={'8px 12px'}
                boxSizing={'border-box'}
                variant={'ghost'}
                background={'#292A2B'}
                backgroundColor={'#292A2B'}
                borderRadius={'6px'}
                onClick={copyQrPageLink}
                className={cx(styles.flex_center_all, styles.copy_link_button)}
              >
                <Link2Icon height={'20px'} width={'20px'} />
                {availableWidth > 510 && <p>Copy Link</p>}
              </Button>
            </div>
          )}
          <div className={cx(styles.flex_center_all, styles.avatar)}>
            <button
              type="button"
              className={cx(
                styles.flex_center_all,
                HeaderCustomerPortalUserOptionsClassname
              )}
            >
              <Avatar
                name={
                  head(getUserDisplayNameFromObject(targetCustomerInfo)) ||
                  'N/A'
                }
                height={'40px'}
                width={'40px'}
                background={'#000'}
                color={'#fff'}
              />{' '}
              <div
                className={cx(
                  styles.cover_dom,
                  HeaderCustomerPortalUserOptionsClassname
                )}
              ></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(HeaderCustomerPortal)
);
