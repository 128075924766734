import platform from 'platform';
import {
  includes,
  isEmpty,
  isObject,
  isString,
  toLower,
  toString,
} from 'lodash';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';

export const getAuthenticatedHeaders = (user) => {
  const token = user?.auth?.token || '';

  return {
    authorization: `BEARER ${token}`,
  };
};

export const isMobileScreen = (breakPointWith = 805) =>
  window?.innerWidth <= breakPointWith;

export function isIOS() {
  const platformName = platform?.name || '';
  const osName = toLower(
    isObject(platform?.os) ? platform.os?.toString() || '' : platform?.os
  );
  const deviceManufacturer = toLower(`${window?.platform?.manufacturer || ''}`);

  return (
    platformName?.includes('ios') ||
    deviceManufacturer?.includes('apple') ||
    deviceManufacturer?.includes('mac') ||
    osName?.includes('ios') ||
    osName?.includes('apple')
  );
}

export function isIosMobile() {
  return isIOS() && isMobileScreen();
}

export function withEscapeCharacters(text = '') {
  return (
    isString(text) &&
    !isEmpty(text) &&
    (text?.includes(' ') || text?.includes('\n'))
  );
}

export function isCapacitorMobileApp() {
  try {
    // console.log(Capacitor.isNativePlatform(), `Capacitor.isNativePlatform()`);
    const origin = toLower(window.location.origin);

    return (
      origin?.startsWith('capacitor') ||
      (Capacitor.isNativePlatform() &&
        (Capacitor.getPlatform() === 'ios' ||
          Capacitor.getPlatform() === 'android'))
    );
  } catch {
    return false;
  }
}

export const isDev = () =>
  process.env.NODE_ENV === 'development' || process.env.MODE === 'development';

export function isCapacitorAndroid() {
  try {
    return Capacitor.getPlatform() === 'android' && isCapacitorMobileApp();
  } catch {
    return false;
  }
}

export function isCapacitorIos() {
  try {
    return (
      (Capacitor.getPlatform() === 'ios' || isIosMobile()) &&
      isCapacitorMobileApp()
    );
  } catch {
    return false;
  }
}

export async function isUserNetworkOnline() {
  try {
    const networkStatus = await Network.getStatus();

    return networkStatus?.connected && networkStatus?.connectionType !== 'none';
  } catch {
    return true;
  }
}

export function timeout(ms) {
  return new Promise((resolve) => {
    const _timeout = setTimeout(
      () => {
        clearTimeout(_timeout);
        return resolve();
      },
      ms ? ms : 50
    );
  });
}

export function getUserIdFromObject(user = {}) {
  return toString(user?.id || user?.userId || '');
}

export function copyTextToClipboard(text = '') {
  return new Promise((resolve) => {
    text = `${text}`;
    const textArea = document.createElement('textarea');
    const valueSTR = text;

    textArea.value = valueSTR;
    textArea.setAttribute('value', valueSTR);
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, valueSTR.length);

    if (document?.execCommand && document.execCommand('copy')) {
      textArea.remove();
      return resolve({ err: false });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(valueSTR).then(
        () => {
          textArea.remove();
          return resolve({ err: false });
        },

        (err) => {
          console.log(`copyTextToClipboard err ${err?.message}`);
          textArea.remove();
          return resolve({ err: true });
        }
      );
    } else {
      textArea.remove();
      return resolve({ err: true });
    }
  });
}

export function setMetaTitle(title = '') {
  try {
    title = title.replace(/["]+/g, '');
    const metas = document.querySelectorAll('.meta_title');
    const titleDOM = document.querySelector('title');
    if (titleDOM) titleDOM.innerText = title;

    for (let m of metas) {
      if (m && m.hasAttribute('content')) {
        m.setAttribute('content', title);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export function noUIOverflow() {
  const bodyDOM = document.querySelector('body');

  if (bodyDOM) {
    // bodyDOM.style.overflow = 'hidden';
    bodyDOM.classList.add('overflow_hidden');
  }
}

export function applyUIOverflow() {
  const bodyDOM = document.querySelector('body');

  if (bodyDOM) {
    // bodyDOM.style.overflow = '';
    bodyDOM.classList.remove('overflow_hidden');
  }
}

export const isCorrectEmailFormat = (email = '') => {
  const match =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return isString(email) && !isEmpty(email) && match.test(email);
};

export const getUserDisplayNameFromObject = (user = '') => {
  if (user?.storeOwner || !isEmpty(user?.fullName)) {
    return toString(user?.fullName || '');
  } else {
    return toString(user?.email);
  }
};

export const getHandlePropertyFromObject = (prop) => {
  const handleSanitized = toString(prop?.handle || '');

  if (handleSanitized?.startsWith('@')) {
    return handleSanitized.substring(1);
  }

  return handleSanitized;
};

export const isSafeEmailAddress = (email = '') => {
  return (
    !isEmpty(email) &&
    isString(email) &&
    !includes(email, 'gmai.co') &&
    !includes(email, 'test.co') &&
    !includes(email, 'yaho.co') &&
    !includes(email, 'yopmail.c')
  );
};

export const isUserAdmin = (user) => {
  return includes(user?.roles || [], 'admin');
};

export const isUserModerator = (user) => {
  return includes(user?.roles || [], 'moderator');
};

export function downloadURI(uri, name) {
  try {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    link.remove();
  } catch {}
}

export const unlockPremiumFeature = () => {
  try {
    window.location.replace(
      'https://robertespina.gumroad.com/l/melonkarmalifetimepremium'
    );
  } catch {}
};
