import cx from 'classnames';
import styles from './CopyButton.module.scss';
import { Button, Tooltip, useToast } from '@chakra-ui/react';
import { isEmpty, isFunction, isString } from 'lodash';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import { copyTextToClipboard, isMobileScreen } from 'utils/helpers';

const CopyButton = (props) => {
  const { text, onCopy, className } = props;

  const toast = useToast({ position: 'top' });

  const onClick = async (e) => {
    if (isFunction(onCopy)) {
      onCopy();
    }

    if (text) {
      await copyTextToClipboard(text);
    }

    toast({
      status: 'success',
      position: isMobileScreen() ? 'bottom' : 'top',
      title: 'Copied to clipboard!',
      duration: 2_500,
      isClosable: true,
    });
  };

  return (
    <Tooltip label="Copy to clipboard" className={styles.custom_tooltip}>
      <Button
        height={'40px'}
        width={'40px'}
        className={cx(styles.copy_button, styles.flex_center_all, {
          [`${className}`]: !isEmpty(className) && isString(className),
        })}
        onClick={onClick}
        background={'transparent'}
      >
        <ClipboardCopyIcon />
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
