import cx from 'classnames';
import styles from './CustomerActivity.module.scss';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { userActivityScanAddPoints } from 'constants';
import { userActivityAddPoints } from 'constants';
import { ceil, isNumber } from 'lodash';

const CustomerActivity = (props) => {
  const { activity } = props;

  const type = useMemo(() => activity?.type, [activity?.type]);

  const dateFormatted = useMemo(() => {
    const createdMins = activity?.createdMins;
    const timestampNow = Date.now();
    const timestampNowSeconds = ceil(timestampNow / 1000);
    const createdSeconds = createdMins * 60;
    const createdMs = createdSeconds * 1_000;

    if (
      timestampNowSeconds > createdMs &&
      timestampNowSeconds - createdMs < 70
    ) {
      return 'Few seconds ago';
    }

    const dateObj = new Date(createdMs);

    return moment(dateObj).fromNow();
  }, [activity]);

  const previewPurchase = useMemo(
    () => activity?.preview?.purchase,
    [activity?.preview]
  );

  const getTypeDescriptionFormat = () => {
    if (type === userActivityScanAddPoints || type === userActivityAddPoints) {
      return 'Customer scanned QR for points.';
    }

    return '';
  };

  return (
    <div className={cx(styles.flex_center_all, styles.customer_activity)}>
      <div className={cx(styles.flex_center_all, styles.left)}>
        <div className={styles.type}>
          <p>{getTypeDescriptionFormat()}</p>
        </div>
        <div className={cx(styles.flex_center_all, styles.date)}>
          <p>{dateFormatted}</p>
        </div>
      </div>
      <div className={styles.right}>
        {isNumber(previewPurchase) && (
          <div className={cx(styles.flex_center_all, styles.purchase)}>
            <p>{previewPurchase}</p>
          </div>
        )}{' '}
      </div>
    </div>
  );
};

export default CustomerActivity;
