import { isNetworkError, isSuccessStatus } from 'api';
import { axiosDeleteRequest, axiosGetRequest, axiosPostRequest } from './axios';
import { getFilesApiPathname, getStoresApiPathname } from './urls';
import { isArray, isEmpty, isNumber, isObject, pick, trim } from 'lodash';

export async function getOwnedStoresByPageRequest(
  pageNumber = 1,
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let stores = [];
  let hasNext = false;

  try {
    const url = getStoresApiPathname() + `/owned/${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && isArray(resData?.stores)) {
      hasNext = resData?.hasNext;
      stores = resData?.stores;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    hasNext,
    stores,
  };
}

export async function getStoreRecordedCustomersByPageRequest(
  pageNumber = 1,
  storeId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let totalCount = 0;
  let storeInfo = null;
  let users = [];
  let hasNext = false;

  try {
    const url =
      getStoresApiPathname() +
      `/recorded-users/${trim(storeId)}?page=${pageNumber}`;
    const res = await axiosGetRequest(url, { ...headers });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.users) {
      users = resData?.users;
      totalCount = resData?.totalCount;
      storeInfo = resData?.storeInfo;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    users,
    hasNext,
    storeInfo,
    totalCount,

    networkError,
    errorMessage,
  };
}

export async function createNewStoreRequest(businessName, headers = {}) {
  let networkError = false;
  let errorMessage = '';
  let store = null;

  try {
    const url = getStoresApiPathname() + '/new';
    const res = await axiosPostRequest(url, headers, {
      businessName: trim(businessName),
    });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.store) {
      store = resData.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    store,
    networkError,
    errorMessage,
  };
}

export async function updateStoreDisplayImageRequest(
  file = null,
  fileName = '',
  storeId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let store = null;

  try {
    const url =
      getFilesApiPathname() +
      `/upload/stores/image/${trim(storeId)}?fileName=${trim(fileName)}`;
    const formData = new FormData();
    formData.append('file', file, trim(fileName));
    const res = await axiosPostRequest(
      url,
      { ...headers, 'content-type': 'multipart/form-data' },
      formData
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.store) {
      store = resData.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    store,
    errorMessage,
    networkError,
  };
}

export async function updateStoreCoverPhotoImageRequest(
  file = null,
  fileName = '',
  storeId = '',
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let store = null;

  try {
    const url =
      getFilesApiPathname() +
      `/upload/stores/cover/${trim(storeId)}?fileName=${trim(fileName)}`;
    const formData = new FormData();
    formData.append('file', file, trim(fileName));
    const res = await axiosPostRequest(
      url,
      { ...headers, 'content-type': 'multipart/form-data' },
      formData
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.store) {
      store = resData.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    store,
    errorMessage,
    networkError,
  };
}

export async function getStoreInfoByHandleOrIdRequest(
  storeHandleOrId = '',
  headers = {},
  email = ''
) {
  let errorMessage = '';
  let networkError = false;
  let store = null;
  let customerInfo = null;

  try {
    const url =
      getStoresApiPathname() +
      `/info/${storeHandleOrId}?${
        !isEmpty(email) ? `email=${trim(email)}` : ''
      }`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.store) {
      store = resData.store;
      customerInfo = resData?.customerInfo;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }
    networkError = isNetworkError(err);
  }

  return {
    store,
    errorMessage,
    networkError,
    customerInfo,
  };
}

export async function updateStoreInfoPropertyRequest(
  storeId = '',
  params = {},
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let store = null;

  try {
    const url = getStoresApiPathname() + '/info';
    const res = await axiosPostRequest(url, headers, {
      storeId,
      ...pick(params || {}, ['storeId', 'businessName', 'handle', 'settings']),
    });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.success && resData?.store) {
      store = resData?.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    store,
    errorMessage,
    networkError,
  };
}

export async function getStoreNotificationsByPageRequest(
  storeId = '',
  pageNumber = 1,
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let hasNext = false;
  let totalCount = 0;
  let notifications = [];

  // hasNext, totalCount, notifications
  try {
    const url =
      getStoresApiPathname() +
      `/notifications/${trim(storeId)}?page=${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && isArray(resData?.notifications)) {
      notifications = resData?.notifications;
      hasNext = resData?.hasNext;
      totalCount = resData?.totalCount;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    notifications,
    hasNext,
    totalCount,
  };
}

export async function getStoreRecordedUsersByPageRequest(
  storeId = '',
  pageNumber = 1,
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let users = [];
  let hasNext = false;
  let totalCount = 0;

  try {
    const url =
      getStoresApiPathname() +
      `/recorded-users/${trim(storeId)}?page=${pageNumber}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && isArray(resData?.users)) {
      users = resData?.users;
      hasNext = resData?.hasNext;
      totalCount = resData?.totalCount;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return { totalCount, users, errorMessage, networkError, hasNext };
}

export async function deleteOwnedStoreRequest(storeId = '', headers = {}) {
  let errorMessage = '';
  let networkError = false;
  let success = false;

  try {
    const url = getStoresApiPathname() + `/${trim(storeId)}`;
    const res = await axiosDeleteRequest(url, headers, {});
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function searchStoreCustomerByEmailOrName(
  emailOrName = '',
  storeId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let results = [];

  try {
    const url =
      getStoresApiPathname() +
      `/search/${trim(emailOrName)}?storeId=${trim(storeId)}`;
    const res = await axiosPostRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.results) {
      results = resData?.results;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    results,
  };
}

export async function getStoreRewardsByPageRequest(
  storeId = '',
  pageNumber = 1,
  headers = {}
) {
  let errorMessage = '';
  let networkError = false;
  let rewards = [];
  let hasNext = false;
  let canEdit = false;

  try {
    const url =
      getStoresApiPathname() + `/rewards/${trim(storeId)}?page=${pageNumber}`;
    const res = await axiosGetRequest(url, { ...headers });
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.rewards) {
      rewards = resData?.rewards;
      canEdit = resData?.canEdit;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    hasNext,
    canEdit,
    errorMessage,
    networkError,
    rewards,
  };
}

export async function deleteStoreRewardRequest(
  id = '',
  storeId = '',
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let success = false;

  try {
    const url = getStoresApiPathname() + `/rewards/${trim(storeId)}`;
    const res = await axiosDeleteRequest(
      url,
      { ...headers },
      { rewardId: trim(id) }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.success) {
      success = true;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    networkError,
    errorMessage,
    success,
  };
}

export async function updateStoreRewardRequest(
  id = '',
  storeId = '',
  params = {},
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let reward = null;
  let store = null;

  try {
    const url = getStoresApiPathname() + `/rewards/update/${trim(storeId)}`;
    const res = await axiosPostRequest(
      url,
      { ...headers },
      {
        ...pick(params, ['name', 'image', 'points', 'description']),
        rewardId: trim(id),
      }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.reward) {
      reward = resData?.reward;
      store = resData?.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    reward,
    store,
  };
}
export async function uploadImageFileForStoreReward(
  file = null,
  fileName = '',
  storeId = '',
  headers = {},
  rewardId = ''
) {
  let networkError = false;
  let errorMessage = '';
  let image = '';
  let reward = null;

  try {
    const url =
      getFilesApiPathname() +
      `/upload/stores/reward/${trim(storeId)}?fileName=${trim(fileName || '')}${
        rewardId ? `&rewardId=${trim(rewardId)}` : ''
      }`;
    const formData = new FormData();
    formData.append('file', file, trim(fileName));
    const res = await axiosPostRequest(
      url,
      { ...headers, 'content-type': 'multipart/form-data' },
      formData
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.image) {
      image = resData?.image || '';
      reward = resData?.reward || null;
    } else if (resData?.message) {
      errorMessage = resData?.message;
      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    reward,
    image,
    networkError,
    errorMessage,
  };
}

export async function createStoreRewardRequest(
  storeId = '',
  name = '',
  params = {},
  headers = {}
) {
  let networkError = false;
  let errorMessage = '';
  let reward = null;
  let store = null;

  try {
    const image = trim(params?.image || '');
    const description = isObject(params?.description)
      ? JSON.stringify(params?.description)
      : params?.description;
    const points = isNumber(params?.points) ? params.points : 0;
    const url = getStoresApiPathname() + `/rewards/new/${trim(storeId)}`;
    const res = await axiosPostRequest(
      url,
      { ...headers },
      { name, image, points, description }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.reward) {
      reward = resData?.reward;
      store = resData?.store;
    } else if (resData?.message) {
      errorMessage = resData?.message;

      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    errorMessage,
    networkError,
    reward,
    store,
  };
}

export async function getStoreUserActivitiesByPageRequest(
  storeId = '',
  pageNumber = 1,
  userId = '',
  headers
) {
  let errorMessage = '';
  let networkError = false;
  let activities = [];
  let customer = null;
  let hasNext = false;

  // /user/activities/:storeId

  try {
    const url =
      getStoresApiPathname() +
      `/user/activities/${storeId}?page=${pageNumber}&userId=${trim(userId)}`;
    const res = await axiosGetRequest(url, headers);
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.activities) {
      activities = resData.activities;
      customer = resData.customer;
      hasNext = resData?.hasNext;
    } else if (resData?.message) {
      errorMessage = resData?.message;

      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    hasNext,
    networkError,
    errorMessage,
    activities,
    customer,
  };
}

export async function updateStoreCustomerPropertyRequest(
  storeId = '',
  userId = '',
  params = {},
  headers = {}
) {
  let storeUser = null;
  let networkError = false;
  let errorMessage = '';

  try {
    const url = getStoresApiPathname() + `/user/info/${trim(storeId)}`;
    const res = await axiosPostRequest(
      url,
      { ...headers },
      { ...pick(params, ['totalPoints', 'approved', 'banned']), userId }
    );
    const resData = res?.data;

    if (isSuccessStatus(res) && resData?.storeUser) {
      storeUser = resData.storeUser;
    } else if (resData?.message) {
      errorMessage = resData?.message;

      throw new Error(errorMessage);
    } else {
      throw new Error(`status: ${res?.status} ${res?.statusText}`);
    }
  } catch (err) {
    if (!errorMessage) {
      errorMessage = err?.message;
    }

    networkError = isNetworkError(err);
  }

  return {
    storeUser,
    networkError,
    errorMessage,
  };
}
