import cx from 'classnames';
import styles from './Footer.module.scss';
import { FacebookIcon, TwitterIcon } from 'icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.footer)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <div className={cx(styles.flex_center_all, styles.left)}>
          <div className={cx(styles.flex_center_all, styles.logo)}>
            <div>
              <img src={'/images/logo-192.png'} alt="App logo" />
            </div>
            <p>Easily integrate a rewards system to your business. </p>
          </div>
          <div className={cx(styles.flex_center_all, styles.socials)}>
            <a
              href="https://www.facebook.com/melonkarma"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon height={'30px'} width={'30px'} />
            </a>
            <a
              href="https://twitter.com/melonkarma"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon height={'30px'} width={'30px'} />{' '}
            </a>
          </div>
        </div>
        <div className={cx(styles.flex_center_all, styles.right)}>
          <div className={styles.note}>
            <ul className={styles.flex_center_all}>
              <li>
                <button>
                  <Link to={'/terms-service'}>Terms of Service</Link>
                </button>
              </li>
              <li>
                <button>
                  <Link to={'/privacy-policy'}>Privacy Policy</Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
