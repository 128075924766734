import cx from 'classnames';
import styles from './VerifyEmail.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { isCapacitorMobileApp, timeout } from 'utils/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { verifyUserEmailTokenRequest } from 'api';
import { isFunction } from 'lodash';
import { CheckSuccessIcon } from 'icons';

const VerifyEmail = (props) => {
  const { profileLoaded, updateUserProps, isLoggedIn, user } = props;
  const verifying = useRef(false);
  const [submitted, setSubmitted] = useState(true);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = useMemo(
    () => searchParams.get('c') || searchParams.get('token') || '',
    [searchParams]
  );

  useEffect(() => {
    const send = async () => {
      try {
        setSubmitted(true);
        const { success, user: updatedUser } =
          await verifyUserEmailTokenRequest(token);

        if (
          success &&
          isLoggedIn &&
          (user?.id === updatedUser?.id || user?.email === updatedUser?.email)
        ) {
          // update local state

          if (isFunction(updateUserProps)) {
            updateUserProps({ emailVerified: true });
          }
        }
      } catch {
      } finally {
        timeout(500).then(() => {
          // navigate to home
          setSubmitted(false);
          setSuccess(true);
        });
        timeout(1_500).then(() => {
          // navigate to home
          navigate('/');
        });
      }
    };

    if (!profileLoaded || verifying?.current) {
      return;
    }

    verifying.current = true;

    send();
  }, [token, navigate, isLoggedIn, updateUserProps, user, profileLoaded]);

  return (
    <div className={cx(styles.flex_center_all, styles.verify_email)}>
      <div className={styles.content}>
        <div className={cx(styles.flex_center_all, styles.icon)}>
          <img
            src={
              isCapacitorMobileApp()
                ? 'images/logo-transparent.png'
                : '/images/logo-192.png'
            }
            alt={'App logo'}
          />
        </div>
        {success && (
          <div className={cx(styles.success, styles.flex_center_all)}>
            <CheckSuccessIcon height={'20px'} width={'20px'} />
          </div>
        )}
        {submitted && (
          <div className={cx(styles.loading, styles.flex_center_all)}>
            <Spinner height={'20px'} width={'20px'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUserProfileSettings(VerifyEmail);
