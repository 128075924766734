import { useEffect, useMemo, useState } from 'react';

export const initialAvailableHeight =
  window?.visualViewport?.height || window?.innerHeight;
export const initialAvailableWidth =
  window?.visualViewport?.width || window?.innerWidth;

const useWindowResize = (props) => {
  const [availableHeight, setAvailableHeight] = useState(
    initialAvailableHeight
  );
  const [availableWidth, setAvailableWidth] = useState(initialAvailableWidth);

  useEffect(() => {
    const onResize = () => {
      const availableHeight =
        window?.visualViewport?.height || window?.innerHeight;
      const availableWidth =
        window?.visualViewport?.width || window?.innerWidth;

      setAvailableHeight(availableHeight);
      setAvailableWidth(availableWidth);
    };

    if (window?.visualViewport) {
      window.visualViewport.addEventListener('resize', onResize, false);
    } else {
      window.addEventListener('resize', onResize, false);
    }

    onResize();

    return () => {
      try {
        if (window?.visualViewport) {
          window.visualViewport.removeEventListener('resize', onResize, false);
        } else {
          window.removeEventListener('resize', onResize, false);
        }
      } catch {}
    };
  }, [props]);

  const memoizedAvailableHeight = useMemo(
    () => availableHeight,
    [availableHeight]
  );
  const memoizedAvailableWidth = useMemo(
    () => availableWidth,
    [availableWidth]
  );

  return {
    availableWidth: memoizedAvailableWidth,
    availableHeight: memoizedAvailableHeight,
  };
};

export default useWindowResize;
