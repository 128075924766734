import cx from 'classnames';
import styles from './LogoIcon.module.scss';
import { withUserProfileSettings } from 'managers/profile';
import { isFunction } from 'lodash';

export const logoIconComponentId = 'logoIconComponentId';
const LogoIcon = (props) => {
  const { onClick } = props;

  const onBtnClick = (...args) => {
    if (isFunction(onClick)) {
      onClick(...args);
    }
  };

  return (
    <div
      id={logoIconComponentId}
      className={cx(styles.flex_center_all, styles.logo)}
    >
      <button
        onClick={onBtnClick}
        type="button"
        className={cx(styles.no_style_button, styles.flex_center_all)}
      >
        <img alt={'App logo'} src={'/images/logo-192.png'} />
      </button>
    </div>
  );
};

export default withUserProfileSettings(LogoIcon);
