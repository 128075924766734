import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

const useRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const createQueryString = useCallback(
    (name = '', value = '', op = '') => {
      const params = new URLSearchParams(searchParams);

      if (op === 'add') {
        params.set(name, value);
      } else if (op === 'remove') {
        params.delete(name);
      }

      return params.toString();
    },
    [searchParams]
  );

  const setQuery = (key = '', value = '', targetPathname = '') => {
    const pathname = location?.pathname;

    navigate(
      (targetPathname ? targetPathname : pathname) +
        '?' +
        createQueryString(key, value, 'add')
    );
  };

  const removeQuery = (key = '', value = '', targetPathname = '') => {
    const pathname = location?.pathname;
    const newQuery = createQueryString(key, value, 'remove');

    navigate(
      (targetPathname ? targetPathname : pathname) +
        (!isEmpty(newQuery) ? '?' : '') +
        newQuery
    );
  };

  return {
    setQuery,
    removeQuery,
  };
};

export default useRouter;
