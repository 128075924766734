import { Component, createContext, forwardRef } from 'react';
import { isNil } from 'lodash';
import ModalWrapper from './ModalWrapper';

const { Consumer, Provider } = createContext({
  closeModal: () => null,
  setActiveModalDom: () => null,
  dom: null,
  domAction: null,
  domTitle: '',
});

export function withModalManagerSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {(value) => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class ModalManager extends Component {
  state = {
    dom: null,
    domTitle: null,
    domAction: null,
    active: false,
    type: '',
    blockClosing: false,
    hideClose: false,
  };

  /**
   * Set react doms to be contained in a modal wrapper
   * @param dom
   * @param domAction
   * @param domTitle
   * @returns
   */
  setActiveModalDom = (
    dom = null,
    domAction = null,
    domTitle = '',
    type = '',
    hideClose = false
  ) => {
    if (isNil(dom)) {
      return;
    }

    this.setState({
      active: true,
      dom,
      type,
      hideClose: Boolean(hideClose),
      domAction: domAction || null,
      domTitle: domTitle || null,
    });
  };

  /**
   * Close modal
   */
  closeModal = (forceClose = false) => {
    const { blockClosing } = this.state;

    if (!forceClose && blockClosing) {
      return;
    }

    this.setState({
      active: false,
      dom: null,
      domAction: null,
      domTitle: '',
      type: '',
      hideClose: false,
    });
  };

  blockClosingOfModal = () => {
    this.setState({
      blockClosing: true,
    });
  };

  unBlockClosingOfModal = (forceClose = false) => {
    this.setState(
      {
        blockClosing: false,
        hideClose: false,
      },
      () => {
        if (forceClose) {
          this.closeModal();
        }
      }
    );
  };

  render() {
    const { children } = this.props;
    const { active, dom, domAction, domTitle, blockClosing, type, hideClose } =
      this.state;
    const props = {
      dom,
      domAction,
      domTitle,
      blockClosing,
      type,
      hideClose,
      modalActive: active,
      blockClosingOfModal: this.blockClosingOfModal,
      unBlockClosingOfModal: this.unBlockClosingOfModal,
      setActiveModalDom: this.setActiveModalDom,
      closeModal: this.closeModal,
    };

    return (
      <Provider value={props}>
        <ModalWrapper {...props}>{children}</ModalWrapper>
      </Provider>
    );
  }
}

export default ModalManager;
