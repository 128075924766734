import cx from 'classnames';
import styles from './VerifyEmailPrompt.module.scss';
import { WarningIcon } from 'icons';

const VerifyEmailPrompt = () => {
  return (
    <div className={cx(styles.flex_center_all, styles.verify_email_prompt)}>
      <div className={cx(styles.flex_center_all, styles.content)}>
        <WarningIcon height={24} width={24} />
        <p>Please verify your E-mail address.</p>
      </div>
    </div>
  );
};

export default VerifyEmailPrompt;
