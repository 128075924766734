import cx from 'classnames';
import styles from './StoreReward.module.scss';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import YoutubeExtension from '@tiptap/extension-youtube';
import Placeholder from '@tiptap/extension-placeholder';
import StoreRewardForm from 'components/store-reward-form';
import { Editor as TipTapEditor } from '@tiptap/core';
import { Button } from '@chakra-ui/react';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { withStoreManagerSettings } from 'managers/stores';
import { useEffect, useRef, useState } from 'react';
import { isEmpty, isFunction } from 'lodash';
import DeleteReward from 'components/delete-reward';

const StoreReward = (props) => {
  const imgDomRef = useRef(null);
  const editorDomRef = useRef(null);
  const editorInstanceCreated = useRef(false);
  const { reward, allowModification, setActiveModalDom } = props;
  const [editor, setEditor] = useState(null);

  const triggerEditForm = () => {
    if (isFunction(setActiveModalDom) && !isEmpty(reward?.id)) {
      setActiveModalDom(<StoreRewardForm targetRewardInfo={reward} />);
    }
  };

  const triggerDeleteReward = () => {
    if (isFunction(setActiveModalDom)) {
      setActiveModalDom(<DeleteReward targetRewardInfo={reward} />);
    }
  };

  useEffect(() => {
    const dom = imgDomRef?.current;

    if (!dom) {
      return;
    }

    if (!isEmpty(reward?.image)) {
      dom.setAttribute('src', reward.image);
    } else {
      dom.setAttribute('src', '');
      dom.removeAttribute('src');
    }
  }, [reward?.image]);

  useEffect(() => {
    const dom = editorDomRef?.current;
    const descriptionString = reward?.description;

    if (!editor) {
      if (editorInstanceCreated.current) {
        return;
      }

      try {
        const parsed = JSON.parse(descriptionString);
        editorInstanceCreated.current = true;
        const editorInstance = new TipTapEditor({
          element: dom,
          extensions: [
            StarterKit.configure({
              code: false,
              codeBlock: false,
              italic: true,
              bold: true,
              heading: { levels: [2, 3] },
              blockquote: {
                content: 'paragraph*',
              },
            }),
            Placeholder.configure({
              placeholder: 'Describe your reward',
            }),
            Link.configure({
              openOnClick: false,
            }),
            YoutubeExtension.extend({
              addOptions() {
                return {
                  ...this.parent?.(),
                  height: '320px',
                  width: '100%',
                };
              },
            }),
          ],
          content: parsed || `<p></p>`,
          editable: false,
          autofocus: false,
          injectCSS: false,
        });

        setEditor(editorInstance);
      } catch {}
    }
  }, [editor, reward?.description]);

  // update description
  useEffect(() => {
    if (editor?.commands && !isEmpty(reward?.description)) {
      if (!editorInstanceCreated.current) {
        return;
      }

      try {
        const descriptionString = reward?.description;
        const parsed = JSON.parse(descriptionString);

        if (!isEmpty(parsed)) {
          editor.commands.setContent(parsed);
        }
      } catch {}
    }
  }, [editor, reward?.description]);

  return (
    <div className={cx(styles.flex_center_all, styles.store_reward)}>
      <div className={cx(styles.flex_center_all, styles.reward_image)}>
        <img
          ref={imgDomRef}
          alt="Reward preview"
          className={cx({ [styles.hide_dom]: !reward?.image })}
        />
      </div>
      <div className={cx(styles.flex_center_all, styles.name)}>
        <p>{reward?.name || ''}</p>
      </div>

      <div className={styles.editor}>
        <div ref={editorDomRef} className={styles.editor_raw}></div>
      </div>

      {allowModification && (
        <div className={cx(styles.flex_center_all, styles.cta)}>
          <Button
            height={'40px'}
            minWidth={'90px'}
            variant={'ghost'}
            borderRadius={'50px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.edit)}
            color="#fff"
            onClick={triggerEditForm}
          >
            <p>Edit</p>
          </Button>

          <Button
            height={'40px'}
            minWidth={'90px'}
            variant={'ghost'}
            borderRadius={'50px'}
            className={cx(styles.flex_center_all, styles.delete)}
            color="#fff"
            onClick={triggerDeleteReward}
          >
            <p>Delete </p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default withStoreManagerSettings(withModalManagerSettings(StoreReward));
