import cx from 'classnames';
import styles from './Customers.module.scss';
import CustomerItem from './CustomerItem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withStoreManagerSettings } from 'managers/stores';
import {
  filter,
  isEmpty,
  isFunction,
  isNil,
  isUndefined,
  map,
  size,
  toString,
  trim,
} from 'lodash';
import { maxEmail } from 'constants';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Cross2Icon,
} from '@radix-ui/react-icons';
import { withUserProfileSettings } from 'managers/profile';
import { primaryGreenColor } from 'constants';
import { searchStoreCustomerByEmailOrName } from 'api/stores';
import { useAuth } from 'hooks';

const pageSize = 20;

const Customers = (props) => {
  const initialCustomersFetch = useRef(false);

  const {
    storeSelected,
    storeCustomersPage,
    storeCustomers,
    storeCustomersHasNext,
    storeCustomersTotalCount,
    storeCustomersFetching,
    getStoreRecordedCustomersPrevPage,
    getStoreRecordedCustomersNextPage,
  } = props;

  const searchingTimeoutId = useRef(null);

  const { getAuthenticatedHeaders } = useAuth(props);

  const [searchInput, setSearchInput] = useState('');

  const [searching, setSearching] = useState(false);

  const [customerSearchResults, setCustomerSearchResults] = useState([]);

  const [showSearchResults, setShowSearchResults] = useState(false);

  const sanitizedStoreCustomers = useMemo(
    () =>
      showSearchResults && !isEmpty(searchInput)
        ? filter(customerSearchResults)
        : filter(storeCustomers, (customer) => !isEmpty(customer?.email)),
    [storeCustomers, searchInput, showSearchResults, customerSearchResults]
  );

  const emptyCustomersList = useMemo(
    () =>
      !storeCustomersFetching &&
      (isNil(sanitizedStoreCustomers) ||
        isUndefined(sanitizedStoreCustomers) ||
        isEmpty(sanitizedStoreCustomers)),
    [storeCustomersFetching, sanitizedStoreCustomers]
  );

  const start = useMemo(
    () => (storeCustomersPage < 2 ? 1 : (storeCustomersPage - 1) * pageSize),
    [storeCustomersPage]
  );

  const end = useMemo(
    () =>
      storeCustomersPage < 2
        ? size(storeCustomers)
        : start + size(storeCustomers),
    [start, storeCustomers, storeCustomersPage]
  );

  const emptyList = useMemo(
    () =>
      (isEmpty(customerSearchResults) &&
        showSearchResults &&
        !isEmpty(searchInput) &&
        !searching) ||
      !storeCustomers?.length ||
      isEmpty(storeCustomers),
    [
      storeCustomers,
      showSearchResults,
      searching,
      searchInput,
      customerSearchResults,
    ]
  );
  const toast = useToast({ position: 'top' });

  const submitSearch = useCallback(() => {
    if (searching) {
      return;
    }

    if (!searchInput || isEmpty(searchInput)) {
      return;
    }

    setSearching(true);
    clearTimeout(searchingTimeoutId.current);

    searchingTimeoutId.current = setTimeout(async () => {
      try {
        if (searching) {
          return;
        }

        const headers = getAuthenticatedHeaders();
        const storeId = trim(storeSelected?.id || '');

        setShowSearchResults(true);
        const { errorMessage, networkError, results } =
          await searchStoreCustomerByEmailOrName(searchInput, storeId, headers);

        if (!errorMessage && !networkError) {
          setCustomerSearchResults(results);
        }
      } catch {
      } finally {
        setSearching(false);
      }
    }, 100);
  }, [storeSelected, getAuthenticatedHeaders, searching, searchInput]);

  // on enter key submit
  useEffect(() => {
    const onKeydown = (evt) => {
      if (!evt) {
        return;
      }

      if (
        !isEmpty(searchInput) &&
        !searching &&
        (evt?.keyCode === 27 || evt?.keyCode === 13)
      ) {
        submitSearch();
      }
    };

    window.addEventListener('keydown', onKeydown, false);

    return () => {
      window.removeEventListener('keydown', onKeydown, false);
    };
  }, [
    submitSearch,
    storeSelected,
    searchInput,
    searching,
    customerSearchResults,
  ]);

  useEffect(() => {
    if (!initialCustomersFetch.current && storeCustomersFetching) {
      initialCustomersFetch.current = true;
    }
  }, [storeCustomersFetching]);

  return (
    <div
      className={cx(styles.customers_settings, {
        [styles.hide_dom]: !storeSelected?.id,
      })}
    >
      <div className={cx(styles.flex_center_all, styles.search)}>
        <div className={cx(styles.flex_center_all, styles.search_label)}>
          <p>Search for customers</p>
        </div>

        <div>
          <div
            className={cx(
              styles.flex_center_all,
              styles.input,
              styles.input_with_icon,
              styles.input_search
            )}
          >
            <input
              placeholder="customer@domain.com"
              value={searchInput}
              onChange={(evt) => {
                const val = toString(evt?.target?.value || '');

                setSearchInput(val);

                if (!val) {
                  setShowSearchResults(false);
                }
              }}
              maxLength={maxEmail}
              type="text"
              aria-label="Search for customer email"
            />
            <div
              className={cx(styles.flex_center_all, styles.input_right_icon, {
                [styles.hide_dom]: isEmpty(searchInput),
              })}
            >
              <Button
                variant={'unstyled'}
                aria-label="Clear email input"
                className={cx(styles.flex_center_all, styles.clear)}
                onClick={() => {
                  setSearchInput('');
                  setShowSearchResults(false);
                  setCustomerSearchResults([]);
                }}
              >
                <Cross2Icon height={'18px'} width={'18px'} />
              </Button>
            </div>
          </div>
          <Button
            height={'40px'}
            width={'100px'}
            variant={'ghost'}
            borderRadius={'50px'}
            background={primaryGreenColor}
            backgroundColor={primaryGreenColor}
            className={cx(styles.flex_center_all, styles.submit_search)}
            color="#fff"
            onClick={submitSearch}
            isLoading={searching}
          >
            <p>Search </p>
          </Button>
        </div>
      </div>

      {emptyList && !storeCustomersFetching && (
        <div className={cx(styles.flex_center_all, styles.empty_list)}>
          <div className={styles.flex_center_all}>
            <p>No results.</p>
          </div>
        </div>
      )}

      {!emptyCustomersList && !showSearchResults && (
        <div className={cx(styles.flex_center_all, styles.page_info)}>
          <div className={cx(styles.flex_center_all, styles.page_info_content)}>
            <p>{`Total customers ( ${storeCustomersTotalCount} )`}</p>

            <div className={cx(styles.flex_center_all, styles.nav)}>
              <Button
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'40px'}
                width={'40px'}
                variant={'ghost'}
                borderRadius={'50%'}
                onClick={() => {
                  if (isFunction(getStoreRecordedCustomersPrevPage)) {
                    getStoreRecordedCustomersPrevPage();
                  }
                }}
              >
                <ChevronLeftIcon height={'20px'} width={'20px'} />
              </Button>

              <p>{`${start} - ${end}`}</p>

              <Button
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'40px'}
                width={'40px'}
                variant={'ghost'}
                borderRadius={'50%'}
                onClick={() => {
                  if (!storeCustomersHasNext) {
                    toast({
                      title: 'End of list.',
                      duration: 1_500,
                      status: 'info',
                    });

                    return;
                  }

                  if (isFunction(getStoreRecordedCustomersNextPage)) {
                    getStoreRecordedCustomersNextPage();
                  }
                }}
              >
                <ChevronRightIcon height={'20px'} width={'20px'} />
              </Button>
            </div>
          </div>
        </div>
      )}

      {!emptyCustomersList && (
        <div className={styles.list}>
          <ul>
            {map(sanitizedStoreCustomers, (customer) => {
              const customerId = customer?.id || customer?.userId;
              const cacheKey = `PanelCustomerKey${customerId}`;

              return (
                <li key={cacheKey}>
                  <CustomerItem customer={customer} />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {storeCustomersFetching &&
        !initialCustomersFetch?.current &&
        (isNil(sanitizedStoreCustomers) ||
          isEmpty(sanitizedStoreCustomers)) && (
          <div className={cx(styles.flex_center_all, styles.loading)}>
            <Spinner height={'24px'} width={'24px'} />
          </div>
        )}
    </div>
  );
};

export default withUserProfileSettings(withStoreManagerSettings(Customers));
