import cx from 'classnames';
import styles from './CreateStore.module.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  filter,
  head,
  isEmpty,
  isFunction,
  size,
  toString,
  trim,
} from 'lodash';
import { Button, useToast } from '@chakra-ui/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { primaryGreenColor } from 'constants';
import { withModalManagerSettings } from 'managers/modal';
import { withStoreManagerSettings } from 'managers/stores';
import { createNewStoreRequest } from 'api/stores';
import { minBusinessName } from 'constants';
import { useAuth } from 'hooks';
import { withUserProfileSettings } from 'managers/profile';
import { maxBusinessName } from 'constants';

// for modal
const CreateNewStore = (props) => {
  const {
    blockClosingOfModal,
    unBlockClosingOfModal,
    createNewStore,
    stores,
    closeModal,
  } = props;
  const [businessName, setBusinessName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const { getAuthenticatedHeaders } = useAuth(props);

  const toast = useToast({ position: 'top', id: 'chakraToastCreateStore' });

  const submit = useCallback(async () => {
    try {
      if (submitted) {
        return;
      }

      if (size(stores) >= 10) {
        toast({
          title: 'The maximum allowed stores have been reached.',
          status: 'error',
          duration: 4_500,
          isClosable: true,
        });

        return;
      }

      const sanitizedBusinessName = trim(businessName);
      const targetStoreBusinessName = head(
        filter(
          stores,
          (store) => trim(store?.businessName) === sanitizedBusinessName
        )
      );
      const headers = getAuthenticatedHeaders();

      setSubmitted(true);

      if (targetStoreBusinessName) {
        setErrorMessage('The business name already exists in your list.');
        return;
      }

      if (
        !sanitizedBusinessName ||
        size(sanitizedBusinessName) < minBusinessName
      ) {
        setErrorMessage(
          `Business name must at least contain ${minBusinessName} characters.`
        );
        return;
      }

      setErrorMessage('');

      if (isFunction(blockClosingOfModal)) {
        blockClosingOfModal();
      }

      const { store, errorMessage, networkError } = await createNewStoreRequest(
        businessName,
        headers
      );

      if (networkError) {
        setErrorMessage('Check your network connection.');
      } else if (errorMessage || !store) {
        setErrorMessage('Something went wrong. Try again.');
      } else {
        if (isFunction(createNewStore)) {
          // add to list
          // auto select
          createNewStore(store);

          if (isFunction(closeModal)) {
            closeModal(true);
          }

          toast({
            status: 'success',
            title: 'Successfully Created!',
            duration: 3_500,
            isClosable: true,
          });
        }
      }
    } catch (err) {
    } finally {
      setSubmitted(false);

      if (isFunction(unBlockClosingOfModal)) {
        unBlockClosingOfModal();
      }
    }
  }, [
    unBlockClosingOfModal,
    blockClosingOfModal,
    closeModal,
    createNewStore,
    getAuthenticatedHeaders,
    toast,
    submitted,
    stores,
    businessName,
  ]);

  useEffect(() => {
    const onKeydown = (evt) => {
      if (
        !isEmpty(businessName) &&
        !submitted &&
        (evt.keyCode === 27 || evt.keyCode === 13)
      ) {
        submit();
      }
    };

    window.addEventListener('keydown', onKeydown, false);

    return () => {
      window.removeEventListener('keydown', onKeydown, false);
    };
  }, [submit, submitted, businessName]);

  return (
    <div className={styles.create_store}>
      <div className={cx(styles.flex_center_all, styles.label)}>
        <p>Create new business profile</p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input_label_margin,
          styles.input_label
        )}
      >
        <p>Business name</p>
      </div>
      <div
        className={cx(
          styles.flex_center_all,
          styles.input,
          styles.input_with_icon,
          styles.input_margin
        )}
      >
        <input
          placeholder="Your business name"
          value={businessName}
          onChange={(evt) =>
            setBusinessName(toString(evt?.target?.value || ''))
          }
          type="text"
          maxLength={maxBusinessName}
          aria-label="Your business name for your new account"
        />
        <div
          className={cx(styles.flex_center_all, styles.input_right_icon, {
            [styles.hide_dom]: isEmpty(businessName),
          })}
        >
          <Button
            variant={'unstyled'}
            aria-label="Clear business name input"
            className={cx(styles.flex_center_all, styles.clear)}
            onClick={() => setBusinessName('')}
          >
            <Cross2Icon height={'18px'} width={'18px'} />
          </Button>
        </div>
      </div>

      {!isEmpty(errorMessage) && (
        <div className={cx(styles.error, styles.flex_center_all)}>
          <p> {errorMessage}</p>
        </div>
      )}
      <div className={cx(styles.flex_center_all, styles.cta)}>
        <Button
          height={'50px'}
          minWidth={'180px'}
          variant={'ghost'}
          borderRadius={'100px'}
          background={primaryGreenColor}
          backgroundColor={primaryGreenColor}
          className={cx(styles.flex_center_all, styles.submit)}
          color="#fff"
          onClick={submit}
          isLoading={submitted}
        >
          <p>Confirm</p>
        </Button>
      </div>
    </div>
  );
};

export default withUserProfileSettings(
  withStoreManagerSettings(withModalManagerSettings(CreateNewStore))
);
